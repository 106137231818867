import { useHistory } from 'react-router'
import AgencyHouseAnalyticsFiltersbar from '../AgencyHouseAnalyticsFiltersbar/AgencyHouseAnalyticsFiltersbar'
import { useEffect, useState, useCallback } from 'react'
import useQuery from '../../../../hooks/useQuery'
import { hebrew } from '../../../../i18n'
import { useDispatch, useSelector } from 'react-redux'
import {
  AGENT_HOUSE_ANALYTICS_SET_DATATYPE,
  AGENT_HOUSE_ANALYTICS_SET_LISTTYPE,
  AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE,
} from '../../../../types/actionTypes'
import {
  analyticsIncomesExtraListType,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
  incomesDataTypes,
  incomesTotalsType,
} from '../../../../types/analyticsIncomesTypes'
import queryString from 'query-string'
import AgencyHouseAnalyticsIncomesTotals from '../AgencyHouseAnalyticsIncomesTotals/AgencyHouseAnalyticsIncomesTotals'
import AgencyHouseAnalyticsIncomesListContainer from '../AgencyHouseAnalyticsIncomesListContainer/AgencyHouseAnalyticsIncomesListContainer'
import {
  getAgencyHouseAnalyticsListRequest,
  getAgencyHouseAnalyticsTotalsRequest,
} from '../../../../http/requests/agencyHouseRequests'
import { getCheckedIds, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import { isRegularAgency } from '../../../login/reducers/loginSelectors'
import {
  getAgencyAnalyticsListRequest,
  getAgencyAnalyticsTotalsRequest,
} from '../../../../http/requests/agencyAnalyticsRequests'
import { agencyHouseAnalyticsSubroute } from '../../../../environment/urls'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import AgencyHouseAnalyticsIncomesChangesDetailsCard from '../AgencyHouseAnalyticsIncomesChangesDetailsCard/AgencyHouseAnalyticsIncomesChangesDetailsCard'
import AgencyHouseAnalyticsIncomesDetailsCard from '../AgencyHouseAnalyticsIncomesDetailsCard/AgencyHouseAnalyticsIncomesDetailsCard'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import './AgencyHouseAnalyticsIncomes.scss'

const AgencyHouseAnalyticsIncomes = () => {
  const [totals, setTotals] = useState(
    Object.values(incomesTotalsType).map(type => ({
      type,
      value: null,
      diff: 0,
      trend: null,
    }))
  )

  const [totalsLoading, setTotalsloading] = useState(false)
  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)
  const [detailsItem, setDetailsItem] = useState(null)

  const dispatch = useDispatch()
  const {
    incomesDataType,
    incomesListType,
    incomesListViewType,
    periodViewType,
    selectedDates,
    selectedAgencies,
    companies,
    categories,
    filtersLoading,
  } = useSelector(({ agencyHouseAnalytics }) => agencyHouseAnalytics)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)
  const calculateByFamilyGroups = familyGroupMode()

  const history = useHistory()
  let query = useQuery()
  const currentDataType = query.get('dataType')
  const currentListType = query.get('listType')
  const currentListViewType = query.get('listViewType')

  const selectDataType = dataType => {
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, dataType }).toString() })
  }

  if (!currentDataType || !incomesDataTypes.map(({ id }) => id).includes(+currentDataType)) {
    selectDataType(incomesDataType)
  }

  if (
    currentDataType &&
    incomesDataTypes.map(({ id }) => id).includes(+currentDataType) &&
    incomesDataType !== +currentDataType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_DATATYPE, payload: +currentDataType })
  }

  const selectListType = listType => {
    let listTypeToSet = listType
    if (isRegularAgency() && listType === analyticsIncomesListType.agents)
      listTypeToSet = analyticsIncomesListType.companies
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, listType: listTypeToSet }).toString() })
  }

  if (
    !currentListType ||
    !Object.values(analyticsIncomesListType)
      .filter(lt => (isRegularAgency() ? lt !== analyticsIncomesListType.agents : true))
      .includes(+currentListType)
  ) {
    selectListType(incomesListType)
  }

  if (
    currentListType &&
    Object.values(analyticsIncomesListType)
      .filter(lt => (isRegularAgency() ? lt !== analyticsIncomesListType.agents : true))
      .includes(+currentListType) &&
    incomesListType !== +currentListType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_LISTTYPE, payload: +currentListType })
  }

  const selectListViewType = listViewType => {
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, listViewType }).toString() })
  }

  if (!currentListViewType || !Object.values(analyticsIncomesListViewType).includes(+currentListViewType)) {
    selectListViewType(incomesListViewType)
  }

  if (
    currentListViewType &&
    Object.values(analyticsIncomesListViewType).includes(+currentListViewType) &&
    incomesListViewType !== +currentListViewType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE, payload: +currentListViewType })
  }

  const existingDataType = useCallback(
    () => currentDataType && incomesDataTypes.map(({ id }) => id).includes(+currentDataType),
    [currentDataType]
  )

  useEffect(() => {
    const fetchTotals = async dataType => {
      try {
        setTotalsloading(true)
        const getTotalsRequest = isRegularAgency()
          ? getAgencyAnalyticsTotalsRequest
          : getAgencyHouseAnalyticsTotalsRequest
        const { data: resp } = await getTotalsRequest({
          categories: getCheckedIds(categories, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.id),
          timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
          periodType: periodViewType,
          dataType,
          fromNewPolicies: history.location.pathname.includes(agencyHouseAnalyticsSubroute.incomesNew),
          ...(isRegularAgency() && { calculateByFamilyGroups }),
        })
        const data = lowercaseObjectKeys(resp.Data ?? resp.data)

        setTotals(
          Object.entries(incomesTotalsType).map(([typeKey, type]) => ({
            type,
            value: data[typeKey]?.currentValue,
            diff: data[typeKey]?.diff,
            trend: data[typeKey]?.trend,
          }))
        )
        setTotalsloading(false)
      } catch (error) {
        setTotalsloading(false)
        console.log('err getting totals')
        console.log(error)
      }
    }
    if (
      (!isRegularAgency() &&
        selectedDates.length > 0 &&
        companies.length > 0 &&
        categories.length > 0 &&
        selectedAgencies.length > 0) ||
      (isRegularAgency() && selectedDates.length > 0 && companies.length > 0 && categories.length > 0)
    ) {
      if (existingDataType()) {
        fetchTotals(+currentDataType)
      }
    }
  }, [
    calculateByFamilyGroups,
    companies,
    categories,
    selectedDates,
    selectedAgencies,
    currentDataType,
    existingDataType,
    selectedGroupsIds,
  ])

  useEffect(() => {
    const fetchTable = async dataType => {
      try {
        setDetailsItem(null)
        setList([])
        setListLoading(true)
        const getListRequest = isRegularAgency() ? getAgencyAnalyticsListRequest : getAgencyHouseAnalyticsListRequest
        const { data: resp } = await getListRequest({
          categories: getCheckedIds(categories, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.id),
          timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
          dataType,
          entityType:
            periodViewType !== analyticsPeriodViewType.monthly
              ? analyticsIncomesExtraListType.timePeriods
              : +currentListType,
          fromNewPolicies: history.location.pathname.includes(agencyHouseAnalyticsSubroute.incomesNew),
          isChartView: periodViewType !== analyticsPeriodViewType.monthly,
          periodType: periodViewType,
          ...(isRegularAgency() && { calculateByFamilyGroups }),
        })
        let listItems = lowercaseObjectKeys(resp.Data ?? resp.data).items
        if (periodViewType !== analyticsPeriodViewType.monthly) {
          listItems = listItems.map(li => {
            const [year, month] = li.key.split('-')
            return {
              ...li,
              name: `${getMonthNameByNumber(month, 'he')}, ${year}`,
              nameEn: `${getMonthNameByNumber(month, 'en')}, ${year}`,
            }
          })
        }
        setList(listItems)
        setListLoading(false)
      } catch (error) {
        console.log(error)
        setListLoading(false)
      }
    }
    if (
      (!isRegularAgency() &&
        selectedDates.length > 0 &&
        companies.length > 0 &&
        categories.length > 0 &&
        selectedAgencies.length > 0) ||
      (isRegularAgency() && selectedDates.length > 0 && companies.length > 0 && categories.length > 0)
    ) {
      if (existingDataType()) {
        fetchTable(+currentDataType)
      }
    }
  }, [
    calculateByFamilyGroups,
    categories,
    companies,
    selectedDates,
    selectedAgencies,
    currentDataType,
    currentListType,
    existingDataType,
    currentListViewType,
    selectedGroupsIds,
  ])

  useEffect(() => {
    detailsItem && setDetailsItem(null)
  }, [history.location, selectedDates, categories, companies])

  return (
    <div className='agency-house-analytics-incomes'>
      <main>
        <AgencyHouseAnalyticsFiltersbar
          dataTypes={incomesDataTypes.map(itm => ({
            ...itm,
            value: hebrew() ? itm.name : itm.nameEn,
            selected: +currentDataType === itm.id,
          }))}
          selectDataType={selectDataType}
        />
        <div className='agency-house-analytics-incomes-content'>
          <AgencyHouseAnalyticsIncomesTotals
            dataType={+currentDataType}
            periodViewType={periodViewType}
            totals={totals}
            totalsLoading={totalsLoading || filtersLoading}
          />
          <AgencyHouseAnalyticsIncomesListContainer
            listType={+currentListType}
            setListType={selectListType}
            listViewType={+currentListViewType}
            setListViewType={selectListViewType}
            periodViewType={periodViewType}
            list={list}
            listLoading={listLoading || totalsLoading || filtersLoading}
            dataType={+currentDataType}
            totals={totals}
            selectItem={setDetailsItem}
            selectedItem={detailsItem}
          />
        </div>
      </main>
      {periodViewType !== analyticsPeriodViewType.monthly ? (
        <AgencyHouseAnalyticsIncomesChangesDetailsCard
          listViewType={+currentListViewType}
          item={detailsItem}
          closeCard={() => setDetailsItem(null)}
          dataType={+currentDataType}
          fromNewPolicies={history.location.pathname.includes(agencyHouseAnalyticsSubroute.incomesNew)}
        />
      ) : (
        <AgencyHouseAnalyticsIncomesDetailsCard
          listViewType={+currentListViewType}
          item={detailsItem}
          closeCard={() => setDetailsItem(null)}
          dataType={+currentDataType}
          fromNewPolicies={history.location.pathname.includes(agencyHouseAnalyticsSubroute.incomesNew)}
        />
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsIncomes
