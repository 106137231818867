import './Snackbar.scss'

const Snackbar = ({ closeBar = () => {}, children }) => {
  return (
    <div className='snackbar-container'>
      <div onClick={closeBar} className='snackbar-close-icon'>
        <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
      </div>
      {children}
    </div>
  )
}

export default Snackbar
