let agencyUrl
let masterUrl
let crawlerUrl
const masterSchema = 'https://'
let signalRUrl
let signalRCrawlerUrl

let agencyHost
let masterHost
let crawlerHost
let signalRHost
let signalRCrawlerHost

if (process.env.REACT_APP_API_ENV === 'development') {
  // -- Local URL ----------------------------------
  const { hostname } = window.location
  agencyHost = `${hostname}:5010`
  masterHost = `${hostname}:5005/api`
  crawlerHost = `${hostname}:5001`
  signalRHost = `${hostname}:5005`
  signalRCrawlerHost = `${hostname}:5001`

  agencyUrl = (agency = '{agency}') => `${masterSchema}${agencyHost}/${agency}/`
  masterUrl = `${masterSchema}${masterHost}/`
  crawlerUrl = `${masterSchema}${crawlerHost}/`
  signalRUrl = `${masterSchema}${signalRHost}/`
  signalRCrawlerUrl = `${masterSchema}${signalRCrawlerHost}/`

  // ------------------------------------------------
} else if (process.env.REACT_APP_API_ENV === 'development-prod') {
  // -- production URL from dev ------------------------------
  agencyHost = `${masterSchema}ai-gentins.com`
  masterHost = `${masterSchema}ai-gentins.com`
  crawlerHost = `${masterSchema}ai-gentins.com`
  signalRHost = `${masterSchema}ai-gentins.com`
  signalRCrawlerHost = `${masterSchema}ai-gentins.com`

  agencyUrl = (agency = '{agency}') => `${agencyHost}/api/${agency}/`
  masterUrl = `${masterHost}/master/api/`
  crawlerUrl = `${crawlerHost}/`
  signalRUrl = `${signalRHost}/`
  signalRCrawlerUrl = `${signalRCrawlerHost}/`

  // ------------------------------------------------
} else if (process.env.REACT_APP_API_ENV === 'development-stage') {
  // -- production URL from dev ------------------------------
  agencyHost = 'https://stage.ai-gentins.com'
  masterHost = 'https://stage.ai-gentins.com'
  crawlerHost = 'https://stage.ai-gentins.com'
  signalRHost = 'https://stage.ai-gentins.com'
  signalRCrawlerHost = 'https://stage.ai-gentins.com'

  agencyUrl = (agency = '{agency}') => `${agencyHost}/api/${agency}/`
  masterUrl = `${masterHost}/master/api/`
  crawlerUrl = `${crawlerHost}/`
  signalRUrl = `${signalRHost}/`
  signalRCrawlerUrl = `${signalRCrawlerHost}/`

  // ------------------------------------------------
} else {
  // -- production URL ------------------------------
  agencyUrl = (agency = '{agency}') => `/api/${agency}/`
  masterUrl = `/master/api/`
  crawlerUrl = '/crawler/'
  signalRUrl = `/master/`
  signalRCrawlerUrl = `/crawler/`

  // ------------------------------------------------
}

const { origin: currOrigin } = window.location
export const useCrossDomainCookies = currOrigin.includes('localhost') ? '; Secure=true; SameSite=None;' : ''

export const urlDashboard = (agency = '{agency}') => `${agencyUrl(agency)}dashboard/`
export const urlCustomers = () => `${agencyUrl()}customers/`
export const urlCustomer = () => `${agencyUrl()}single-customer/`
export const urlCompany = (agency = '{agency}') => `${agencyUrl(agency)}Company/`
export const urlPolicy = (agency = '{agency}') => `${agencyUrl(agency)}policy/`
export const urlAnalytics = () => `${agencyUrl()}analytics/`
export const urlAgency = (agency = '{agency}') => `${agencyUrl(agency)}agency/`
export const urlProfile = (agency = '{agency}') => `${agencyUrl(agency)}profile/`
export const urlTreatment = () => `${agencyUrl()}treatment/`
export const urlWorkstation = () => `${agencyUrl()}workstation/`
export const urlAgentGroups = () => `${agencyUrl()}agentGroup/`
export const urlCustomerGroups = () => `${agencyUrl()}customer-group/`
export const urlReportRecords = (agency = '{agency}') => `${agencyUrl(agency)}ReportRecord/`
export const urlCompanyUsers = (agency = '{agency}') => `${agencyUrl(agency)}company-users/`

export const urlCrawlerActions = (agency = '{agency}') => `${crawlerUrl}${agency}/api/Actions/`
export const urlCrawlerStatus = (agency = '{agency}') => `${crawlerUrl}${agency}/api/status/`
export const urlSignalRCrawler = () => `${signalRCrawlerUrl}`

export const urlRegistration = () => `${masterUrl}`
export const urlMaster = () => `${masterUrl}`
export const urlSignalR = () => `${signalRUrl}`
export const urlBigAgency = () => `${masterUrl}bigagency`
export const urlBigAgencyAnalytics = () => `${masterUrl}Analytics/`
export const urlAgreement = () => `${masterUrl}agreement/`

export const sharedRoutes = {
  login: '/login',
}

export const agencyRoutes = {
  dashboard: 'lobby',
  lobby: 'lobby',
  analytcs: 'analytics',
  customers: 'customers',
  customer: 'single-customer',
  company: 'company',
  policy: 'policy',
  policies: 'policies',
  download: 'download',
  agency: 'agency',
  profile: 'profile',
  externalData: 'externalData',
  treatment: 'treatment',
  workstation: 'workstation',
  scan: 'download',
  noPayment: 'nopayment',
}

export const agencyProfileSuroutes = {
  details: '/details',
  fields: '/fields',
  companies: '/companies',
  employees: '/employees',
  agencies: '/agencies',
  payments: '/payments',
  settings: '/settings',
}

export const agencyHouseRoutes = {
  agencies: '/agencies',
  lobby: '/lobby',
  subagency: '/:subAgency',
  download: '/download',
  analytics: '/analytics',
}

export const agencyHouseAnalyticsSubroute = {
  incomes: '/incomes',
  incomesNew: '/incomesnew',
  policies: '/policies',
  policiesNew: '/policiesnew',
  customers: '/customers',
  signals: '/signals',
  rating: '/rating',
}
