import { useTranslation } from 'react-i18next'
import './AgencyHouseLobbySalesField.scss'
import { hebrew } from '../../../../i18n'
import AgencyHouseLobbySalesCategoryCard from './AgencyHouseLobbySalesCategoryCard'
import { formatAsNumber } from '../../../../utils/formatAs'

const AgencyHouseLobbySalesField = ({ field, potentialField }) => {
  const { t } = useTranslation('agencyHouseLobby')
  const { name, nameEn, customersCount, categories } = field
  const fieldName = hebrew() ? name : nameEn
  const totalCustomers = t('sales.totalCustomers', { customers: formatAsNumber(customersCount) })

  return (
    <div className='agency-house-lobby-sales-field'>
      <header>
        <p>{fieldName}</p>
        <p>{totalCustomers}</p>
      </header>
      <main>
        {categories.map(c => (
          <AgencyHouseLobbySalesCategoryCard category={c} key={c.categoryId} potentialCategory={potentialField} />
        ))}
      </main>
    </div>
  )
}

export default AgencyHouseLobbySalesField
