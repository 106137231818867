import PrivateRoute from '../HOCs/PrivateRoute'
import {
  agencyHouseAnalyticsSubroute,
  agencyHouseRoutes,
  agencyRoutes,
  agencyProfileSuroutes,
} from '../environment/urls'
import { agencyUserRoleType, userPreferenceType } from '../types/agencyUserTypes'
import AgenciesScreen from '../views/Agencies/AgenciesScreen'
import CustomerScreen from '../views/Customer/CustomerScreen'
import CustomersScreen from '../views/Customers/CustomersScreen'
import LobbyScreen from '../views/Lobby/LobbyScreen'
import NoPayment from '../views/NoPayment/NoPayment'
import NoSubscription from '../views/NoSubscription/NoSubscription'
import PoliciesScreen from '../views/Policies/PoliciesScreen'
import ProfileScreen from '../views/Profile/ProfileScreen'
import WorkstationScreen from '../views/Workstation/WorkstationScreen'
import RegisterScreen from '../views/Registration/RegisterScreen'
import LoginScreen from '../views/Login/LoginScreen'
import MarketingPage from '../views/MarketingPage/MarketingPage'
import EarlyAccessPage from '../views/EarlyAccessPage/EarlyAccessPage'
import AgencyHouseLobbyScreen from '../views/AgencyHouseLobby/AgencyHouseLobbyScreen'
import { GroupAddTwoTone, HomeTwoTone, InsertChartTwoTone } from '@mui/icons-material'
import AgencyHouseDownloadScreen from '../views/AgencyHouseDownload/AgencyHouseDownloadScreen'
import { Download } from '@mui/icons-material'
import AgencyHouseAnalyticsScreen from '../views/AgencyHouseAnalytics/AgencyHouseAnalyticsScreen'
import AgencyHouseAnalyticsIncomes from '../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsIncomes/AgencyHouseAnalyticsIncomes'
import AgencyHouseAnalyticsPolicies from '../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsPolicies/AgencyHouseAnalyticsPolicies'
import AgencyHouseAnalyticsCustomers from '../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsCustomers/AgencyHouseAnalyticsCustomers'
import AgencyHouseAnalyticsSignals from '../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsSignals/AgencyHouseAnalyticsSignals'
import AgencyHouseAnalyticsRating from '../features/agencyHouseAnalytics/components/AgencyHouseAnalyticsRating/AgencyHouseAnalyticsRating'
import ProfileFields from '../features/profile/components/ProfileFields/ProfileFields'
import ProfileCompanies from '../features/profile/components/ProfileCompanies/ProfileCompanies'
import ProfileInfo from '../features/profile/components/ProfileInfo/ProfileInfo'
import ScanScreen from '../views/Scan/ScanScreen'
import ProfileEmployees from '../features/profile/components/ProfileEmployees/ProfileEmployees'
import ProfileConnectedAgencies from '../features/profile/components/ProfileConnectedAgencies/ProfileConnectedAgencies'
import ProfilePayments from '../features/profile/components/ProfilePayments/ProfilePayments'
import ProfileSettings from '../features/profile/components/ProfileSettings/ProfileSettings'
import store from '../app/store'

export const regularAgencyPrivateRoutes = [
  {
    path: '/lobby',
    exact: true,
    component: LobbyScreen,
    requiredPreferences: [userPreferenceType.lobby],
    noAccessRedirect: '/workstation',
  },
  {
    path: '/workstation',
    exact: true,
    component: WorkstationScreen,
    allowedRoles: [
      agencyUserRoleType.fullUserAccess,
      agencyUserRoleType.administrator,
      agencyUserRoleType.agent,
      agencyUserRoleType.watchOnly,
    ],
  },
  {
    path: `${agencyHouseRoutes.analytics}`,
    component: AgencyHouseAnalyticsScreen,
    navIcon: <InsertChartTwoTone />,
    name: '\nהתיק שלי',
    nameEn: 'My Bag',
    subroute: {
      [agencyHouseAnalyticsSubroute.incomes]: {
        path: agencyHouseAnalyticsSubroute.incomes,
        component: <AgencyHouseAnalyticsIncomes />,
        name: 'מתיק קיים',
        nameEn: 'from all policies',
      },
      [agencyHouseAnalyticsSubroute.incomesNew]: {
        path: agencyHouseAnalyticsSubroute.incomesNew,
        component: <AgencyHouseAnalyticsIncomes />,
        name: 'מפוליסות חדשות',
        nameEn: 'from new policies',
      },
      [agencyHouseAnalyticsSubroute.policies]: {
        path: agencyHouseAnalyticsSubroute.policies,
        component: <AgencyHouseAnalyticsPolicies />,
        name: 'סה״כ',
        nameEn: 'all',
      },
      [agencyHouseAnalyticsSubroute.policiesNew]: {
        path: agencyHouseAnalyticsSubroute.policiesNew,
        component: <AgencyHouseAnalyticsPolicies />,
        name: 'חדשות',
        nameEn: 'new',
      },
      [agencyHouseAnalyticsSubroute.customers]: {
        path: agencyHouseAnalyticsSubroute.customers,
        component: <AgencyHouseAnalyticsCustomers />,
        name: 'לקוחות',
        nameEn: 'Customers',
      },
      [agencyHouseAnalyticsSubroute.rating]: {
        path: agencyHouseAnalyticsSubroute.rating,
        component: <AgencyHouseAnalyticsRating />,
        name: 'מדרג לקוחות',
        nameEn: 'Customer Rating',
      },
    },
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
    requiredPreferences: [userPreferenceType.analytics],
    noAccessRedirect: '/workstation',
  },
  {
    path: '/customers',
    exact: true,
    component: CustomersScreen,
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
  },
  {
    path: '/customers/:id',
    exact: true,
    component: CustomerScreen,
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
  },
  {
    path: '/policies',
    exact: true,
    component: PoliciesScreen,
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
  },
  {
    path: '/' + agencyRoutes.profile,
    exact: false,
    component: ProfileScreen,
    subroute: {
      [agencyProfileSuroutes.details]: {
        path: agencyProfileSuroutes.details,
        component: ProfileInfo,
        name: 'פרטי משתמש',
        nameEn: 'User Information',
      },
      [agencyProfileSuroutes.fields]: {
        path: agencyProfileSuroutes.fields,
        component: ProfileFields,
        name: 'ענפים',
        nameEn: 'Fields',
      },
      [agencyProfileSuroutes.companies]: {
        path: agencyProfileSuroutes.companies,
        component: ProfileCompanies,
        name: 'חברות',
        nameEn: 'Companies',
      },
      [agencyProfileSuroutes.employees]: {
        path: agencyProfileSuroutes.employees,
        component: ProfileEmployees,
        name: 'עובדים בסוכנות',
        nameEn: 'Agency Employees',
      },
      [agencyProfileSuroutes.agencies]: {
        path: agencyProfileSuroutes.agencies,
        component: ProfileConnectedAgencies,
        name: 'שיקוף נתונים',
        nameEn: 'Data Mirroring',
      },
      [agencyProfileSuroutes.settings]: {
        path: agencyProfileSuroutes.settings,
        component: ProfileSettings,
        name: 'הגדרות חשבון/ התראות',
        nameEn: 'Account settings/Notifications',
      },
      [agencyProfileSuroutes.payments]: {
        path: agencyProfileSuroutes.payments,
        component: ProfilePayments,
        name: 'תשלומים',
        nameEn: 'Payments',
      },
    },
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
  },
  {
    path: '/' + agencyRoutes.scan,
    exact: false,
    component: ScanScreen,
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
    requiredPreferences: [userPreferenceType.download],
  },
]

export const bigAgencyPrivateRoutes = [
  {
    path: `${agencyHouseRoutes.lobby}`,
    component: AgencyHouseLobbyScreen,
    navIcon: <HomeTwoTone />,
    name: 'לובי',
    nameEn: 'Lobby',
    exact: true,
  },
  {
    path: `${agencyHouseRoutes.lobby}${agencyHouseRoutes.subagency}`,
    component: LobbyScreen,
    navIcon: <HomeTwoTone />,
    name: 'Sub Agnecy Lobby',
    nameEn: 'Sub Agnecy Lobby',
    exact: true,
  },
  {
    path: `${agencyHouseRoutes.analytics}`,
    component: AgencyHouseAnalyticsScreen,
    navIcon: <InsertChartTwoTone />,
    name: '\nהתיק שלי',
    nameEn: 'My Bag',
    subroute: {
      [agencyHouseAnalyticsSubroute.incomes]: {
        path: agencyHouseAnalyticsSubroute.incomes,
        component: <AgencyHouseAnalyticsIncomes />,
        name: 'מתיק קיים',
        nameEn: 'from all policies',
      },
      [agencyHouseAnalyticsSubroute.incomesNew]: {
        path: agencyHouseAnalyticsSubroute.incomesNew,
        component: <AgencyHouseAnalyticsIncomes />,
        name: 'מפוליסות חדשות',
        nameEn: 'from new policies',
      },
      [agencyHouseAnalyticsSubroute.policies]: {
        path: agencyHouseAnalyticsSubroute.policies,
        component: <AgencyHouseAnalyticsPolicies />,
        name: 'סה״כ',
        nameEn: 'all',
      },
      [agencyHouseAnalyticsSubroute.policiesNew]: {
        path: agencyHouseAnalyticsSubroute.policiesNew,
        component: <AgencyHouseAnalyticsPolicies />,
        name: 'חדשות',
        nameEn: 'new',
      },
      [agencyHouseAnalyticsSubroute.customers]: {
        path: agencyHouseAnalyticsSubroute.customers,
        component: <AgencyHouseAnalyticsCustomers />,
        name: 'לקוחות',
        nameEn: 'Customers',
      },
      [agencyHouseAnalyticsSubroute.signals]: {
        path: agencyHouseAnalyticsSubroute.signals,
        component: <AgencyHouseAnalyticsSignals />,
        name: 'איתותים',
        nameEn: 'Signals',
      },
    },
  },
  {
    path: `${agencyHouseRoutes.agencies}`,
    component: AgenciesScreen,
    navIcon: <GroupAddTwoTone />,
    name: 'סוכנות',
    nameEn: 'Agencies',
  },
  {
    path: `${agencyHouseRoutes.download}`,
    component: AgencyHouseDownloadScreen,
    navIcon: <Download />,
    name: 'Download',
    nameEn: 'Download',
  },
]

export const sharedPrivateRoutes = [
  {
    path: `/${agencyRoutes.noPayment}`,
    exact: true,
    component: NoPayment,
    allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
  },
  // {
  //   path: '/nopayment',
  //   exact: true,
  //   component: NoPayment,
  //   allowedRoles: [agencyUserRoleType.fullUserAccess, agencyUserRoleType.agent, agencyUserRoleType.watchOnly],
  // },
  // { path: '/nosubscription', exact: true, component: NoSubscription },
]

export const sharedPublicRoutes = [
  { path: '/register', exact: true, component: RegisterScreen },
  { path: '/login', exact: true, component: LoginScreen },
  { path: '/early', exact: true, component: EarlyAccessPage },
  { path: '/marketing', exact: true, component: MarketingPage },
]

export const agencyAfterLoginPath = () => {
  const { role } = store.getState().login
  return `/${role === agencyUserRoleType.administrator ? agencyRoutes.workstation : agencyRoutes.workstation}`
}
