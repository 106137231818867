export const getMonthName = (date, langauge, length = 'long') => {
  const locale = langauge && langauge === 'he' ? 'he-Il' : langauge === 'en' ? 'en-Us' : 'default'
  if (isNotaDate(date)) return '--'
  return new Date(date).toLocaleString(locale, {
    month: length,
  })
}

/**
 * @param {number} monthNumber - Month Number starting from 1
 */
export const getMonthNameByNumber = (monthNumber, langauge, length) => {
  const date = new Date(new Date().getFullYear(), monthNumber - 1, 1)
  if (isNotaDate(date)) return '--'
  return getMonthName(date, langauge, length)
}

export const isNotaDate = date => isNaN(Date.parse(date))
