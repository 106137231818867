import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AddUserModal from '../../features/customer/components/AddUserModal/AddUserModal'
import { getAgents } from '../../features/shared/actions/sharedActions'
import { setTaskAgent } from '../../features/workstation/actions/workstationActions'
import SetTaskStatusMenu from '../../features/workstation/components/SetTaskStatusMenu/SetTaskStatusMenu'
import { hebrew } from '../../i18n'
import { WORKSTATION_TASK_AGENT_RESET } from '../../types/actionTypes'
import { agencyUserRoleType } from '../../types/agencyUserTypes'
import Snackbar from '../Common/Snackbar/Snackbar'
import './TreatmentSnackbar.scss'

const TreatmentSnackbar = ({ tasks = [], closeBar = () => {}, children }) => {
  const { role } = useSelector(({ login }) => login)
  const { agents, agentsLoading } = useSelector(({ shared }) => shared)
  const { taskAgentLoading, taskAgentSuccess } = useSelector(state => state.workstation)
  const dispatch = useDispatch()

  const [showUpdateTreatUserModal, setShowUpdateTreatUserModal] = useState(false)
  const [showSetStatusMenu, setShowSetStatusMenu] = useState(false)

  const itemsCount = tasks.length
  const canEditTaskAgent = role === agencyUserRoleType.fullUserAccess || role === agencyUserRoleType.agent
  const actions = [
    {
      icon: './assets/analytics/Activity-sm.png',
      text: hebrew() ? 'סטטוס' : 'Status',
      action: () => setShowSetStatusMenu(true),
      disabled: !tasks.every((t, _, arr) => t.status === arr[0].status),
      styleClass: 'update-status-wrapper',
      showChildMenu: showSetStatusMenu,
    },
    {
      icon: './assets/button-icons/send-light/send.png',
      text: hebrew() ? 'העבר ל' : 'Send to',
      action: () => setShowUpdateTreatUserModal(true),
      disabled: !canEditTaskAgent,
    },
  ]

  const updateAgent = agent => {
    dispatch(setTaskAgent({ ids: tasks.map(t => t.id), agentId: agent.id, agentName: agent.name }))
  }

  useEffect(() => {
    if (showUpdateTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showUpdateTreatUserModal, dispatch])

  useEffect(() => {
    if (taskAgentSuccess) {
      setShowUpdateTreatUserModal(false)
      dispatch({ type: WORKSTATION_TASK_AGENT_RESET })
      closeBar()
    }
  }, [taskAgentSuccess, closeBar, dispatch])

  return (
    <>
      <Snackbar closeBar={closeBar}>
        <div className='treatment-snackbar'>
          <div className='snackbar-items-count'>{itemsCount}</div>
          <div className='snackbar-actions'>
            {actions.map(ac => (
              <div className={classNames('snackbar-action-container', ac.styleClass)} key={ac.text}>
                <button
                  className={classNames('snackbar-action', {
                    disabled: ac.disabled,
                  })}
                  onClick={ac.action}
                  disabled={ac.disabled}
                >
                  <div className='snackbar-action-ico'>
                    <img src={ac.icon} alt={ac.text} />
                  </div>
                  <div className='snackbar-action-text'>{ac.text}</div>
                </button>
                {ac.showChildMenu && showSetStatusMenu && (
                  <div className='task-item-status-menu-wrapper'>
                    <SetTaskStatusMenu
                      closeMenu={() => setShowSetStatusMenu(false)}
                      onCloseMenu={closeBar}
                      tasks={tasks}
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </Snackbar>
      {showUpdateTreatUserModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading || taskAgentLoading}
          closeModal={() => setShowUpdateTreatUserModal(false)}
          closeOnOutsideClick={false}
          onAddUser={updateAgent}
        />
      )}
    </>
  )
}

export default TreatmentSnackbar
