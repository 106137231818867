import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import AgencyHouseLobbyHeader from '../../features/agencyHouseLobby/components/AgencyHouseLobbyHeader/AgencyHouseLobbyHeader'
import AgencyHouseLobbyTotals from '../../features/agencyHouseLobby/components/AgencyHouseLobbyTotals/AgencyHouseLobbyTotals'
import ButtonRounded from '../../components/Common/ButtonRounded/ButtonRounded'
import AgencyHouseLobbySignals from '../../features/agencyHouseLobby/components/AgencyHouseLobbySignals/AgencyHouseLobbySignals'
import AgencyHouseLobbySales from '../../features/agencyHouseLobby/components/AgencyHouseLobbySales/AgencyHouseLobbySales'
import ExportButton from '../../components/Common/ExportButton/ExportButton'
import { familyGroupType } from '../../types/familyGroupTypes'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'
import { getAgencyHouseSales, getLobbyTotalsRequest } from '../../http/requests/agencyHouseRequests'
import { agencyHouseRoutes } from '../../environment/urls'
import { capitalizeObjectKeys } from '../../utils/objectUtils'
import './LobbyScreen.scss'

const LobbyScreen = () => {
  const { subAgency } = useParams()
  const [totals, setTotals] = useState(totalsInitial)
  const [totalsLoading, setTotalsLoading] = useState(true)
  const [signals, setSignals] = useState({})
  const [sales, setSales] = useState({ existingCategories: [], nonExistingCategories: [] })
  const [salesLoading, setSalesLoading] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const { selectedGroupsIds, familyGroupsType } = useSelector(({ shared }) => shared)

  const { t: tCommon } = useTranslation('common')
  const { t } = useTranslation('agencyHouseLobby')

  useEffect(() => {
    const fetchTotals = async () => {
      try {
        setTotalsLoading(true)
        let {
          data: { data },
        } = await getLobbyTotalsRequest({
          regularAgency: true,
          familyGroup: familyGroupsType === familyGroupType.group,
          ...(subAgency && { agency: subAgency }),
        })
        data = capitalizeObjectKeys(data)
        setTotals(data)
        setSignals({
          ...(data.MissingOrNoPremiumIrregularitiesCount !== 0 && {
            cancellation: {
              count: data.MissingOrNoPremiumIrregularitiesCount,
              value: data.MissingOrNoPremiumIrregularitiesValue,
            },
          }),
          ...(data.NegativePaymentIrregularitiesCount !== 0 && {
            negativePayment: {
              count: data.NegativePaymentIrregularitiesCount,
              value: data.NegativePaymentIrregularitiesValue,
            },
          }),
          ...(data.CommissionRateIrregularitiesCount !== 0 && {
            commissionDowngrade: {
              count: data.CommissionRateIrregularitiesCount,
              value: data.CommissionRateIrregularitiesValue,
            },
          }),
          // ...(data.PoliciesThatEndsNextMonthCount !== 0 && {
          //   renewal: { count: data.PoliciesThatEndsNextMonthCount, value: data.PoliciesThatEndsNextMonthValue },
          // }),
        })
        setTotalsLoading(false)
      } catch (error) {
        setTotalsLoading(false)
        console.log(error)
      }
    }
    fetchTotals()
  }, [selectedGroupsIds, subAgency, familyGroupsType])

  useEffect(() => {
    const fetchSales = async () => {
      try {
        setSalesLoading(true)
        const {
          data: { data },
        } = await getAgencyHouseSales({ regularAgency: true, ...(subAgency && { agency: subAgency }) })
        setSales(data)
        setSalesLoading(false)
      } catch (error) {
        setSalesLoading(false)
        console.log(error)
      }
    }
    showMore && fetchSales()
  }, [showMore, selectedGroupsIds, subAgency])

  if (isMobile) {
    return <NoMobileLayout pageName={tCommon('sidebar.lobby')} />
  }

  return (
    <div className='lobby-screen'>
      {subAgency && (
        <div>
          <NavLink to={`${agencyHouseRoutes.agencies}/subagencies`}>
            <ExportButton />
            <span>{t('goBackToBigAgency')}</span>
          </NavLink>
        </div>
      )}
      <div className='lobby-screen-banners'>
        <BackToBigAgency />
        <AgentGroupsBanner />
      </div>
      <div className='agency-house-lobby-screen'>
        <AgencyHouseLobbyHeader
          date={totals.Month && new Date(+totals.Month?.split('-')[0], +totals.Month?.split('-')[1] - 1, 1)}
        />
        <AgencyHouseLobbyTotals
          MonthlyCommission={totals.MonthlyCommission}
          MonthlyPremium={totals.MonthlyPremium}
          MonthlyAccumulation={totals.MonthlyAccumulation}
          NewCommission={totals.NewCommission}
          NewPoliciesCount={totals.NewPoliciesCount}
          NewPoliciesFromExistingClientsCount={totals.NewPoliciesFromExistingClientsCount}
          NewPoliciesFromNewClientsCount={totals.NewPoliciesFromNewClientsCount}
          AverageCommission={totals.AverageCommission}
          CommissionAverageForLastThreeMonth={totals.CommissionAverageForLastThreeMonth}
          totalsLoading={totalsLoading}
        />
        <ButtonRounded variant='outlined' onClick={() => setShowMore(!showMore)}>
          {t('seeMoreBtn')}
        </ButtonRounded>
        {showMore && (
          <>
            <AgencyHouseLobbySignals signals={signals} signalsLoading={totalsLoading} />
            <AgencyHouseLobbySales sales={sales} loading={salesLoading} />
          </>
        )}
      </div>
    </div>
  )
}

export default LobbyScreen

const totalsInitial = {
  AverageCommission: { Value: 0, Trend: false, Difference: 0 },
  CommissionAverageForLastThreeMonth: [],
  MonthlyAccumulation: { Value: 0, Trend: false, Difference: 0 },
  MonthlyCommission: { Value: 0, Trend: false, Difference: 0 },
  MonthlyPremium: { Value: 0, Trend: false, Difference: 0 },
  NewCommission: { Value: 0, Trend: false, Difference: 0 },
  NewPoliciesCount: 0,
  NewPoliciesFromExistingClientsCount: 0,
  NewPoliciesFromNewClientsCount: 0,
  MissingOrNoPremiumIrregularitiesCount: 0,
  MissingOrNoPremiumIrregularitiesValue: 0,
  NegativePaymentIrregularitiesCount: 0,
  NegativePaymentIrregularitiesValue: 0,
  CommissionRateIrregularitiesCount: 0,
  CommissionRateIrregularitiesValue: 0,
  PoliciesThatEndsNextMonthCount: 0,
  PoliciesThatEndsNextMonthValue: 0,
}
