import { useTranslation } from 'react-i18next'
import './MultipleUsersAlert.scss'
import ButtonRounded from '../Common/ButtonRounded/ButtonRounded'

const MultipleUsersAlert = ({ company, twoUsers, setTwoUsers }) => {
  const { t, i18n } = useTranslation('download')
  const companyName = i18n.language === 'he' ? company.name : company.nameEn
  const btnCaption = t(`mainContainer.alert.btnHave${twoUsers ? 'OneUser' : 'TwoUsers'}`)
  return (
    <div className='download-stage-alert'>
      <div>
        <p>
          {t('mainContainer.alert.textRow1', {
            companyName,
          })}
        </p>
        <p>{t('mainContainer.alert.textRow2')}</p>
      </div>
      <ButtonRounded onClick={() => setTwoUsers(!twoUsers)} variant='outlined'>
        {btnCaption}
      </ButtonRounded>
    </div>
  )
}

export default MultipleUsersAlert
