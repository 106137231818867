import { ArrowBack, ArrowForward } from '@mui/icons-material'
import ButtonText from '../../../../components/Common/ButtonText/ButtonText'
import ModalSidebar from '../../../../components/Common/ModalSidebar/ModalSidebar'
import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import './CustomerTotalsDetails.scss'
import HierarchyPicker from '../../../../components/HierarchyPicker/HierarchyPicker'
import { customerDataTypeCard } from '../../../../types/customerDataTypes'
import { useEffect, useState } from 'react'
import { getCustomerTotalsDetailsRequest } from '../../../../http/requests/customerRequests'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import CustomerTotalsDetailsList from './CustomerTotalsDetailsList'

const companyHierarchyEntity = {
  id: 2,
  text: 'יצרנים',
  textEn: 'Companies',
}

const categoryHierarchyEntity = {
  id: 3,
  text: 'מוצרים',
  textEn: 'Categories',
}

const entities = [companyHierarchyEntity, categoryHierarchyEntity]

const hierarchies = [
  {
    id: 1,
    entitiesOrder: [companyHierarchyEntity.id, categoryHierarchyEntity.id],
    text: `${companyHierarchyEntity.text} -> ${categoryHierarchyEntity.text}`,
    textEn: `${companyHierarchyEntity.textEn} -> ${categoryHierarchyEntity.textEn}`,
    selected: true,
  },
  {
    id: 2,
    entitiesOrder: [categoryHierarchyEntity.id, companyHierarchyEntity.id],
    text: `${categoryHierarchyEntity.text} -> ${companyHierarchyEntity.text}`,
    textEn: `${categoryHierarchyEntity.textEn} -> ${companyHierarchyEntity.textEn}`,
  },
]

const CustomerTotalsDetails = ({ closeDetails, totalsType, id }) => {
  const [details, setDetails] = useState([])
  const [detailsLoading, setDetailsLoading] = useState(true)
  const [detailsError, setDetailsError] = useState(null)
  const [hierarchy, setHierarchy] = useState(hierarchies)
  const { t } = useTranslation('customer')
  const { icon, typeKey } = customerDataTypeCard[totalsType]
  const remark = t(`totalsCard.value${typeKey.includes('Payments') ? 'Payments' : ''}Title`)

  const calculateByGroup = familyGroupMode()
  const setHierarchyHandler = id => setHierarchy(prev => prev.map(pr => ({ ...pr, selected: pr.id === id })))

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setDetailsLoading(true)
        setDetails([])
        setDetailsError(null)
        const {
          data: { data },
        } = await getCustomerTotalsDetailsRequest({
          id: +id,
          calculateByGroup,
          dataType: totalsType,
          entityTypeOrder: hierarchy.find(h => h.selected).entitiesOrder,
        })
        setDetails(data)
        setDetailsLoading(false)
      } catch (error) {
        setDetailsLoading(false)
        setDetailsError('Error getting details')
      }
    }
    fetchDetails()
  }, [id, hierarchy, totalsType, calculateByGroup])

  return (
    <ModalSidebar closeSidebar={closeDetails}>
      <div className='customer-totals-details'>
        <section>
          <div>
            <ButtonText onClick={closeDetails}>
              <span>{t('totalsCard.backToCustomerPage')}</span>
              {hebrew() ? <ArrowBack /> : <ArrowForward />}
            </ButtonText>
          </div>
          <div>
            <div>
              <div>{icon}</div>
              <p>{t(`totalsCard.${typeKey}Title`)}</p>
            </div>
            <HierarchyPicker hierarchies={hierarchy} setHierarchy={setHierarchyHandler} enteties={entities} />
          </div>
          <p>{remark}</p>
        </section>
        <section>
          <CustomerTotalsDetailsList list={details} loading={detailsLoading} error={detailsError} />
        </section>
      </div>
    </ModalSidebar>
  )
}

export default CustomerTotalsDetails
