import { Close } from '@mui/icons-material'
import ModalWindow from '../ModalWindow/ModalWindow'
import ButtonRounded from '../Common/ButtonRounded/ButtonRounded'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { LOGIN_CLOSE_UI_TRANSITION_WARN } from '../../types/actionTypes'
import './UiTransitionWarnModal.scss'

const UiTransitionWarnModal = () => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch({ type: LOGIN_CLOSE_UI_TRANSITION_WARN })
  }
  return (
    <ModalWindow showCloseIcon={false}>
      <div
        className='ui-transition-warn-modal'
        style={{
          backgroundImage: `url('assets/_updated-ui-warn-modal/bg.png')`,
          backgroundPosition: 'center top',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <button onClick={closeModal} className='ui-transition-warn-modal-close-btn'>
          <Close />
        </button>
        <div className='ui-transition-warn-modal-img'>
          <img src='./assets/_updated-ui-warn-modal/updated-ui-warn-modal-img.png' alt='img' />
        </div>
        <div className='ui-transition-warn-modal-text'>
          <h5>{t('UiTransitionWarnModal.header')}</h5>
          <p>{t('UiTransitionWarnModal.text')}</p>
        </div>
        <div className='ui-transition-warn-modal-footer'>
          <ButtonRounded onClick={closeModal}>{t('UiTransitionWarnModal.acceptBtn')}</ButtonRounded>
        </div>
      </div>
    </ModalWindow>
  )
}

export default UiTransitionWarnModal
