import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalWindow from '../ModalWindow/ModalWindow'
import './AddAgentGroupModal.scss'
import SearchAgentsInput from './SearchAgentsInput/SearchAgentsInput'
import Button from '../Common/Button/Button'
import {
  addAgentGroup,
  getAgentNumbers,
  removeAgentNumber,
  restoreAgentNumber,
  updateAgentGroup,
} from '../../features/shared/actions/sharedActions'
import Loader from '../Common/Loader/Loader'
import { hebrew } from '../../i18n'
import { getUserNameInitials } from '../../services/getUserNameInitials'
import { getRandomInt } from '../../services/getRandom'
import CrossMark from '../Common/Marks/CrossMark/CrossMark'
import InputText from '../Common/InputText/InputText'
import {
  SHARED_ADD_GROUP_RESET,
  SHARED_AGENT_NUMBERS_RESET,
  SHARED_UPDATE_GROUP_RESET,
} from '../../types/actionTypesShared'
import NoData from '../Common/NoData/NoData'
import { avatarStyles } from '../../types/colorsTypes'
import { sortBy, sortOrder } from '../../types/sortByTypes'
import LinedTitle from '../Common/LinedTitle/LinedTitle'
import LoadingOverlay from '../Common/LoadingOverlay/LoadingOverlay'
import ConfirmWarnDialog from '../ConfirmWarnDialog/ConfirmWarnDialog'
import { actionWithAgentType } from '../../types/actionWithAgentTypes'
import { DeleteOutline, RestoreFromTrashOutlined } from '@mui/icons-material'

const sorter = (order, key) => (a, b) => {
  const x = a[key]
  const y = b[key]
  if (x === y) {
    if (a.name && b.name) {
      return a.name.normalize().localeCompare(b.name.normalize())
    } else if (!a.name) {
      return 1
    } else if (!b.name) {
      return -1
    }
  } else if (x === null) {
    return 1
  } else if (y === null) {
    return -1
  }
  return order === sortOrder.asc
    ? x.normalize().localeCompare(y.normalize())
    : y.normalize().localeCompare(x.normalize())
}

const AddAgentGroupModal = ({ closeModal, group = null }) => {
  const [searchAgent, setSearchAgent] = useState('')
  const [groupName, setGroupName] = useState(group ? group.name : '')
  const [selectedAgents, setSelectedAgents] = useState(group ? group.agents : [])
  const [sort, setSort] = useState({
    by: sortBy.company,
    order: sortOrder.asc,
  })
  const { t } = useTranslation('profile')
  const {
    activeAgents,
    deletedAgents,
    agentNumbersLoading,
    addGroupLoading,
    addGroupSuccess,
    updateGroupSuccess,
    agentGroups,
  } = useSelector(({ shared }) => shared)
  const groupToUpdate = agentGroups.find(gr => gr.id === group?.id)
  const updateGroupLoading = groupToUpdate && groupToUpdate.loading
  const filterAgents = agents =>
    agents.filter(
      an =>
        an.agentNumber.toLowerCase().includes(searchAgent.toLowerCase()) ||
        (an.name && an.name.toLowerCase().includes(searchAgent.toLowerCase()))
    )

  const filteredActiveAgents = filterAgents(activeAgents)
  const filteredDeletedAgents = filterAgents(deletedAgents)

  const setSortHandler = sortBy => {
    if (sort.by === sortBy) {
      setSort(prev => {
        return {
          ...prev,
          order: prev.order !== sortOrder.asc ? sortOrder.asc : sortOrder.desc,
        }
      })
    } else {
      setSort({ by: sortBy, order: sortOrder.asc })
    }
  }

  const dispatch = useDispatch()

  const submitBtnDisabled =
    updateGroupLoading ||
    agentNumbersLoading ||
    addGroupLoading ||
    activeAgents.length === 0 ||
    selectedAgents.length === 0 ||
    groupName.length === 0

  const selectAgentHandler = agent => {
    setSelectedAgents(prev =>
      prev.map(a => a.id).includes(agent.id) ? prev.filter(an => an.id !== agent.id) : [...prev, agent]
    )
  }

  const selectAllAgentsHandler = agents => {
    // deselect if all are selected
    if (agents.every(({ id }) => selectedAgents.map(fa => fa.id).includes(id))) {
      setSelectedAgents(prev => prev.filter(an => !agents.map(fa => fa.id).includes(an.id)))
    } else {
      //select all if some are not
      const notIncluded = agents.filter(({ id }) => !selectedAgents.map(fa => fa.id).includes(id))
      setSelectedAgents(prev => [...prev, ...notIncluded])
    }
  }

  const addGroupHandler = () => {
    dispatch(
      group
        ? updateAgentGroup({ id: group.id, name: groupName, agents: selectedAgents })
        : addAgentGroup({
            name: groupName,
            agents: selectedAgents,
          })
    )
  }

  useEffect(() => {
    dispatch(getAgentNumbers())
    return () => dispatch({ type: SHARED_AGENT_NUMBERS_RESET })
  }, [dispatch])

  useEffect(() => {
    if ((addGroupSuccess && !addGroupLoading) || (group && updateGroupSuccess && !updateGroupLoading)) closeModal()
    return () => {
      addGroupSuccess && dispatch({ type: SHARED_ADD_GROUP_RESET })
      group && updateGroupSuccess && dispatch({ type: SHARED_UPDATE_GROUP_RESET })
    }
  }, [addGroupSuccess, addGroupLoading, updateGroupSuccess, group, updateGroupLoading, closeModal, dispatch])

  return (
    <ModalWindow
      closeOnOutsideClick={false}
      closeModal={closeModal}
      renderPortal
      showCloseIcon={false}
      loadingOverlay={addGroupLoading || updateGroupLoading}
    >
      <div className='add-agent-group-modal'>
        <header className='add-agent-group-modal-header'>
          <div>{t('addGroupModal.title')}</div>
          <button onClick={closeModal}>
            <img src='./assets/close-icon-square/close-square.png' alt='cls' />
          </button>
        </header>
        <div className='add-agent-group-modal-content'>
          <div className='add-agent-group-modal-sidebar'>
            <InputText
              value={groupName}
              onChange={setGroupName}
              required={true}
              label={t('addGroupModal.namePlaceholder')}
            />
            <div className='add-agent-group-modal-selected-heading'>{t('addGroupModal.agentNumbers')}</div>
            <div className='add-agent-group-modal-selected-items'>
              {selectedAgents.map(an => (
                <div className='add-agent-group-modal-selected-item' key={an.id}>
                  <div className='add-agent-group-modal-company-logo'>
                    <img src={`./assets/companies-logos-light/${an.companyId}.png`} alt={an.companyNameEn} />
                  </div>
                  <div>{an.agentNumber}</div>
                  <div onClick={() => selectAgentHandler(an)}>
                    <CrossMark />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='add-agent-group-modal-main'>
            <div className='add-agent-group-modal-main-content'>
              {agentNumbersLoading ? (
                <Loader />
              ) : activeAgents.length === 0 && deletedAgents.length === 0 ? (
                <NoData text={t('addGroupModal.noAgents')} />
              ) : (
                <>
                  <div className='add-agent-group-search-container'>
                    <SearchAgentsInput
                      searchValue={searchAgent}
                      setSearchValue={setSearchAgent}
                      placeholder={t('addGroupModal.searchPlaceholder')}
                    />
                  </div>
                  <div className='add-agent-group-modal-list-container'>
                    <AgentsList
                      list={filteredActiveAgents}
                      listTitle={t('agentGroupListHeading.active')}
                      setSort={setSortHandler}
                      sort={sort}
                      selectItem={selectAgentHandler}
                      selectedItems={selectedAgents}
                      selectAllItmes={() => selectAllAgentsHandler(filteredActiveAgents)}
                    />
                    <AgentsList
                      list={filteredDeletedAgents}
                      listTitle={t('agentGroupListHeading.deleted')}
                      setSort={setSortHandler}
                      sort={sort}
                      selectItem={selectAgentHandler}
                      selectedItems={selectedAgents}
                      selectAllItmes={() => selectAllAgentsHandler(filteredDeletedAgents)}
                    />
                  </div>
                </>
              )}
            </div>
            <footer className='add-agent-group-modal-footer'>
              <Button caption={t('addGroupModal.saveBtn')} disabled={submitBtnDisabled} onClick={addGroupHandler} />
            </footer>
          </div>
        </div>
      </div>
    </ModalWindow>
  )
}

export default AddAgentGroupModal

const AgentsList = ({ list, listTitle, selectedItems, selectItem, selectAllItmes, sort, setSort }) => {
  const [showList, setShowList] = useState(true)
  const [agentToRemove, setAgentToRemove] = useState(null)
  const [agentToRestore, setAgentToRestore] = useState(null)

  const { t } = useTranslation('profile')
  const dispatch = useDispatch()

  const removeAgent = () => {
    dispatch(removeAgentNumber(agentToRemove))
    setAgentToRemove(null)
  }
  const restoreAgent = () => {
    dispatch(restoreAgentNumber(agentToRestore))
    setAgentToRestore(null)
  }

  const actionButton = agent => {
    const { isActive, nextPaymentAction } = agent

    const agentIsActive =
      (!isActive && nextPaymentAction === actionWithAgentType.activate) ||
      (isActive && nextPaymentAction !== actionWithAgentType.deactivate)

    const clickHanlder = agentIsActive ? setAgentToRemove : setAgentToRestore
    return (
      <button onClick={() => clickHanlder(agent)}>
        {agentIsActive ? <DeleteOutline /> : <RestoreFromTrashOutlined style={{ color: 'var(--trend-up-color)' }} />}
      </button>
    )
  }

  return (
    <div>
      <LinedTitle
        title={listTitle}
        count={list.length}
        collapsed={!showList}
        onCollapse={() => setShowList(!showList)}
        collapsible
      />
      {showList &&
        (list.length === 0 ? (
          <div className='add-agent-group-modal-no-data-container'>
            <NoData text='Nothing Found' />
          </div>
        ) : (
          <>
            <div className='add-agent-group-modal-list-heading add-agent-group-modal-list-grid'>
              <div>
                {list.length > 0 && (
                  <img
                    src={`./assets/${
                      list.every(({ id }) => selectedItems.map(fa => fa.id).includes(id))
                        ? 'checkbox-sm-checked/Square.png'
                        : 'check-box-blank/Check Box Blank.png'
                    }`}
                    alt='slct'
                    onClick={selectAllItmes}
                  />
                )}
              </div>
              <div>{t('agentGroupListHeading.agentNumber')}</div>
              <div>{t('agentGroupListHeading.agentName')}</div>
              <div onClick={() => setSort(sortBy.company)} className='sortable-column'>
                {t('agentGroupListHeading.company')}
                {sort.by === sortBy.company && (
                  <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
                )}
              </div>
              <div></div>
            </div>
            <div className='add-agent-group-modal-list'>
              {list.sort(sorter(sort.order, hebrew() ? 'companyName' : 'companyNameEn')).map(an => (
                <div key={an.id} className='add-agent-group-modal-list-item add-agent-group-modal-list-grid'>
                  {an.loading && <LoadingOverlay />}
                  <div>
                    <img
                      src={`./assets/${
                        selectedItems.map(a => a.id).includes(an.id)
                          ? 'checkbox-sm-checked/Square.png'
                          : 'check-box-blank/Check Box Blank.png'
                      }`}
                      alt='slct'
                      onClick={() => selectItem(an)}
                    />
                  </div>
                  <div>{an.agentNumber}</div>
                  <div className='add-agent-group-modal-list-item-name'>
                    <div
                      className='add-agent-group-modal-list-item-avatar'
                      style={avatarStyles[an?.colorIndex ?? getRandomInt(1, 3) - 1]}
                    >
                      {getUserNameInitials(an.name)}
                    </div>
                    <div>{an.name}</div>
                  </div>
                  <div className='add-agent-group-modal-list-item-company'>
                    <div className='add-agent-group-modal-company-logo'>
                      <img src={`./assets/companies-logos-light/${an.companyId}.png`} alt={an.companyNameEn} />
                    </div>
                    <div>{hebrew() ? an.companyName : an.companyNameEn}</div>
                  </div>
                  {actionButton(an)}
                </div>
              ))}
            </div>
          </>
        ))}
      {agentToRemove && (
        <ConfirmWarnDialog
          title={t(`deleteAgentWarnTitle`)}
          text={t(`delete${agentToRemove.deletedNextMonth ? 'Existing' : 'New'}AgentWarnText`)}
          cancelText={t('cancelAgentBtn')}
          confirmText={t('deleteAgentBtn')}
          cancelHandler={() => setAgentToRemove(null)}
          confirmHandler={removeAgent}
          dangerWarn
        />
      )}
      {agentToRestore && (
        <ConfirmWarnDialog
          title={t(`restoreAgentWarnTitle`)}
          text={t(`restoreAgentWarnText`)}
          cancelText={t('cancelAgentBtn')}
          confirmText={t('restoreAgentBtn')}
          cancelHandler={() => setAgentToRestore(null)}
          confirmHandler={restoreAgent}
        />
      )}
    </div>
  )
}
