import {
  analyticsIncomesList,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
} from '../../../../types/analyticsIncomesTypes'
import { hebrew } from '../../../../i18n'
import TabsNavbar from '../../../../components/TabsNavbar/TabsNavbar'
import './AgencyHouseAnalyticsIncomesListContainer.scss'
import { InsertChart, TableChart } from '@mui/icons-material'
import classNames from 'classnames'
import AgencyHouseAnalyticsIncomesTable from '../AgencyHouseAnalyticsIncomesTable/AgencyHouseAnalyticsIncomesTable'
import AgencyHouseAnalyticsIncomesChartsContainer from '../AgencyHouseAnalyticsIncomesChartsContainer/AgencyHouseAnalyticsIncomesChartsContainer'
import { isRegularAgency } from '../../../login/reducers/loginSelectors'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import AgencyHouseAnalyticsIncomesChangesTable from '../AgencyHouseAnalyticsIncomesChangesTable/AgencyHouseAnalyticsIncomesChangesTable'

const AgencyHouseAnalyticsIncomesListContainer = ({
  list,
  listLoading,
  listType,
  setListType,
  listViewType,
  setListViewType,
  periodViewType,
  dataType,
  totals,
  selectItem,
  selectedItem,
}) => {
  const ListComponent =
    listViewType === analyticsIncomesListViewType.table
      ? periodViewType === analyticsPeriodViewType.monthly
        ? AgencyHouseAnalyticsIncomesTable
        : AgencyHouseAnalyticsIncomesChangesTable
      : AgencyHouseAnalyticsIncomesChartsContainer

  return (
    <div className='agency-house-analytics-incomes-list-container'>
      {periodViewType === analyticsPeriodViewType.monthly && (
        <TabsNavbar
          items={Object.values(analyticsIncomesList)
            .filter(l => (isRegularAgency() ? l.type !== analyticsIncomesListType.agents : true))
            .map(t => ({
              ...t,
              id: t.type,
              text: hebrew() ? t.name : t.nameEn,
            }))}
          currentItem={listType}
          setCurrentItem={setListType}
        />
      )}
      <div className='agency-house-analytics-incomes-list-view-selector'>
        <button
          onClick={() => setListViewType(analyticsIncomesListViewType.chart)}
          className={classNames({ selected: listViewType === analyticsIncomesListViewType.chart })}
        >
          <InsertChart />
        </button>
        <button
          onClick={() => setListViewType(analyticsIncomesListViewType.table)}
          className={classNames({ selected: listViewType === analyticsIncomesListViewType.table })}
        >
          <TableChart />
        </button>
      </div>
      <ListComponent
        listType={listType}
        periodViewType={periodViewType}
        list={list}
        listLoading={listLoading}
        dataType={dataType}
        totals={totals}
        selectItem={selectItem}
        selectedItem={selectedItem}
      />
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesListContainer
