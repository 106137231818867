import { urlCustomer, urlPolicy, urlTreatment } from '../../../environment/urls'
import $api from '../../../http'
import { delay } from '../../../services/delay'
import {
  POLICY_CHECK,
  POLICY_SELECT,
  POLICY_FIX_REQUEST,
  POLICY_FIX_SUCCESS,
  POLICY_FIX_FAIL,
  POLICY_IGNORE_REQUEST,
  POLICY_IGNORE_SUCCESS,
  POLICY_IGNORE_FAIL,
  POLICY_IRREG_REQUEST,
  POLICY_IRREG_SUCCESS,
  POLICY_IRREG_FAIL,
  POLICY_SET_CATEGORY_REQUEST,
  POLICY_SET_CATEGORY_SUCCESS,
  POLICY_SET_CATEGORY_FAIL,
  POLICY_TREAT_REQUEST,
  POLICY_TREAT_SUCCESS,
  POLICY_TREAT_FAIL,
  POLICY_DEACTIVATE_REQUEST,
  POLICY_DEACTIVATE_SUCCESS,
  POLICY_DEACTIVATE_FAIL,
  POLICY_AGG_REQUEST,
  POLICY_AGG_SUCCESS,
  POLICY_AGG_FAIL,
  CUSTOMER_CHECK,
  POLICY_HISTORY_REQUEST,
  POLICY_HISTORY_SUCCESS,
  POLICY_HISTORY_FAIL,
  POLICY_CANCEL_REQUEST,
  POLICY_CANCEL_SUCCESS,
  POLICY_CANCEL_FAIL,
  POLICY_DETAILS_REQUEST,
  POLICY_DETAILS_SUCCESS,
  POLICY_DETAILS_FAIL,
  POLICY_CHECK_GROUP,
} from '../../../types/actionTypes'
import { policyHistoryType } from '../../../types/policyHistoryTypes'

const policyUrl = urlPolicy()
const customerUrl = urlCustomer()
const treatUrl = urlTreatment()

export const selectPolicy = policyId => {
  return {
    type: POLICY_SELECT,
    payload: policyId,
  }
}

export const checkPolicy = policyId => {
  return {
    type: POLICY_CHECK,
    payload: policyId,
  }
}

export const checkPolicyGroup = policyIds => {
  return {
    type: POLICY_CHECK_GROUP,
    payload: policyIds,
  }
}

export const checkCustomer = customer => {
  return {
    type: CUSTOMER_CHECK,
    payload: customer,
  }
}

export const setPoliciesCategory =
  (policiesIds, categoryId, updateAllRelatedPolicies) => async (dispatch, getState) => {
    dispatch({ type: POLICY_SET_CATEGORY_REQUEST })
    try {
      if (!Array.isArray(policiesIds)) {
        policiesIds = [policiesIds]
      }
      const {
        data: {
          data: { updatedPolicies },
        },
      } = await $api.patch(`${policyUrl}category`, { policiesIds, categoryId, updateAllRelatedPolicies })
      const category = getState().shared.categories.find(c => c.id === categoryId)
      dispatch({
        type: POLICY_SET_CATEGORY_SUCCESS,
        payload: { success: Array.isArray(updatedPolicies), updatedPolicies, category },
      })
    } catch (error) {
      dispatch({
        type: POLICY_SET_CATEGORY_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const getPolicyAgg = policyId => async dispatch => {
  dispatch({ type: POLICY_AGG_REQUEST })
  try {
    const {
      data: { Data },
    } = await $api.get(`${policyUrl}month-agg/${policyId}`)
    dispatch({
      type: POLICY_AGG_SUCCESS,
      payload: Data,
    })
  } catch (error) {
    dispatch({
      type: POLICY_AGG_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPolicyIrregularities = policyId => async dispatch => {
  dispatch({ type: POLICY_IRREG_REQUEST })
  try {
    const {
      data: { data: irregularities },
    } = await $api.get(`${customerUrl}get-irregularities/${policyId}`)
    dispatch({
      type: POLICY_IRREG_SUCCESS,
      payload: irregularities,
    })
  } catch (error) {
    dispatch({
      type: POLICY_IRREG_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPolicyHistory =
  (policyId, silentUpdate = false) =>
  async dispatch => {
    dispatch({ type: POLICY_HISTORY_REQUEST, payload: !silentUpdate })
    try {
      const {
        data: {
          data: { result: history },
        },
      } = await $api.get(`${policyUrl}history/${policyId}`)
      dispatch({
        type: POLICY_HISTORY_SUCCESS,
        payload: history,
      })
    } catch (error) {
      dispatch({
        type: POLICY_HISTORY_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const cancelPolicyLog = (logId, policyId, cancelActionType) => async dispatch => {
  dispatch({ type: POLICY_CANCEL_REQUEST, payload: logId })
  try {
    const {
      data: {
        data: { hasErrors },
      },
    } = await $api.get(`${policyUrl}cancel-action/${logId}`)
    if (!hasErrors) {
      dispatch({
        type: POLICY_CANCEL_SUCCESS,
        payload: { hasErrors, logId, policyId, cancelActionType },
      })
      dispatch(getPolicyHistory(policyId, true))
      if (
        cancelActionType === policyHistoryType.irregularityFixed ||
        cancelActionType === policyHistoryType.irregularityIgnored
      ) {
        dispatch(getPolicyIrregularities(policyId))
      }
    } else {
      throw new Error('Cancelling error')
    }
  } catch (error) {
    dispatch({
      type: POLICY_CANCEL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const sendForTreat =
  ({ customerId, agentId, policyId, irregularityId, note, type }) =>
  async (dispatch, getState) => {
    dispatch({ type: POLICY_TREAT_REQUEST })
    try {
      const treatDto = {
        agentId: agentId || null,
        policyId: policyId || null,
        irregularityId: irregularityId || null,
        customerId: customerId || null,
        note: note?.toString() || null,
        type: type || null,
      }
      const {
        data: {
          data: { success },
        },
      } = await $api.put(`${treatUrl}add`, treatDto)
      const agentName = getState().shared.agents.find(a => a.id === agentId).name
      dispatch({
        type: POLICY_TREAT_SUCCESS,
        payload: { success, irregularityId, agentName, agentId },
      })
    } catch (error) {
      dispatch({
        type: POLICY_TREAT_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const sendForTreatBatch = treatDtos => async dispatch => {
  dispatch({ type: POLICY_TREAT_REQUEST })
  treatDtos = treatDtos.map(dto => {
    return {
      agentId: dto?.agentId || null,
      policyId: dto?.policyId || null,
      irregularityId: dto?.irregularityId || null,
      customerId: dto?.customerId || null,
      note: dto?.note?.toString() || null,
      taskId: dto?.taskId || null,
      type: dto?.type || null,
    }
  })
  try {
    const {
      data: {
        data: { success },
      },
    } = await $api.put(`${treatUrl}add-batch`, treatDtos)
    dispatch({
      type: POLICY_TREAT_SUCCESS,
      payload: { success },
    })
  } catch (error) {
    dispatch({
      type: POLICY_TREAT_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const fixIrregularity =
  ({ irregularityId, month, note, policyId, irregsCount }) =>
  async dispatch => {
    dispatch({ type: POLICY_FIX_REQUEST })
    try {
      const {
        data: {
          data: { success },
        },
      } = await $api.post(`${treatUrl}schedule-irregularity-treatment`, { irregularityId, note })
      dispatch({
        type: POLICY_FIX_SUCCESS,
        payload: { success, irregularityId, policyId, irregsCount },
      })
    } catch (error) {
      dispatch({
        type: POLICY_FIX_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const ignoreIrregularity =
  ({ irregularityId, policyId, irregsCount }) =>
  async dispatch => {
    dispatch({ type: POLICY_IGNORE_REQUEST })
    try {
      const {
        data: {
          data: { success },
        },
      } = await $api.get(`${policyUrl}ignore-irregularity/${irregularityId}`)
      dispatch({
        type: POLICY_IGNORE_SUCCESS,
        payload: { success, irregularityId, policyId, irregsCount },
      })
    } catch (error) {
      dispatch({
        type: POLICY_IGNORE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const deactivatePolicy = policyId => async dispatch => {
  dispatch({ type: POLICY_DEACTIVATE_REQUEST })
  try {
    const {
      data: {
        data: { success },
      },
    } = await $api.post(`${policyUrl}deactivate/${policyId}`)
    dispatch({
      type: POLICY_DEACTIVATE_SUCCESS,
      payload: { success, policyId },
    })
  } catch (error) {
    dispatch({
      type: POLICY_DEACTIVATE_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPolicyDetails = policyId => async dispatch => {
  dispatch({ type: POLICY_DETAILS_REQUEST })
  try {
    const {
      data: { data },
    } = await $api.get(`${policyUrl}info/${policyId}`)
    dispatch({
      type: POLICY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: POLICY_DETAILS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
