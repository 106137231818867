import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { CUSTOMERS_FILTER_BY_ADVANCED, POLICIES_DATES_FILTER_APPLY } from '../../../../types/actionTypes'
import { hebrew, rtlDir } from '../../../../i18n'
import { getMonthName, getMonthNameByNumber } from '../../../../services/getMonthName'
import './PoliciesFilterDates.scss'
import { formatAsShortDate } from '../../../../utils/formatAs'
import { printDatesInRange } from '../../../../utils/date'

const PoliciesFilterDates = () => {
  const { datesFilter } = useSelector(({ policies }) => policies)
  const [showFilters, setShowFilters] = useState(false)
  const [filtersDirty, setFiltersDirty] = useState(false)
  const [dates, setDates] = useState([])
  const [years, setYears] = useState([])
  const [yearIndex, setYearIndex] = useState(0)
  const [selectedDates, setSelectedDates] = useState([])
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('policies')
  const btnRef = useRef()

  const setYearsIndexHandler = index => {
    index > -1 && index < years.length && setYearIndex(index)
  }

  const appliedFiltersLength = datesFilter.filter(d => d.selected).length

  const selectDate = date => {
    setFiltersDirty(true)
    date = date.toString()
    setSelectedDates(prev => (selectedDates.includes(date) ? prev.filter(d => d !== date) : [...prev, date]))
  }

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  const applyFilters = () => {
    filtersDirty &&
      dispatch({
        type: POLICIES_DATES_FILTER_APPLY,
        payload: selectedDates,
      })
    setFiltersDirty(false)
    setShowFilters(false)
  }

  useEffect(() => {
    if (!showFilters || datesFilter.length === 0) {
      return
    }
    setDates(datesFilter)
    const initYears = []
    datesFilter.forEach(({ date }) => {
      const year = +date.slice(0, 4)
      !initYears.includes(year) && initYears.push(year)
    })
    setYears(initYears)
    setYearIndex(initYears.length - 1)
    setSelectedDates(datesFilter.filter(d => d.selected).map(({ date }) => date))
  }, [datesFilter, showFilters])

  return (
    <div className='policies-filter-dates-container'>
      <div className='policies-filter-dates-btn-container' ref={btnRef}>
        <button
          className={classNames('policies-filter-dates-btn', {
            open: showFilters,
            'has-applied': appliedFiltersLength > 0,
          })}
          onClick={() => setShowFilters(!showFilters)}
        >
          <div className='policies-filter-dates-btn-icon'>
            <img src='./assets/calendar-icon/Calendar.png' alt='dts' />
          </div>
          {appliedFiltersLength > 0 && <div className='policies-filter-dates-btn-count'>{appliedFiltersLength}</div>}
        </button>
      </div>
      {showFilters && (
        <div className='policies-filter-dates-overlay'>
          <div
            className='policies-filter-dates-main-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().left + 'px' }
                : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
            }}
          >
            <header>
              <p>{t('datesFilter.title')}</p>
              <div>
                <button onClick={() => setShowFilters(false)}>
                  <img src='./assets/close-icon-square/close-square.png' alt='cls' />
                </button>
              </div>
            </header>
            <main>
              <section className='policies-filter-dates-controls'>
                <div>
                  <button
                    style={{
                      visibility: yearIndex > 0 ? 'visible' : 'hidden',
                    }}
                    onClick={() => setYearsIndexHandler(yearIndex - 1)}
                  >
                    <img src='./assets/chevron-right/chevron-right.png' alt='prev' />
                  </button>
                </div>
                <h3>{years[yearIndex]}</h3>
                <div>
                  <button
                    style={{
                      visibility: yearIndex < years.length - 1 ? 'visible' : 'hidden',
                    }}
                    onClick={() => setYearsIndexHandler(yearIndex + 1)}
                  >
                    <img src='./assets/chevron-left/chevron-left.png' alt='next' />
                  </button>
                </div>
              </section>
              <p className='policies-filter-dates-selected'>{printDatesInRange(selectedDates, i18n.language)}</p>
              <section className='policies-filter-dates'>
                {dates
                  .filter(d => d.date.includes(years[yearIndex].toString()))
                  .map((d, i) => (
                    <div
                      key={d.date}
                      className={classNames('policies-filter-dates-month', {
                        selected: selectedDates.includes(d.date.toString()),
                        'no-data': !d.hasValue,
                      })}
                      onClick={() => selectDate(d.date)}
                    >
                      {getMonthName(d.date, i18n.language)}
                    </div>
                  ))}
              </section>
              <p className='policies-filter-dates-remark-error'>
                {dates.filter(d => !d.hasValue).some(({ date }) => selectedDates.includes(date)) &&
                  t('datesFilter.remarkError')}
              </p>
            </main>
            <footer>
              <div>
                <button
                  onClick={applyFilters} //disabled={selectedDates.length === 0}
                >
                  {t('datesFilter.applyBtn')}
                </button>
              </div>
            </footer>
          </div>
        </div>
      )}
    </div>
  )
}

export default PoliciesFilterDates
