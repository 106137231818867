import { useTranslation } from 'react-i18next'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hebrew } from '../../../../i18n'
import { formatAsCurrency } from '../../../../utils/formatAs'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import PrivateValue from '../../../../HOCs/PrivateValue'
import { userPreferenceType } from '../../../../types/agencyUserTypes'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import ConfirmDialog from '../../../../components/ConfirmDialog/ConfirmDialog'
import Loader from '../../../../components/Common/Loader/Loader'
import { deleteCustomersGroup, getCustomersGroups } from '../../actions/customersActions'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import { getAgents } from '../../../shared/actions/sharedActions'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import CustomerAllCategoriesModal from '../../../customer/components/CustomerAllCategoriesModal/CustomerAllCategoriesModal'
import CustomersGroupDetailsSidbar from '../CustomersGroupDetailsSidbar/CustomersGroupDetailsSidbar'
import { CUSTOMERS_COLLAPSE_CUSTOMERS_GROUP } from '../../../../types/actionTypes'
import './CustomersGroupsList.scss'
import { customerGroupType } from '../../../../types/customerGroupTypes'
import { useHistory } from 'react-router-dom'
import { agencyRoutes } from '../../../../environment/urls'
import { SHARED_SET_FAMILY_GROUPS_TYPE } from '../../../../types/actionTypesShared'
import { familyGroupType } from '../../../../types/familyGroupTypes'
import { delay } from '../../../../services/delay'
import { exportPoliciesByRankByGroupRequest } from '../../../../http/requests/policiesRequests'

const CustomersGroupsList = ({ groups, selectGroups, selectedGroups }) => {
  const { t } = useTranslation('customers')
  const { t: tCustomer } = useTranslation('customer')
  const dispatch = useDispatch()
  const history = useHistory()
  const { groupTypesFilter } = useSelector(({ customers }) => customers)
  const [showEditGroupModal, setShowEditGroupModal] = useState(false)
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false)
  const [showAddTreatUserModal, setShowAddTreatUserModal] = useState(false)
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)
  const [showGroupDetails, setShowGroupDetails] = useState(false)
  const { agents, agentsLoading } = useSelector(({ shared }) => shared)
  const [treatAgent, setTreatAgent] = useState(null)
  const [showCategoriesModal, setShowCategoriesModal] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [loadingGroup, setLoadingGroup] = useState({})
  const currGroupType = groupTypesFilter.find(t => t.id === +groups.groupName)
  const groupTypeName = currGroupType && (hebrew() ? currGroupType?.name : currGroupType?.englishName)

  const exportCustomersGroup = async id => {
    try {
      setLoadingGroup({ ...loadingGroup, [id]: true })
      await exportPoliciesByRankByGroupRequest({ groupsFilter: [id] })
      setLoadingGroup({ ...loadingGroup, [id]: false })
    } catch (error) {
      setLoadingGroup({ ...loadingGroup, [id]: false })
    }
  }

  const menuItemContent = (iconPath, text) => (
    <div className='customers-group-menu-item'>
      <div>{text}</div>
      <div>
        <img src={iconPath} alt={text} />
      </div>
    </div>
  )
  const buildMenuItems = group => [
    {
      id: 1,
      text: menuItemContent('./assets/customer-groups-actions/edit/edit.png', t('customersGroupMenu.edit')),
      clickHandler: () => {
        setSelectedGroup(group)
        setShowEditGroupModal(true)
      },
    },
    {
      id: 2,
      text: menuItemContent('./assets/customer-groups-actions/delete/delete.png', t('customersGroupMenu.delete')),
      clickHandler: () => {
        setSelectedGroup(group)
        setShowDeleteGroupModal(true)
      },
    },
    {
      id: 3,
      text: menuItemContent('./assets/customer-groups-actions/export/export.png', t('customersGroupMenu.export')),
      clickHandler: () => exportCustomersGroup(group.id),
    },
    {
      id: 4,
      text: menuItemContent('./assets/customer-groups-actions/treat/treat.png', t('customersGroupMenu.treat')),
      clickHandler: () => {
        setSelectedGroup(group)
        setShowAddTreatUserModal(true)
      },
      disabled: true,
      blured: true,
    },
    {
      id: 5,
      text: menuItemContent(
        './assets/customer-groups-actions/examine-products/examine-products.png',
        t('customersGroupMenu.examineProducts')
      ),
      clickHandler: () => {
        setShowCategoriesModal(true)
      },
      disabled: true,
      blured: true,
    },
  ]

  const deleteGroupHandler = () => dispatch(deleteCustomersGroup(selectedGroup.id))

  const setTreatAgentHandler = agent => {
    setTreatAgent(agent)
    setShowAddTreatUserModal(false)
    setShowTreatNoteModal(true)
  }

  const sendForTreatHandler = note => {
    const agentId = treatAgent.id
    const treatDtos = [
      {
        agentId: agentId,
        type: 'customerTaskType.customerGroup',
        note,
      },
    ]
    console.log('send for treat', treatDtos)
  }

  const openDetailsHandler = group => {
    const familyGroup = group.type === customerGroupType.family
    if (familyGroup) {
      const mainMemberId = group.customers.find(c => c.isMainFamilyGroupMember)?.id
      if (mainMemberId) {
        dispatch({ type: SHARED_SET_FAMILY_GROUPS_TYPE, payload: familyGroupType.group })
        history.push(`/${agencyRoutes.customers}/${mainMemberId}`)
      }
    } else {
      setSelectedGroup(group)
      setShowGroupDetails(true)
    }
  }

  const closeDetailsHandler = () => {
    setShowGroupDetails(false)
    setSelectedGroup(null)
  }

  const collapseGroup = groupName => {
    dispatch({ type: CUSTOMERS_COLLAPSE_CUSTOMERS_GROUP, payload: groupName })
  }

  useEffect(() => {
    if (showAddTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showAddTreatUserModal, dispatch])

  return (
    <div className='customers-group-type-container'>
      <div className='customers-group-type-name'>
        <LinedTitle
          title={groupTypeName}
          count={groups.itemsInGroup}
          collapsible
          onCollapse={() => collapseGroup(groups.groupName)}
          collapsed={groups.isColapsed}
          uncollapsedIconPath='./assets/dropdown-icon-light/dropdown-icon-light.png'
          collapsedIconPath='./assets/dropdown-icon-light-left/Left.png'
        />
      </div>
      {!groups.isColapsed && groups.groupItems.length > 0 && (
        <div className='customers-group-items-list'>
          {groups.groupItems.map(g => (
            <div
              className='customers-group-item customers-groups-list-grid'
              key={g.id}
              onClick={() => openDetailsHandler(g)}
            >
              <div className='customers-group-item-selector-container'>
                <button
                  onClick={e => {
                    e.stopPropagation()
                    selectGroups([g.id])
                  }}
                >
                  <img
                    src={`./assets/${
                      selectedGroups.includes(g.id)
                        ? 'checkbox-xs-checked/Check Box .png'
                        : 'check-box-blank/Check Box Blank.png'
                    }`}
                    alt='slct'
                  />
                </button>
              </div>
              <div>{g.name ?? t('customersGroupNamePlaceholder', { groupId: g.id })}</div>
              <div
                style={{
                  direction: 'ltr',
                  ...(hebrew() && { textAlign: 'end' }),
                }}
              >
                <PrivateValue requiredPrefernces={[userPreferenceType.premium]}>
                  {formatAsCurrency(g.premium)}
                </PrivateValue>
              </div>
              <div
                style={{
                  direction: 'ltr',
                  ...(hebrew() && { textAlign: 'end' }),
                }}
              >
                <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
                  {formatAsCurrency(g.commission)}
                </PrivateValue>
              </div>
              <div
                style={{
                  direction: 'ltr',
                  ...(hebrew() && { textAlign: 'end' }),
                }}
              >
                <PrivateValue requiredPrefernces={[userPreferenceType.accumulation]}>
                  {formatAsCurrency(g.accumulation)}
                </PrivateValue>
              </div>
              <div className='customers-group-item-menu-container'>
                <MeatBallsMenu menuItems={buildMenuItems(g)} positionFixed />
              </div>
              {(g.loading || loadingGroup[g.id]) && (
                <div className='customers-group-item-loading-overlay' onClick={e => e.stopPropagation()}>
                  <Loader />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {showEditGroupModal && (
        <AddCustomerGroupModal
          group={selectedGroup}
          closeModal={() => {
            setSelectedGroup(null)
            setShowEditGroupModal(false)
          }}
          onEditSuccess={() => dispatch(getCustomersGroups())}
        />
      )}
      {showDeleteGroupModal && (
        <ConfirmDialog
          confirm={deleteGroupHandler}
          decline={() => {
            setSelectedGroup(null)
            setShowDeleteGroupModal(false)
          }}
          title={t('deleteCustomersGroupModal.modalTitle')}
          text={t('deleteCustomersGroupModal.modalText', { groupName: selectedGroup.name })}
          confirmText={t('deleteCustomersGroupModal.removeBtn')}
          declineText={t('deleteCustomersGroupModal.cancelBtn')}
        />
      )}
      {showAddTreatUserModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => setShowAddTreatUserModal(false)}
          closeOnOutsideClick={false}
          onAddUser={setTreatAgentHandler}
          currentUser={treatAgent}
          askConfirm={false}
        />
      )}
      {showTreatNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatNoteModal(false)}
          closeOnOutsideClick={false}
          title={tCustomer('treatNoteModal.modalTitle')}
          subtitle={tCustomer('treatNoteModal.modalSubtitle')}
          label={tCustomer('treatNoteModal.inputLabel')}
          btnCaption={tCustomer('treatNoteModal.btnTitle')}
          back={() => setShowAddTreatUserModal(true)}
          onAddNote={sendForTreatHandler}
        />
      )}
      {showCategoriesModal && <CustomerAllCategoriesModal closeModal={() => setShowCategoriesModal(false)} />}
      {showGroupDetails && (
        <CustomersGroupDetailsSidbar
          closeSidebar={closeDetailsHandler}
          groupId={selectedGroup.id}
          groupTypes={groupTypesFilter}
          onEditSuccess={() => dispatch(getCustomersGroups(selectedGroup.id))}
        />
      )}
    </div>
  )
}

export default CustomersGroupsList
