import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { profileEditCompany } from '../../actions/profileActions'
import i18n from '../../../../i18n'
import { getCompanyAuthFields } from '../../../../utils/getAuthFields'
import InputRadio from '../../../../components/Common/InputRadio/InputRadio'
import { companyAuth, companyAuthType } from '../../../../types/companyAuthType'
import MultipleUsersAlert from '../../../../components/MultipleUsersAlert/MultipleUsersAlert'
import './InputCredentials.css'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'

const InputCredentials = ({ company }) => {
  const { editCompanyLoading } = useSelector(state => state.profile)
  const canHaveMultipleUsers = company.fields.length > 1
  const [twoUsers, setTwoUsers] = useState(company.hasElementaryUser)
  const authFields = getCompanyAuthFields(company)
  const authTypesValues = [
    {
      ...companyAuth[companyAuthType.elementary],
      text:
        i18n.language === 'he'
          ? companyAuth[companyAuthType.elementary].name
          : companyAuth[companyAuthType.elementary].nameEn,
      value: companyAuthType.elementary,
    },
    {
      ...companyAuth[companyAuthType.main],
      text: i18n.language === 'he' ? companyAuth[companyAuthType.main].name : companyAuth[companyAuthType.main].nameEn,
      value: companyAuthType.main,
    },
  ]
  const [selectedAuthType, setSelectedAuthType] = useState(authTypesValues[0])

  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: { ...authFields.map(field => field.fieldType) },
  })
  const [showPassword, setShowPassword] = useState(false)
  const { t } = useTranslation('profile')

  const watchAllFields = watch()
  const dispatch = useDispatch()
  const submitHandler = data => {
    let authFields = Object.keys(data).map(key => {
      return {
        Name: key,
        Value: data[key],
      }
    })
    const updatedCompany = {
      hasTwoUsers: twoUsers,
      CompanyId: company.id,
      InsuranceFields: company.fields.map(f => f.id),
      AuthType: twoUsers ? selectedAuthType.value : companyAuthType.main,
      AuthFields: authFields,
    }
    dispatch(profileEditCompany(updatedCompany))
  }

  const hasEmptyFields = () => {
    return Object.values(watchAllFields).some(field => field === '') || Object.keys(watchAllFields).length === 0
  }

  return (
    <>
      {canHaveMultipleUsers && <MultipleUsersAlert company={company} twoUsers={twoUsers} setTwoUsers={setTwoUsers} />}
      <form action='' className='form-container' autoComplete='off' onSubmit={handleSubmit(submitHandler)}>
        <div className='profile-company-credentials'>
          {authFields.map((field, i) => {
            return (
              <div className='input-container' key={company.id + field.fieldType}>
                <input
                  type={field?.fieldType.includes('ass') && !showPassword[field.fieldType] ? 'password' : 'text'}
                  name={field.fieldType}
                  id={company.id + field.fieldType}
                  placeholder=' '
                  ref={register({ required: 'required' })}
                  autoComplete={field?.fieldType.includes('ass') ? 'one-time-code' : 'off'}
                />
                {field.fieldType.includes('ass') && (
                  <div
                    className='password-icon'
                    onMouseDownCapture={() =>
                      setShowPassword(prev => {
                        return { ...prev, [field.fieldType]: true }
                      })
                    }
                    onMouseUp={() =>
                      setShowPassword(prev => {
                        return { ...prev, [field.fieldType]: false }
                      })
                    }
                  >
                    {showPassword[field.fieldType] ? (
                      <img src='./assets/eye/Hide.png' alt='hide' />
                    ) : (
                      <img src='./assets/eye/Show.png' alt='show' />
                    )}
                  </div>
                )}
                <label htmlFor={company.id + field.fieldType}>
                  {i18n.language === 'he' ? field.name : field.nameEn}
                </label>
                <span className='line'></span>
                {errors[field.fieldType] && <span className='error-small'>{errors[field.fieldType].message}</span>}
              </div>
            )
          })}
        </div>

        {twoUsers && (
          <div className='profile-sidebar-auth'>
            <div className='profile-sidebar-auth-title'>choose auth type</div>
            <InputRadio
              values={authTypesValues}
              selectedValue={selectedAuthType.value}
              handleChange={e =>
                setSelectedAuthType(authTypesValues.find(at => at.value.toString() === e.target.value))
              }
            />
          </div>
        )}

        <div className='form-footer'>
          <ButtonRounded
            typeSubmit
            className=''
            disabled={hasEmptyFields() || Object.keys(errors).length !== 0 || editCompanyLoading}
            onClick={handleSubmit(submitHandler)}
          >
            {t('editCompanyModal.modalButton.save')}
          </ButtonRounded>
        </div>
      </form>
    </>
  )
}

export default InputCredentials
