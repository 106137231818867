import { useTranslation } from 'react-i18next'
import { getIncomesTotalsTypes, incomesTotalsObject, incomesTotalsType } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { hebrew } from '../../../../i18n'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import Loader from '../../../../components/Common/Loader/Loader'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AgencyHouseAnalyticsIncomesNoData from '../AgencyHouseAnalyticsIncomesNoData/AgencyHouseAnalyticsIncomesNoData'
import { cn } from '../../../../utils/stylesUtils'
import AnalyticsChangesValue from '../AnalyticsChangesValue/AnalyticsChangesValue'
import { getKeyByValue } from '../../../../utils/objectUtils'
import './AgencyHouseAnalyticsIncomesChangesTable.scss'

const AgencyHouseAnalyticsIncomesChangesTable = ({
  dataType,
  listType,
  periodViewType,
  list,
  listLoading,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly

  const tableHeader = (
    <header
      className={cn('agency-house-analytics-incomes-changes-table-grid', {
        extended: dataType === requestDataTypes.accumulation,
      })}
    >
      <div></div>
      {getIncomesTotalsTypes(dataType, periodViewType).map(dt => {
        const { name, nameEn, nameMonthlyView, nameEnMonthlyView, type } = incomesTotalsObject[dt]
        const text = monthlyView ? (hebrew() ? nameMonthlyView : nameEnMonthlyView) : hebrew() ? name : nameEn
        return (
          <div key={type}>
            <div>{text}</div>
            <div>{t('tableHeading.prevMonthDiff')}</div>
            <div></div>
          </div>
        )
      })}
    </header>
  )

  if (!list) {
    return null
  }
  if (listLoading) {
    return <Loader />
  }
  if (list.length === 0) {
    return <AgencyHouseAnalyticsIncomesNoData />
  }
  return (
    <div className='agency-house-analytics-incomes-changes-table-container'>
      {tableHeader}
      <div className='agency-house-analytics-incomes-changes-table'>
        {list.map(itm => (
          <AgencyHouseAnalyticsIncomesChangesTableItem
            key={itm.key}
            item={itm}
            dataType={dataType}
            periodViewType={periodViewType}
            listType={listType}
            selectItem={selectItem}
            selectedItem={selectedItem}
          />
        ))}
      </div>
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesChangesTable

const AgencyHouseAnalyticsIncomesChangesTableItem = ({
  item,
  dataType,
  periodViewType,
  listType,
  selectItem,
  selectedItem,
}) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const dataKeys = getIncomesTotalsTypes(dataType, periodViewType).map(
    tt => Object.entries(incomesTotalsType).find(([key, val]) => val === tt)[0]
  )
  const itemName = hebrew() ? item.name : item.nameEn

  return (
    <div
      className={cn(
        'agency-house-analytics-incomes-changes-table-item agency-house-analytics-incomes-changes-table-grid',
        { extended: dataType === requestDataTypes.accumulation }
      )}
    >
      <div>{itemName}</div>
      {dataKeys.map(k => (
        <div key={k}>
          <div>
            <span>
              {dataType === requestDataTypes.commissionRate
                ? formatAsPercent(item[k], {
                    ...(k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) && {
                      maximumFractionDigits: 4,
                    }),
                  })
                : formatAsCurrency(item[k])}
            </span>
            {dataType === requestDataTypes.commission &&
              k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionMonthly) && (
                <span className='commission-vat'>
                  ({t('tableHeading.inclVat')}:{' '}
                  {formatAsCurrency(item[getKeyByValue(incomesTotalsType, incomesTotalsType.commissionVAT)])})
                </span>
              )}
          </div>
          <div>
            <AnalyticsChangesValue
              value={item[`${k}Diff`]}
              currency={dataType !== requestDataTypes.commissionRate}
              percent={dataType === requestDataTypes.commissionRate}
              {...(k === getKeyByValue(incomesTotalsType, incomesTotalsType.commissionRateAccumulation) && {
                maximumFractionDigits: 4,
              })}
            />
          </div>
          <div>
            <button onClick={() => selectItem({ key: item.key, type: listType, diffKey: k, ...item })}>
              <img src='./assets/export/Export.png' alt='dtls' />
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}
