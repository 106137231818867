import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { irregularStatus } from '../../../types/irregularStatusTypes'
import { policyStatus, policyStatusType } from '../../../types/policyStatusType'
import './PolicyStatusMarker.scss'

const PolicyStatusMarker = ({ active, irregular, lostData, size = 'sm', showActiveStatus = true }) => {
  const { i18n } = useTranslation()
  const status = !active
    ? policyStatusType.inactive
    : lostData
    ? policyStatusType.lostData
    : irregular === irregularStatus.irregular
    ? policyStatusType.irregular
    : showActiveStatus
    ? policyStatusType.active
    : null
  const { backgroundColor, color } = policyStatus[status ?? policyStatusType.normal]
  return (
    <>
      {status && (
        <div className='policy-status-marker-container'>
          <div className={classNames('policy-status-marker', { [size]: size })} style={{ backgroundColor, color }}>
            {i18n.language === 'he' ? policyStatus[status].text : policyStatus[status].textEn}
          </div>
        </div>
      )}
    </>
  )
}

export default PolicyStatusMarker
