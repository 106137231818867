import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Loader from '../../../../components/Common/Loader/Loader'
import NoData from '../../../../components/Common/NoData/NoData'
import PaginateIrreg from '../../../../components/Common/PaginateIrreg/PaginateIrreg'
import PoliciesListItem from './PoliciesListItem'
import PoliciesActions from '../../../policy/components/PoliciesActions/PoliciesActions'
import { getIrregularReasonsFlags } from '../../../../services/getIrregularReasons'
import { irregularReasonObject } from '../../../../types/irregularReasonTypes'
import { sortBy, sortOrder } from '../../../../types/sortByTypes'
import { useDispatch, useSelector } from 'react-redux'
import { checkPolicy, checkPolicyGroup, selectPolicy } from '../../../policy/actions/policyActions'
import { policiesListType } from '../../../../types/tablesTypes'
import { POLICY_UNCHECK_ALL } from '../../../../types/actionTypes'
import classNames from 'classnames'
import './PoliciesListContainer.scss'
import { irregularSignal } from '../../../../types/irregularSignals'

const PoliciesListContainer = ({
  page,
  setPage,
  currentListType,
  loading,
  items = [],
  sort,
  setSortHandler,
  irregularFilter,
  signalsFilter,
}) => {
  const [pageSize, setPageSize] = useState(50)
  const itemsLength =
    currentListType === policiesListType.irregular || currentListType === policiesListType.signals ? 0 : items.length
  const pagesQty =
    currentListType === policiesListType.irregular || currentListType === policiesListType.signals
      ? 0
      : Math.ceil(items.length / pageSize)
  const [irregGroups, setIrregGroups] = useState([])
  const [irregFrame, setIrregFrame] = useState([])
  const [signalGroups, setSignalGroups] = useState([])
  const [signalFrame, setSignalFrame] = useState([])
  const [pages, setPages] = useState(pagesQty)
  const [totalItems, setTotalItems] = useState(itemsLength)

  const listRef = useRef()

  const selectedPolicy = useSelector(state => state.policy).selectedPolicy
  const checkedPolicies = useSelector(state => state.policy).checkedPolicies

  const allCheckedPolicies = items.filter(policy => checkedPolicies.includes(policy.id))
  const dispatch = useDispatch()

  const checkPolicyHandler = policyId => {
    dispatch(checkPolicy(policyId))
  }

  const checkPolicyGroupHandler = groupId => {
    const policyIds = getGroupItemsIds(groupId)
    dispatch(checkPolicyGroup(policyIds))
  }

  const checkPolicySignalGroupHandler = groupId => {
    const policyIds = getSignalGroupItemsIds(groupId)
    dispatch(checkPolicyGroup(policyIds))
  }

  const getGroupItemsIds = groupId => (irregGroups[groupId] ? irregGroups[groupId].items.map(i => i.id) : [])
  const getSignalGroupItemsIds = groupId => (signalGroups[groupId] ? signalGroups[groupId].items.map(i => i.id) : [])

  const selectPolicyHandler = policyId => {
    dispatch(selectPolicy(policyId))
  }

  const forwardHandler = () => {
    if (page <= pageSize) {
      setPage(prev => prev + 1)
    }
  }
  const backwardHandler = () => {
    if (page > 1) {
      setPage(prev => prev - 1)
    }
  }

  const collapseGroup = key => {
    setIrregGroups(prev => {
      return { ...prev, [key]: { ...prev[key], show: !prev[key].show } }
    })
  }

  const collapseSignalGroup = key => {
    setSignalGroups(prev => {
      return { ...prev, [key]: { ...prev[key], show: !prev[key].show } }
    })
  }

  const { t, i18n } = useTranslation('policies')

  useEffect(() => {
    setPage(1)
  }, [currentListType])

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0
    }
  }, [page, currentListType])

  useEffect(() => {
    if (currentListType === policiesListType.irregular) {
      const groups = buildIrregGroups(items, irregularFilter)
      const updatedGroups = Object.entries(groups).reduce((acc, [k, v]) => {
        acc[k] = {
          ...irregGroups[k],
          items: v.items,
          show: irregGroups[k]?.show ?? false,
        }
        return acc
      }, {})
      setIrregGroups({ ...updatedGroups })
    } else if (currentListType === policiesListType.signals) {
      const groups = buildSignalGroups(items, signalsFilter)
      const updatedGroups = Object.entries(groups).reduce((acc, [k, v]) => {
        acc[k] = {
          ...signalGroups[k],
          items: v.items,
          show: signalGroups[k]?.show ?? false,
        }
        return acc
      }, {})
      setSignalGroups({ ...updatedGroups })
    } else {
      setTotalItems(items.length)
      setPages(Math.ceil(items.length / pageSize))
    }
  }, [items, currentListType, pageSize, irregularFilter, signalsFilter])

  useEffect(() => {
    if (currentListType === policiesListType.irregular) {
      setIrregFrame(buildIrregFrame(irregGroups, page, pageSize))
    } else if (currentListType === policiesListType.signals) {
      setSignalFrame(buildSignalFrame(signalGroups, page, pageSize))
    }
  }, [irregGroups, signalGroups, page])

  useEffect(() => {
    if (currentListType === policiesListType.irregular) {
      let items = 0
      Object.entries(irregGroups).forEach(([key, value]) => {
        if (value.show) {
          items += value.items.length
        }
      })
      setTotalItems(items)
      let currentPages = pages
      let updatedPages = Math.ceil(items / pageSize)
      let currentPage = page
      if (updatedPages === 0) {
        setPages(1)
        setPage(1)
      } else {
        setPages(updatedPages)
        if (currentPages > updatedPages) {
          if (currentPage - 1 !== 0) {
            setPage(1)
          } else {
            setPage(currentPage)
          }
        }
      }
    } else if (currentListType === policiesListType.signals) {
      let items = 0
      Object.entries(signalGroups).forEach(([key, value]) => {
        if (value.show) {
          items += value.items.length
        }
      })
      setTotalItems(items)
      let currentPages = pages
      let updatedPages = Math.ceil(items / pageSize)
      let currentPage = page
      if (updatedPages === 0) {
        setPages(1)
        setPage(1)
      } else {
        setPages(updatedPages)
        if (currentPages > updatedPages) {
          if (currentPage - 1 !== 0) {
            setPage(1)
          } else {
            setPage(currentPage)
          }
        }
      }
    }
  }, [irregGroups, signalGroups, pageSize, page, pages])

  return (
    <div className='policies-list-container'>
      {loading ? (
        <Loader />
      ) : items.length > 0 ? (
        <>
          <div className='policies-list-heading'>
            <div></div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.policyNumber)}>
              {t('list.heading.policyNumber')}
              {sort.by === sortBy.policyNumber && (
                <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.customerName)}>
              {t('list.heading.customerName')}
              {sort.by === sortBy.customerName && (
                <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.commission)}>
              {t('list.heading.commission')}
              {sort.by === sortBy.commission && (
                <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.premium)}>
              {t('list.heading.premium')}
              {sort.by === sortBy.premium && (
                <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.accumulation)}>
              {t('list.heading.accumulation')}
              {sort.by === sortBy.accumulation && (
                <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.commissionRatePremium)}>
              {t('list.heading.commissionRatePremium')}
              {sort.by === sortBy.commissionRatePremium && (
                <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.commissionRateAccumulation)}>
              {t('list.heading.commissionRateAccumulation')}
              {sort.by === sortBy.commissionRateAccumulation && (
                <i className={`fas fa-sort-numeric-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            {/* <div className='commission-rate sortable'>
              <div className='commission-rate-heading'>{t('list.heading.commissionRate')}</div>
              <div className='commission-split'>
                <div>{t('list.heading.premium')}</div>
                <div>{t('list.heading.accumulation')}</div>
              </div>
            </div> */}
            <div className='sortable' onClick={() => setSortHandler(sortBy.company)}>
              {t('list.heading.company')}
              {sort.by === sortBy.company && (
                <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div className='sortable' onClick={() => setSortHandler(sortBy.category)}>
              {t('list.heading.category')}
              {sort.by === sortBy.category && (
                <i className={`fas fa-sort-alpha-down${sort.order === sortOrder.asc ? '' : '-alt'} fa-lg`}></i>
              )}
            </div>
            <div></div>
            <div></div>
          </div>
          <div className='policies-list' ref={listRef}>
            {currentListType === policiesListType.irregular
              ? irregFrame.map(grp => {
                  return (
                    <div key={grp.groupId}>
                      <div className='sublist-heading'>
                        <img
                          src={`./assets/checkbox-sq/${
                            getGroupItemsIds(grp.groupId).every(p => checkedPolicies.includes(p)) ? 'A' : 'Ina'
                          }ctive.png`}
                          alt='chk'
                          onClick={() => checkPolicyGroupHandler(grp.groupId)}
                        />
                        <img
                          src={'./assets/dropdown-icon-light/dropdown-icon-light.png'}
                          alt='add'
                          className={classNames('sublist-heading-arrow', { opened: grp.items.length > 0 })}
                          onClick={() => collapseGroup(grp.groupId)}
                        />
                        <div className='sublist-heading-title' onClick={() => collapseGroup(grp.groupId)}>
                          <span className='title-content'>
                            {i18n.language === 'he'
                              ? irregularReasonObject[grp.groupId].name
                              : irregularReasonObject[grp.groupId].nameEn}
                          </span>
                          <span className='items-count'>({grp.itemsCount})</span>
                        </div>
                        <hr className=' sublist-heading-line' />
                      </div>
                      {grp.items.map(grpItem => (
                        <PoliciesListItem
                          item={grpItem}
                          key={grpItem.id}
                          checkPolicy={checkPolicyHandler}
                          checked={checkedPolicies.includes(grpItem.id)}
                          selectPolicy={selectPolicyHandler}
                          selected={selectedPolicy === grpItem.id}
                        />
                      ))}
                    </div>
                  )
                })
              : currentListType === policiesListType.signals
              ? signalFrame.map(grp => {
                  return (
                    <div key={grp.groupId}>
                      <div className='sublist-heading'>
                        <img
                          src={`./assets/checkbox-sq/${
                            getSignalGroupItemsIds(grp.groupId).every(p => checkedPolicies.includes(p)) ? 'A' : 'Ina'
                          }ctive.png`}
                          alt='chk'
                          onClick={() => checkPolicySignalGroupHandler(grp.groupId)}
                        />
                        <img
                          src={'./assets/dropdown-icon-light/dropdown-icon-light.png'}
                          alt='add'
                          className={classNames('sublist-heading-arrow', { opened: grp.items.length > 0 })}
                          onClick={() => collapseSignalGroup(grp.groupId)}
                        />
                        <div className='sublist-heading-title' onClick={() => collapseSignalGroup(grp.groupId)}>
                          <span className='title-content'>
                            {i18n.language === 'he'
                              ? irregularSignal[grp.groupId].name
                              : irregularSignal[grp.groupId].nameEn}
                          </span>
                          <span className='items-count'>({grp.itemsCount})</span>
                        </div>
                        <hr className=' sublist-heading-line' />
                      </div>
                      {grp.items.map(grpItem => (
                        <PoliciesListItem
                          item={grpItem}
                          key={grpItem.id}
                          checkPolicy={checkPolicyHandler}
                          checked={checkedPolicies.includes(grpItem.id)}
                          selectPolicy={selectPolicyHandler}
                          selected={selectedPolicy === grpItem.id}
                        />
                      ))}
                    </div>
                  )
                })
              : items
                  .slice(pageSize * (page - 1), pageSize * page)
                  .map(item => (
                    <PoliciesListItem
                      item={item}
                      key={item.id}
                      checkPolicy={checkPolicyHandler}
                      checked={checkedPolicies.includes(item.id)}
                      selectPolicy={selectPolicyHandler}
                      selected={selectedPolicy === item.id}
                    />
                  ))}
            {allCheckedPolicies.length > 0 && (
              <div className='policies-actions-panel-wrapper'>
                <PoliciesActions
                  policies={allCheckedPolicies}
                  closeModal={() => dispatch({ type: POLICY_UNCHECK_ALL })}
                />
              </div>
            )}
          </div>

          <div className='policies-paginate-wrapper'>
            <PaginateIrreg
              page={page - 1}
              pages={pages}
              pageSize={pageSize}
              totalItems={totalItems}
              forwardHandler={forwardHandler}
              backwardHandler={backwardHandler}
              setPageSize={setPageSize}
            />
          </div>
        </>
      ) : (
        <NoData text={t('list.noData')} />
      )}
    </div>
  )
}

export default PoliciesListContainer

function buildIrregGroups(list, filters = []) {
  let groupedList = {}
  const hasNoFiltersApplied =
    filters.length === 0 || filters.every(itm => itm.checked) || filters.every(itm => !itm.checked)
  const filteredIds = filters.filter(itm => itm.checked).map(itm => itm.id)
  list.forEach(item => {
    if (item.irregularReasonId) {
      let reasonIds = getIrregularReasonsFlags(item.irregularReasonId)

      if (!hasNoFiltersApplied) {
        reasonIds = [...reasonIds.filter(reasonId => filteredIds.includes(reasonId))]
      }
      reasonIds.forEach(reasonId => {
        if (groupedList[reasonId])
          groupedList[reasonId].items.push({
            ...item,
            irregularReasonId: reasonId,
          })
        else {
          groupedList[reasonId] = {
            ...groupedList[reasonId],
            items: [{ ...item, irregularReasonId: reasonId }],
          }
        }
        groupedList[reasonId] = {
          ...groupedList[reasonId],
          show: false,
        }
      })
    }
  })
  return groupedList
}

function buildIrregFrame(groupedList, currPage, pageSize) {
  let frame = []
  let framePage = 1
  let itemsPerPageLeft = Number(pageSize) + 1
  frame = Object.entries(groupedList)
    .map(([key, value]) => {
      return {
        startPage: framePage,
        itemsCount: value.items.length,
        items: value.show
          ? value.items.map(itm => {
              itemsPerPageLeft--
              if (itemsPerPageLeft === 0) {
                framePage++
                itemsPerPageLeft = pageSize
              }
              return { ...itm, page: framePage }
            })
          : [],
        groupId: key,
        endPage: framePage,
      }
    })
    .filter(grp => grp.startPage <= currPage && currPage <= grp.endPage)
    .map(grp => {
      return { ...grp, items: grp.items.filter(itm => itm.page === currPage) }
    })
  return frame
}

function buildSignalGroups(list, filters = []) {
  let groupedList = {}
  const hasNoFiltersApplied =
    filters.length === 0 || filters.every(itm => itm.checked) || filters.every(itm => !itm.checked)
  const signals = filters.filter(itm => (hasNoFiltersApplied ? true : itm.checked)).map(itm => itm.id)
  list
    .filter(l => l.irregularReasonId)
    .forEach(item => {
      let itemReasonIds = getIrregularReasonsFlags(item.irregularReasonId)
      signals.forEach(s => {
        if (irregularSignal[s].irregularReasons.some(ir => itemReasonIds.includes(ir))) {
          if (groupedList[s])
            groupedList[s].items.push({
              ...item,
              signalId: s,
            })
          else {
            groupedList[s] = {
              ...groupedList[s],
              items: [{ ...item, signalId: s }],
            }
          }
          groupedList[s] = {
            ...groupedList[s],
            show: false,
          }
        }
      })
    })
  return groupedList
}

function buildSignalFrame(groupedList, currPage, pageSize) {
  let frame = []
  let framePage = 1
  let itemsPerPageLeft = Number(pageSize) + 1
  frame = Object.entries(groupedList)
    .map(([key, value]) => {
      return {
        startPage: framePage,
        itemsCount: value.items.length,
        items: value.show
          ? value.items.map(itm => {
              itemsPerPageLeft--
              if (itemsPerPageLeft === 0) {
                framePage++
                itemsPerPageLeft = pageSize
              }
              return { ...itm, page: framePage }
            })
          : [],
        groupId: key,
        endPage: framePage,
      }
    })
    .filter(grp => grp.startPage <= currPage && currPage <= grp.endPage)
    .map(grp => {
      return { ...grp, items: grp.items.filter(itm => itm.page === currPage) }
    })
  return frame
}
