import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import PolicyStatusChip from '../../../../components/Common/PolicyStatusChip/PolicyStatusChip'
import { policyStatusType } from '../../../../types/policyStatusType'
import { irregularStatus } from '../../../../types/irregularStatusTypes'
import { getLastMonth } from '../../../../utils/date'
import { insuranceFieldType } from '../../../../types/insuranceField'
import AddPolicyCustomerModal from '../../../policy/components/AddPolicyCustomerModal/AddPolicyCustomerModal'
import SearchCustomerModal from '../../../../components/SearchCustomerModal/SearchCustomerModal'
import { useDispatch, useSelector } from 'react-redux'
import { POLICIES_ADD_CUSTOMER_RESET, POLICY_SET_CATEGORY_RESET } from '../../../../types/actionTypes'
import { addPolicyCustomer } from '../../actions/policiesActions'
import PrivateValue from '../../../../HOCs/PrivateValue'
import { userPreferenceType } from '../../../../types/agencyUserTypes'
import MeatBalls from '../../../../components/Common/MeatBalls/MeatBalls'
import './PoliciesListItem.scss'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import PoliciesMeatBallsMenu from '../PoliciesMeatBallsMenu/PoliciesMeatBallsMenu'
import CategoriesSelector from '../../../shared/components/CategoriesSelector/CategoriesSelector'
import { sendForTreatBatch, setPoliciesCategory } from '../../../policy/actions/policyActions'
import AddUserModal from '../../../customer/components/AddUserModal/AddUserModal'
import ModalWithNote from '../../../../components/Common/ModalWithNote/ModalWithNote'
import { customerTaskType } from '../../../../types/customerTaskTypes'
import { getAgents } from '../../../shared/actions/sharedActions'

const PoliciesListItem = ({ item, checked, checkPolicy, selected, selectPolicy }) => {
  const policyStatuses = []
  new Date(item.pullingDate) >= getLastMonth().from && policyStatuses.push(policyStatusType.new)
  !item.active && policyStatuses.push(policyStatusType.inactive)
  item.irregularStatus === irregularStatus.irregular && policyStatuses.push(policyStatusType.irregular)
  !item.clientId && policyStatuses.push(policyStatusType.lostData)

  const dispatch = useDispatch()
  const { t, i18n } = useTranslation('policies')
  const { t: tCustomer } = useTranslation('customer')

  const { addCustomerSuccess, addCustomerLoading } = useSelector(state => state.policies)
  const setCategorySuccess = useSelector(state => state.policy).setCategorySuccess
  const agents = useSelector(state => state.shared).agents
  const agentsLoading = useSelector(state => state.shared).agentsLoading

  const [showAddTreatUserModal, setShowAddTreatUserModal] = useState(false)
  const [user, setUser] = useState({ currAgent: 2 })
  const [showTreatNoteModal, setShowTreatNoteModal] = useState(false)
  const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)
  const [showSearchCustomerModal, setShowSearchCustomerModal] = useState(false)
  const [showCategoriesSelector, setShowCategoriesSelector] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [menuItems, setMenuItems] = useState([
    {
      id: 1,
      text: t('list.menu.treat'),
      clickHandler: () => {
        closeSearchCustomerModal()
        setShowCategoriesSelector(false)
        selectMenuItem(1)
        setShowAddTreatUserModal(true)
        setShowMenu(false)
      },
    },
    {
      id: 2,
      text: t('list.menu.categories'),
      clickHandler: () => {
        closeSearchCustomerModal()
        selectMenuItem(2)
        setShowCategoriesSelector(true)
      },
    },
    ...(policyStatuses.includes(policyStatusType.lostData)
      ? [
          {
            id: 3,
            text: t('list.menu.belongsCustomer'),
            clickHandler: () => {
              selectMenuItem(3)
              setShowCategoriesSelector(false)
              setShowSearchCustomerModal(true)
            },
          },
        ]
      : []),
  ])

  const addUser = newUser => {
    setUser({ ...user, ...newUser })
    setShowAddTreatUserModal(false)
    setShowTreatNoteModal(true)
  }

  const sendForTreatHandler = note => {
    const agentId = user.id
    const treatDtos = [
      {
        agentId: agentId,
        policyId: item.id,
        customerId: item.clientId,
        type: customerTaskType.policy,
        note,
      },
    ]
    dispatch(sendForTreatBatch(treatDtos))
  }

  const selectMenuItem = id => setMenuItems(prev => prev.map(mi => ({ ...mi, selected: mi.id === id })))

  const selectHandler = (e, policyId) => {
    e.stopPropagation()
    selectPolicy(policyId)
  }

  const checkHandler = (e, policyId) => {
    e.stopPropagation()
    checkPolicy(policyId)
  }

  const addPolicyExistingCustomer = customer => {
    dispatch(addPolicyCustomer(item, customer))
  }

  const closeSearchCustomerModal = () => {
    setMenuItems(prev => prev.map(mi => ({ ...mi, selected: false })))
    setShowSearchCustomerModal(false)
  }

  const closeMenuHandler = () => {
    setShowMenu(false)
    setMenuItems(prev => prev.map(mi => ({ ...mi, selected: false })))
    setShowCategoriesSelector(false)
    setShowSearchCustomerModal(false)
  }

  const updateCategoryHandler = (policiesIds, categoryId, updateAllRelatedPolicies) => {
    dispatch(setPoliciesCategory(policiesIds, categoryId, updateAllRelatedPolicies))
  }

  const elementary = item.fieldId !== insuranceFieldType.lifeFinance

  useEffect(() => {
    if (addCustomerSuccess && showSearchCustomerModal) {
      closeMenuHandler()
    }
    return () => {
      addCustomerSuccess && dispatch({ type: POLICIES_ADD_CUSTOMER_RESET })
    }
  }, [addCustomerSuccess, showSearchCustomerModal, dispatch])

  // useEffect(() => {
  //   return () => {
  //     dispatch({ type: POLICIES_ADD_CUSTOMER_RESET })
  //   }
  // }, [dispatch])

  useEffect(() => {
    if (setCategorySuccess) {
      dispatch({ type: POLICY_SET_CATEGORY_RESET })
      closeMenuHandler()
    }
  }, [setCategorySuccess, dispatch])
  useEffect(() => {
    if (showAddTreatUserModal) {
      dispatch(getAgents())
    }
  }, [showAddTreatUserModal, dispatch])

  return (
    <>
      <div className={classNames('policies-list-item-wrapper', { inactive: !item.active, selected, checked })}>
        <div
          className={classNames('policies-list-item', { inactive: !item.active, selected, checked })}
          onClick={e => selectHandler(e, item.id)}
        >
          <div className='policies-list-item-checkbox'>
            <img
              onClick={e => checkHandler(e, item.id)}
              src={`./assets/checkbox-sq/${checked ? 'A' : 'Ina'}ctive.png`}
              alt={checked ? 'unchk' : 'chk'}
            />
          </div>
          <div>{item.policyNumber}</div>

          <div className='customer-name'>
            <div>{item.clientName ?? '-'}</div>
            {/* {!item.clientId && (
              <div className='lost-btns-container'>
                <button
                  className='btn-lost'
                  onClick={e => {
                    e.stopPropagation()
                    setShowAddCustomerModal(true)
                  }}
                >
                  {t('list.buttons.lostNew')}
                </button>
                <button
                  className='btn-lost'
                  onClick={e => {
                    e.stopPropagation()
                    setShowSearchCustomerModal(true)
                  }}
                >
                  {t('list.buttons.lostExisting')}
                </button>
              </div>
            )} */}
          </div>
          <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
            <div dir='ltr'>{formatAsCurrency(item.commission)}</div>
          </PrivateValue>
          <PrivateValue requiredPrefernces={[userPreferenceType.premium]}>
            <div dir='ltr'>{formatAsCurrency(item.premium)}</div>
          </PrivateValue>
          <PrivateValue requiredPrefernces={[userPreferenceType.accumulation]}>
            <div dir='ltr'>{formatAsCurrency(item.accumulation)}</div>
          </PrivateValue>
          <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
            <div dir='ltr'>{formatAsPercent(item.comissionPremiumRate, { maximumFractionDigits: 3 })}</div>
          </PrivateValue>
          <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
            <div dir='ltr'>{formatAsPercent(item.comissionAccumulationRate, { maximumFractionDigits: 3 })}</div>
          </PrivateValue>
          {/* <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
            <div dir='ltr' style={{ textAlign: 'center' }} className='commission-rate'>
              {!elementary ? (
                <div className='commission-split'>
                  <div>{formatAsPercent(item.comissionAccumulationRate)}</div>
                  <div>{formatAsPercent(item.comissionPremiumRate)}</div>
                </div>
              ) : (
                <>
                  <div>{formatAsPercent(item.comissionPremiumRate)}</div>
                </>
              )}
            </div>
          </PrivateValue> */}
          <div>{i18n.language === 'he' ? item.companyName : item.companyNameEn}</div>
          <div>{i18n.language === 'he' ? item.categoryName : item.categoryNameEn}</div>
          <div className='policy-status'>
            {policyStatuses.map(ps => (
              <PolicyStatusChip
                status={ps}
                key={ps}
                shortName={policyStatuses.length > 1}
                styles={{ color: 'var(--text-primary-color)' }}
              />
            ))}
          </div>
          <div className='policy-menu-wrapper'>
            <PoliciesMeatBallsMenu
              menuItems={menuItems}
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              setCloseMenu={closeMenuHandler}
            >
              {showSearchCustomerModal && (
                <SearchCustomerModal
                  closeModal={closeSearchCustomerModal}
                  loading={addCustomerLoading}
                  key={item.id}
                  onSelect={addPolicyExistingCustomer}
                  policy={item}
                  setShowAddCustomerModal={() => {
                    setShowAddCustomerModal(true)
                    closeMenuHandler()
                  }}
                />
              )}
              {showCategoriesSelector && (
                <CategoriesSelector
                  policies={[item]}
                  closeSelector={() => setShowCategoriesSelector(false)}
                  setPoliciesCategory={updateCategoryHandler}
                />
              )}
            </PoliciesMeatBallsMenu>
          </div>
        </div>
      </div>
      {showAddCustomerModal && (
        <AddPolicyCustomerModal closeModal={() => setShowAddCustomerModal(false)} policy={item} />
      )}
      {showAddTreatUserModal && (
        <AddUserModal
          users={agents}
          loading={agentsLoading}
          closeModal={() => setShowAddTreatUserModal(false)}
          closeOnOutsideClick={false}
          onAddUser={addUser}
          currentUser={user?.currAgent}
          askConfirm={false}
        />
      )}
      {showTreatNoteModal && (
        <ModalWithNote
          closeModal={() => setShowTreatNoteModal(false)}
          closeOnOutsideClick={false}
          title={tCustomer('treatNoteModal.modalTitle')}
          subtitle={tCustomer('treatNoteModal.modalSubtitle')}
          label={tCustomer('treatNoteModal.inputLabel')}
          btnCaption={tCustomer('treatNoteModal.btnTitle')}
          back={() => setShowAddTreatUserModal(true)}
          onAddNote={sendForTreatHandler}
        />
      )}
    </>
  )
}

export default PoliciesListItem
