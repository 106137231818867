export const irregularReason = {
  default: 0,
  missingPayment: 1,
  negativePayment: 2,
  noPremium: 4,
  commissionRate: 8,
  elementaryNegativePayment: 16,
  elementaryPositivePremiumNegativeCommission: 32,
  commissionZero: 64,
  //agentAppointment: 128,
  accumulationPremiumZero: 128,
}

export const irregularReasonObject = {
  [irregularReason.missingPayment]: {
    name: 'פוליסה נגרעה מדו"ח קודם',
    nameEn: 'Policy was cut from prev report',
    order: 1,
  },
  [irregularReason.negativePayment]: {
    name: 'פרמיה או עמלה שלילית',
    nameEn: 'Negative commission or premium',
    order: 2,
  },
  [irregularReason.elementaryNegativePayment]: {
    name: 'פרמיה או עמלה שלילית באלמנטר',
    nameEn: 'Premium or negative commission in Elementary',
    order: 3,
  },
  [irregularReason.elementaryPositivePremiumNegativeCommission]: {
    name: 'עמלה שלילית עם פרמיה חיובית',
    nameEn: 'Negative premium with positive commission',
    order: 4,
  },
  [irregularReason.noPremium]: {
    name: 'פרמיה 0',
    nameEn: 'Premium is 0',
    order: 5,
  },
  [irregularReason.commissionRate]: {
    name: 'ירידה בשיעור עמלה',
    nameEn: 'Decrease in commission rate',
    order: 6,
  },
  [irregularReason.commissionZero]: {
    name: 'פוליסות עמלה 0',
    nameEn: '0 commission policies',
    order: 7,
  },
  [irregularReason.accumulationPremiumZero]: {
    name: 'מינוי סוכן',
    nameEn: 'Agent appointment',
    order: 8,
  },
}
