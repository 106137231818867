import classNames from 'classnames'
import { useRef, useState, useEffect } from 'react'
import MeatBalls from '../../../../components/Common/MeatBalls/MeatBalls'
import './PoliciesMeatBallsMenu.scss'

const PoliciesMeatBallsMenu = ({ showMenu, setShowMenu, setCloseMenu, menuItems = [], menuSize = 'md', children }) => {
  const menuRef = useRef()
  //const [showMenu, setShowMenu] = useState(false)
  let positionTop = false
  const menuClickHanlder = () => setShowMenu(!showMenu)

  if (showMenu) {
    const windowHeight = window.innerHeight
    const widndowBottomPadding = 50
    const menuBottom = menuRef?.current?.getBoundingClientRect().bottom
    const paddingTop = 10
    const paddingBottom = 10
    const menuItemHeight = 48
    const menuHeight = paddingTop + menuItemHeight * menuItems.length + paddingBottom
    positionTop = windowHeight - widndowBottomPadding - menuBottom < menuHeight
  }

  const refContextMenu = useRef(null)

  const closeMenu = () => {
    setCloseMenu()
  }

  const handleCloseMenu = event => {
    if (event.key === 'Escape' || (refContextMenu.current && !refContextMenu.current.contains(event.target))) {
      event.stopPropagation()
      closeMenu()
    }
  }

  const handleClick = (event, item) => {
    event.stopPropagation()
    if (item.clickHandler) {
      item.clickHandler()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleCloseMenu, true)
    document.addEventListener('click', handleCloseMenu, true)
    return () => {
      document.removeEventListener('keydown', handleCloseMenu, true)
      document.removeEventListener('click', handleCloseMenu, true)
    }
  })

  return (
    <div className='policies-meatballs-menu-container' ref={menuRef}>
      <MeatBalls
        showBackground={false}
        onClick={menuItems.length > 0 ? menuClickHanlder : () => {}}
        opened={showMenu}
        size={menuSize}
      />
      {showMenu && (
        <div className={classNames('policies-meatballs-menu', { 'position-top': positionTop })} ref={refContextMenu}>
          <div className='policies-context-menu-container'>
            <div className='context-menu' onClick={e => e.stopPropagation()}>
              {menuItems.map((item, i) => (
                <div
                  key={i + item.text}
                  className={classNames('context-menu-item', {
                    danger: item.warn,
                    blured: item.blured,
                    disabled: item.disabled,
                    selected: item.selected,
                  })}
                  {...(!item.disabled && { onClick: e => handleClick(e, item) })}
                >
                  {item.text}
                </div>
              ))}
            </div>
          </div>
          {children}
        </div>
      )}
    </div>
  )
}

export default PoliciesMeatBallsMenu
