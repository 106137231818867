import axios from 'axios'
import { urlCustomerGroups, urlCustomers } from '../../environment/urls'
import { customersListType } from '../../types/tablesTypes'
import { policyRatingType } from '../../types/policyRatingTypes'
import { getCheckedIds } from '../../utils/objectUtils'
import { sortOrder } from '../../types/sortByTypes'
import $api, { getCancelToken } from '..'

const customersUrl = urlCustomers()

let cancelExportCustomersInfoRequest

export const exportCustomersInfoRequest = async ({
  listType,
  totalElements = 0,
  advancedFiltersApplied,
  sort,
  categoriesFilter,
  companiesFilter,
  categoriesAndFilter,
  companiesAndFilter,
  categoriesNoFilter,
  companiesNoFilter,
  groupFilter,
  searchTerm: searchText,
  page,
  pageSize,
  collapsedGroups,
} = {}) => {
  if (typeof cancelExportCustomersInfoRequest != typeof undefined) {
    cancelExportCustomersInfoRequest.cancel('Operation canceled due to new request.')
  }
  cancelExportCustomersInfoRequest = axios.CancelToken.source()
  let currentListType = 0
  let rating = null
  switch (listType) {
    case customersListType.rating:
      currentListType = 1
      break
    case customersListType.ratingGreen:
      rating = policyRatingType.green
      currentListType = 1
      break
    case customersListType.ratingOrange:
      rating = policyRatingType.orange
      currentListType = 1
      break
    case customersListType.ratingRed:
      rating = policyRatingType.red
      currentListType = 1
      break
    case customersListType.distribution:
      currentListType = 2
      break
    case customersListType.inactive:
      currentListType = 3
      break
    default:
      break
  }
  const requestParams = {
    categories: getCheckedIds(advancedFiltersApplied ? categoriesAndFilter : categoriesFilter),
    companies: getCheckedIds(advancedFiltersApplied ? companiesAndFilter : companiesFilter),
    excludeCategories: getCheckedIds(advancedFiltersApplied ? categoriesNoFilter : []),
    excludeCompanies: getCheckedIds(advancedFiltersApplied ? companiesNoFilter : []),
    shouldHaveBoth: advancedFiltersApplied,
    percentageSplitGroups: groupFilter.filter(c => c.checked).map(c => c.id + 1),
    searchText,
    customersListType: currentListType,
    page,
    pageSize,
    sortBy: sort.by,
    descending: sort.order === sortOrder.desc,
    collapsedGroups: Object.entries(collapsedGroups)
      .filter(([key, v]) => !v.show)
      .map(([key]) => key),
    rating,
    totalElements,
  }

  await $api
    .post(`${customersUrl}export-customers-info`, requestParams, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: cancelExportCustomersInfoRequest.token,
    })
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'Customers.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export customers from the backend.', response)
    })
}

export const downloadCustomersGroupsTemplateRequest = async ({
  totalElements = 0,
  advancedFiltersApplied,
  sort,
  categoriesFilter,
  companiesFilter,
  categoriesAndFilter,
  companiesAndFilter,
  categoriesNoFilter,
  companiesNoFilter,
  groupFilter,
  searchText,
  page,
  pageSize,
  collapsedGroups,
  calculateByGroup,
  rating,
} = {}) => {
  const requestParams = {
    categories: getCheckedIds(advancedFiltersApplied ? categoriesAndFilter : categoriesFilter),
    companies: getCheckedIds(advancedFiltersApplied ? companiesAndFilter : companiesFilter),
    excludeCategories: getCheckedIds(advancedFiltersApplied ? categoriesNoFilter : []),
    excludeCompanies: getCheckedIds(advancedFiltersApplied ? companiesNoFilter : []),
    shouldHaveBoth: advancedFiltersApplied,
    calculateByGroup,
    searchText,
    percentageSplitGroups: groupFilter.filter(c => c.checked).map(c => c.id + 1),
    page,
    pageSize,
    sortBy: sort.by,
    descending: sort.order === sortOrder.desc,
    collapsedGroups: Object.entries(collapsedGroups)
      .filter(([key, v]) => !v.show)
      .map(([key]) => key),
    rating,
    totalElements,
  }

  await $api
    .post(`${customersUrl}export-customers-with-groups`, requestParams, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
      cancelToken: getCancelToken('downloadCustomersGroupsTemplateRequest').cancelToken,
    })
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'CustomersGroups.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Download customers groups from the backend.', response)
    })
}

export const uploadCustomersGroupsTemplateRequest = async file => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('fileName', 'customersFamilyGroups')
  return await $api.post(`${urlCustomerGroups()}import-customers-family-groups`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const getCustomersFiltersRequest = async () =>
  await $api.post(`${customersUrl}filter`, {}, getCancelToken('getCustomersFiltersRequest'))
