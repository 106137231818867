import { useTranslation } from 'react-i18next'
import { policiesListType } from '../../../../types/tablesTypes'
import './PoliciesDashboard.css'

const PoliciesDashboard = ({ currentListType, setCurrentListType, policies }) => {
  const { t } = useTranslation('policies')
  const dashboard = {
    [policiesListType.all]: { text: t('dashboard.allPolicies'), count: policies.all },
    [policiesListType.signals]: { text: t('dashboard.irregularPolicies'), count: policies.signals },
    [policiesListType.new]: { text: t('dashboard.newPolicies'), count: policies.new },
    [policiesListType.lostData]: { text: t('dashboard.lostDataPolicies'), count: policies.lostData },
    [policiesListType.inactive]: { text: t('dashboard.inactivePolicies'), count: policies.inactive },
  }
  return (
    <div className='policies-dashboard'>
      <p>
        {dashboard[currentListType].text} <span>({Intl.NumberFormat().format(dashboard[currentListType].count)})</span>
      </p>
    </div>
  )
}

export default PoliciesDashboard
