import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import './StepPhone.scss'
import { Trans, useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect'
import { hebrew } from '../../../../i18n'

const StepPhone = ({ children, serverError, setServerError }) => {
  const { t } = useTranslation('registration')
  const dispatch = useDispatch()
  const [showHiddenBtn, setShowHiddenBtn] = useState(false)

  useEffect(() => {
    if (serverError) {
      const timer = setTimeout(() => {
        if (serverError) {
          setServerError(null)
        }
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [serverError, setServerError])

  return (
    <div
      className='register-step-phone-wrapper'
      style={{
        backgroundImage: `url('assets/_register/step-phone/image${isMobile ? '_mobile' : ''}${
          hebrew() && !isMobile ? '' : '_en'
        }.png'), url('assets/_register/step-phone/bg${hebrew() && !isMobile ? '' : '_en'}.png')`,
        backgroundPosition: `${hebrew() && !isMobile ? 'right' : 'left'} ${isMobile ? '-10%' : 'top'}, left bottom`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundSize: `${isMobile ? 'auto' : 'contain'}, cover`,
      }}
    >
      <div className='register-step-phone'>
        {/* <div className='register-step-phone-text-container'>
          <p className='register-step-phone-text-pretitle'>{t('step2factor.preTitle')}</p>
          <h3 className='register-step-phone-text-title'>{t('step2factor.title')}</h3>
          <p className='register-step-phone-text-content'>{t('step2factor.text')}</p>
        </div> */}
        <div className='register-step-phone-content'>
          <h4>{t('step2factor.cardTitle')}</h4>
          <div className='register-step-phone-form-container'>
            {/* <div className='register-step-phone-form-desc'>
            <h6 className='register-step-phone-form-card-title'>{t('step2factor.cardTitle')}</h6>
            <h5>{t('step2factor.formDescTitle')}</h5>
            <h6>{t('step2factor.formDescSubTitle')}</h6>
            <p>{t('step2factor.formDescText')}</p>
            <p className='form-desc-remark'>{t('step2factor.formDescRemark')}</p>
          </div> */}
            {children}
            {serverError?.length > 0 && (
              <div className='register-step-phone-form-error'>
                <span>{serverError}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default StepPhone
