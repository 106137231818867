import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { hebrew, rtlDir } from '../../../../i18n'
import { ArrowDropDown, ArrowDropUp, CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material'
import InputSearch from '../../../../components/Common/InputSearch/InputSearch'
import CancelFilledIconSvg from '../../../../components/Common/SvgIcons/CancelFilledIconSvg/CancelFilledIconSvg'
import NoData from '../../../../components/Common/NoData/NoData'
import { cn } from '../../../../utils/stylesUtils'
import './AgencyHouseAnalyticsFiltersPicker.scss'

const AgencyHouseAnalyticsFiltersPicker = ({
  filters,
  filtersLoading,
  applyFilters,
  filtersTitle,
  noFoundText,
  noFiltersText,
  searchPlaceholder,
}) => {
  const [showPicker, setShowPicker] = useState(false)
  const [filtersDirty, setFiltersDirty] = useState(false)
  const [selectedIds, setSelectedIds] = useState([])
  const [searchName, setSearchName] = useState('')
  const btnRef = useRef()
  const overlayRef = useRef()

  const hasFiltersApplied = filters.some(a => a.selected)

  const getButtonPostition = () => {
    const { bottom, left, right } = btnRef?.current?.getBoundingClientRect()
    return { left, right, y: bottom }
  }

  const filteredFilters = filters.filter(a =>
    a[hebrew() ? 'name' : 'nameEn'].toLowerCase().includes(searchName.toLowerCase())
  )

  const applyFiltersHandler = () => {
    filtersDirty && applyFilters(selectedIds)
    setFiltersDirty(false)
    setShowPicker(false)
  }

  useEffect(() => {
    if (showPicker) {
      setSelectedIds(filters.filter(a => a.selected).map(a => a.id))
      setSearchName('')
    }
  }, [filters, showPicker])

  return (
    <div className='agency-house-analytics-filters-picker-container'>
      <div className='agency-house-analytics-filters-picker-btn-container' ref={btnRef}>
        <div
          className={cn('agency-house-analytics-filters-picker-btn', { 'has-applied': hasFiltersApplied })}
          onClick={() => (showPicker ? applyFiltersHandler() : setShowPicker(!showPicker))}
          disabled={filtersLoading}
        >
          <span>{filtersTitle}</span>
          {hasFiltersApplied ? (
            <button
              onClick={e => {
                e.stopPropagation()
                applyFilters([])
              }}
            >
              <CancelFilledIconSvg />
            </button>
          ) : showPicker ? (
            <ArrowDropUp />
          ) : (
            <ArrowDropDown />
          )}
        </div>
      </div>
      {showPicker && (
        <div
          className='agency-house-analytics-filters-picker-overlay'
          ref={overlayRef}
          onClick={e => {
            overlayRef.current === e.target && applyFiltersHandler()
          }}
        >
          <div
            className='agency-house-analytics-filters-picker-main-container'
            style={{
              top: getButtonPostition().y + 14 + 'px',
              ...(rtlDir()
                ? { left: getButtonPostition().left + 'px' }
                : { left: getButtonPostition().right + 'px', transform: 'translateX(-100%)' }),
            }}
          >
            {filters.length === 0 ? (
              <NoData text={noFiltersText} />
            ) : (
              <>
                <div className='agency-house-analytics-filters-picker-search-container'>
                  <InputSearch placeholder={searchPlaceholder} searchHandler={setSearchName} />
                </div>
                <div className='agency-house-analytics-filters-picker-agents-container'>
                  {filteredFilters.length === 0 && <NoData text={noFoundText} />}
                  {filteredFilters.map(({ id, name, nameEn }) => (
                    <div
                      key={id}
                      className={cn('agency-house-analytics-filters-picker-agent-container', {
                        selected: selectedIds.includes(id),
                      })}
                      onClick={() => {
                        setFiltersDirty(true)
                        setSelectedIds(prev => (prev.includes(id) ? prev.filter(itm => itm !== id) : [...prev, id]))
                      }}
                    >
                      {selectedIds.includes(id) ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                      <span>{hebrew() ? name : nameEn}</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default AgencyHouseAnalyticsFiltersPicker
