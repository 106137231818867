import CustomerDetailsListItem from './CustomerDetailsListItem'
import { infoDetailsType } from '../../../../types/infoDetailsTypes'
import CustomerGroups from '../CustomerGroups/CustomerGroups'
import './CustomerDetailsList.scss'

const CustomerDetailsList = ({ person, changePerson }) => {
  return (
    <div className='customer-details-list-container'>
      <CustomerDetailsListItem
        detailsType={infoDetailsType.address}
        content={person.address}
        setContent={changePerson}
      />
      <CustomerDetailsListItem detailsType={infoDetailsType.phone} content={person.phone} setContent={changePerson} />
      <CustomerGroups />
    </div>
  )
}

export default CustomerDetailsList
