import { useTranslation } from 'react-i18next'
import { registerFlowType, registerStep } from '../../../../types/registerStep'
import RegisterTermsFooter from '../RegisterTermsFooter/RegisterTermsFooter'
import RegisterFlowTypeCard from '../RegisterFlowTypeCard/RegisterFlowTypeCard'
import './RegisterFlowType.scss'

const RegisterFlowType = ({ setStep }) => {
  const { t } = useTranslation('registration')
  const flowTypes = [
    {
      type: registerFlowType.agentHouse,
      featuresCount: 1,
    },
    {
      type: registerFlowType.regular,
      featuresCount: 2,
    },
  ]

  const selectFlowType = type =>
    setStep(type === registerFlowType.regular ? registerStep.agentDetails : registerStep.agentHouseDetails)

  return (
    <section className='register-flow-types'>
      <h2>{t('stepFlowType.title')}</h2>
      <p>{t('stepFlowType.desc')}</p>
      <div className='register-flow-types-cards'>
        {flowTypes.map(({ type, featuresCount }) => (
          <RegisterFlowTypeCard
            flowType={type}
            featuresCount={featuresCount}
            key={type}
            selectFlowType={selectFlowType}
          />
        ))}
      </div>
      <RegisterTermsFooter />
    </section>
  )
}
export default RegisterFlowType
