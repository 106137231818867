import {
  POLICIES_ADD_CUSTOMER_REQUEST,
  POLICIES_ADD_CUSTOMER_SUCCESS,
  POLICIES_ADD_CUSTOMER_FAIL,
  POLICIES_ALL_REQUEST,
  POLICIES_ALL_SUCCESS,
  POLICIES_ALL_FAIL,
  POLICIES_FILTERS_REQUEST,
  POLICIES_FILTERS_SUCCESS,
  POLICIES_FILTERS_FAIL,
  POLICIES_RESET,
  POLICY_DEACTIVATE_SUCCESS,
  POLICY_FIX_SUCCESS,
  POLICY_IGNORE_SUCCESS,
  POLICY_SET_CATEGORY_SUCCESS,
  POLICIES_ADD_CUSTOMER_RESET,
  POLICY_IRREG_SUCCESS,
  POLICIES_DATES_FILTER_SUCCESS,
  POLICIES_DATES_FILTER_APPLY,
  POLICIES_TOTALS_REQUEST,
  POLICIES_TOTALS_SUCCESS,
  POLICIES_TOTALS_FAIL,
  POLICIES_FILTERS_CLEAR,
  POLICIES_IRREG_FILTER_APPLY,
  POLICIES_IRREG_FILTERS_APPLY,
  POLICIES_SIGNALS_FILTER_APPLY,
} from '../../../types/actionTypes'
import { irregularReasonObject } from '../../../types/irregularReasonTypes'
import { irregularSignal } from '../../../types/irregularSignals'
import { irregularStatus } from '../../../types/irregularStatusTypes'
import { getLastMonth } from '../../../utils/date'

const irregularReasonFilterInit = Object.entries(irregularReasonObject)
  .map(item => {
    return {
      order: item[1].order,
      id: Number(item[0]),
      name: item[1].name,
      nameEn: item[1].nameEn,
      checked: false,
    }
  })
  .sort((a, b) => a.order - b.order)

const signalsFilterInit = Object.values(irregularSignal)
  .filter(s => s?.irregularReasons?.length > 0)
  .map(({ order, id, name, nameEn }) => {
    return {
      order,
      id,
      name,
      nameEn,
      checked: false,
    }
  })
  .sort((a, b) => a.order - b.order)

const initialState = {
  allPolicies: [],
  allPoliciesLoading: true,
  allPoliciesError: null,
  latestDownloadDate: getLastMonth().from,
  companiesFilter: [],
  categoriesFilter: [],
  datesFilter: [],
  irregularReasonFilter: irregularReasonFilterInit,
  signalsFilter: signalsFilterInit,
  totals: {
    all: 0,
    allLoading: true,
    new: 0,
    newLoading: true,
    lostData: 0,
    lostDataLoading: true,
    irregular: 0,
    irregularLoading: true,
    signals: 0,
    signalsLoading: true,
    inactive: 0,
    inactiveLoading: true,
  },
  policiesFiltersLoading: true,
  policiesFiltersError: null,
  addCustomerSuccess: false,
  addCustomerLoading: false,
}

export const policiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case POLICIES_ALL_REQUEST:
      return {
        ...state,
        allPolicies: [],
        allPoliciesLoading: true,
        allPoliciesError: null,
      }
    case POLICIES_ALL_SUCCESS:
      return {
        ...state,
        allPolicies: action.payload,
        allPoliciesLoading: false,
        allPoliciesError: null,
        latestDownloadDate:
          action.payload.map(p => p.pullingDate).sort((a, b) => new Date(b) - new Date(a))[0] ?? getLastMonth().from,
      }
    case POLICIES_ALL_FAIL:
      return {
        ...state,
        allPoliciesLoading: false,
        allPoliciesError: action.payload,
      }
    case POLICIES_FILTERS_REQUEST:
      return {
        ...state,
        policiesFiltersLoading: true,
        policiesFiltersError: null,
        companiesFilter: [],
        categoriesFilter: [],
      }
    case POLICIES_FILTERS_SUCCESS:
      return {
        ...state,
        policiesFiltersLoading: false,
        policiesFiltersError: null,
        companiesFilter: action.payload.companiesFilter,
        categoriesFilter: action.payload.categoriesFilter,
      }
    case POLICIES_FILTERS_FAIL:
      return {
        ...state,
        policiesFiltersLoading: false,
        policiesFiltersError: action.payload,
        companiesFilter: [],
        categoriesFilter: [],
      }
    case POLICIES_DATES_FILTER_SUCCESS:
      return {
        ...state,
        datesFilter: action.payload,
      }
    case POLICIES_DATES_FILTER_APPLY:
      return {
        ...state,
        datesFilter: state.datesFilter.map(d => ({ ...d, selected: action.payload.includes(d.date) })),
      }
    case POLICIES_TOTALS_REQUEST:
      return {
        ...state,
        totals: {
          ...state.totals,
          allLoading: true,
          newLoading: true,
          lostDataLoading: true,
          irregularLoading: true,
          signalsLoading: true,
          inactiveLoading: true,
        },
      }
    case POLICIES_TOTALS_SUCCESS:
      return {
        ...state,
        totals: {
          allLoading: false,
          newLoading: false,
          lostDataLoading: false,
          irregularLoading: false,
          signalsLoading: false,
          inactiveLoading: false,
          signals: action.payload.irregular,
          ...action.payload,
        },
      }
    case POLICIES_TOTALS_FAIL:
      return {
        ...state,
        totals: {
          allLoading: false,
          newLoading: false,
          lostDataLoading: false,
          irregularLoading: false,
          signalsLoading: false,
          inactiveLoading: false,
        },
        totalsError: action.payload,
      }
    case POLICY_SET_CATEGORY_SUCCESS:
      return {
        ...state,
        allPolicies: state.allPolicies.map(p => {
          if (action.payload.success && action.payload.updatedPolicies.includes(p.id)) {
            const { category } = action.payload
            return {
              ...p,
              categoryId: category.id,
              categoryName: category.name,
              categoryNameEn: category.englishName,
            }
          }
          return p
        }),
      }
    case POLICY_FIX_SUCCESS:
    case POLICY_IGNORE_SUCCESS:
      return {
        ...state,
        allPolicies: state.allPolicies.map(p => {
          const { success, irregsCount, policyId } = action.payload
          if (success && irregsCount === 1 && policyId === p.id) {
            return {
              ...p,
              irregularStatus: irregularStatus.normal,
            }
          }
          return p
        }),
      }
    case POLICY_DEACTIVATE_SUCCESS:
      return {
        ...state,
        allPolicies: state.allPolicies.map(p => {
          const { success, policyId } = action.payload
          if (success && policyId === p.id) {
            return {
              ...p,
              active: false,
            }
          }
          return p
        }),
      }
    case POLICIES_ADD_CUSTOMER_REQUEST:
      return {
        ...state,
        addCustomerLoading: true,
        addCustomerSuccess: false,
      }
    case POLICIES_ADD_CUSTOMER_SUCCESS:
      const { success, policy, customer } = action.payload
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerSuccess: success,
        ...(success && {
          allPolicies: state.allPolicies.map(p =>
            p.id === policy.id
              ? {
                  ...p,
                  clientName: customer.name,
                  clientId: customer.id ?? customer.idNumber + customer.name,
                }
              : p
          ),
        }),
      }
    case POLICIES_ADD_CUSTOMER_FAIL:
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerSuccess: false,
        addCustomerError: action.payload,
      }
    case POLICIES_ADD_CUSTOMER_RESET:
      return {
        ...state,
        addCustomerLoading: false,
        addCustomerSuccess: false,
        addCustomerError: null,
      }
    case POLICY_IRREG_SUCCESS:
      const irregularities = action.payload
      return {
        ...state,
        allPolicies: state.allPolicies.map(p =>
          irregularities.map(irr => irr.policyId).includes(p.id)
            ? {
                ...p,
                irregularStatus: irregularStatus.irregular,
                hasNontreatedIrregualrity: true,
                irregularReasonId:
                  p.irregularReasonId | irregularities.find(irr => irr.policyId === p.id).irregularReason,
              }
            : p
        ),
      }
    case POLICIES_FILTERS_CLEAR:
      return {
        ...state,
        datesFilter: state.datesFilter.map(d => ({ ...d, selected: false })),
        irregularReasonFilter: irregularReasonFilterInit,
        signalsFilter: signalsFilterInit,
      }
    case POLICIES_IRREG_FILTER_APPLY: {
      const { reasonId, checked } = action.payload
      return {
        ...state,
        irregularReasonFilter: state.irregularReasonFilter
          .map(reason => (reason.id === reasonId ? { ...reason, checked } : reason))
          .map((item, _, arr) =>
            arr.every(itm => itm.checked) || arr.every(itm => !itm.checked) ? { ...item, checked: false } : item
          ),
      }
    }
    case POLICIES_IRREG_FILTERS_APPLY: {
      return {
        ...state,
        irregularReasonFilter: state.irregularReasonFilter.map(reason => ({
          ...reason,
          checked: action.payload.includes(reason.id),
        })),
      }
    }
    case POLICIES_SIGNALS_FILTER_APPLY: {
      const { signalId, checked } = action.payload
      return {
        ...state,
        signalsFilter: state.signalsFilter
          .map(s => (s.id === signalId ? { ...s, checked } : s))
          .map((item, _, arr) =>
            arr.every(itm => itm.checked) || arr.every(itm => !itm.checked) ? { ...item, checked: false } : item
          ),
      }
    }
    case POLICIES_RESET:
      return initialState
    default:
      return state
  }
}
