import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import { hebrew } from '../../../../i18n'
import { incomesTotalsObject, incomesTotalsType } from '../../../../types/analyticsIncomesTypes'
import { getIncomesTotalsTypes } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AgencyHouseAnalyticsIncomesChart from '../AgencyHouseAnalyticsIncomesChart/AgencyHouseAnalyticsIncomesChart'
import ApexCharts from 'apexcharts'
import './AgencyHouseAnalyticsIncomesChartsContainer.scss'
import { categoriesObjects } from '../../../../types/categoriesTypes'

const AgencyHouseAnalyticsIncomesChartsContainer = ({
  dataType,
  listType,
  periodViewType,
  list,
  listLoading,
  totals,
  selectItem,
  selectedItem,
}) => {
  const totalsTypes = getIncomesTotalsTypes(dataType, periodViewType)
  const totalsTypesKeys = Object.fromEntries(
    Object.entries(incomesTotalsType)
      .filter(([k, v]) => totalsTypes.includes(v))
      .map(([k, v]) => [v, k])
  )
  const { t } = useTranslation('agencyHouseAnalytics')
  const getSeries = totalsType => {
    const seriesKey = totalsTypesKeys[totalsType]
    return list.filter(itm => itm[seriesKey]).map(itm => itm[seriesKey])
  }

  const getCategories = totalsType => {
    const seriesKey = totalsTypesKeys[totalsType]
    return list
      .filter(itm => itm[seriesKey])
      .map(itm => [
        hebrew() ? itm.name : itm.nameEn,
        dataType === requestDataTypes.commissionRate
          ? formatAsPercent(itm[seriesKey])
          : `₪${Intl.NumberFormat('en', { notation: itm[seriesKey] > 1000 ? 'compact' : 'standard' }).format(
              itm[seriesKey]
            )}`,
        itm[`${seriesKey}Diff`]
          ? `${itm[`${seriesKey}Diff`] > 0 ? '+' : '-'} ₪${Intl.NumberFormat('en', {
              notation: Math.abs(itm[`${seriesKey}Diff`]) > 1000 ? 'compact' : 'standard',
            }).format(Math.abs(itm[`${seriesKey}Diff`]))}${itm[`${seriesKey}Diff`] > 0 ? '\u2191' : '\u2193'}`
          : '',
        itm[`${seriesKey}Diff`] < 0 ? 'red' : itm[`${seriesKey}Diff`] > 0 ? 'green' : '',
      ])
  }

  const getColors = totalsType => {
    const seriesKey = totalsTypesKeys[totalsType]
    return list
      .map((itm, index) => ({ ...itm, index }))
      .filter(itm => itm[seriesKey])
      .map(
        itm =>
          categoriesObjects[itm.id ?? itm.key]?.markerColor ??
          categoriesObjects[itm.index]?.markerColor ??
          categoriesObjects['default'].markerColor
      )
  }
  const getTotalsValue = totalsType => {
    const { value } = totals.find(t => t.type === totalsType)
    return (
      <>
        {t('total')}
        <span className='total-value'>
          {value
            ? dataType === requestDataTypes.commissionRate
              ? formatAsPercent(value)
              : '₪' + formatAsCurrency(value, { currency: '' })
            : '--'}
        </span>
      </>
    )
  }

  const selectItemHandler = totalsType => idx => {
    const diffKey = totalsTypesKeys[totalsType]
    const filteredList = list.filter(itm => itm[totalsTypesKeys[totalsType]])
    filteredList[idx] && selectItem({ key: filteredList[idx].key, type: listType, diffKey })
  }

  useEffect(() => {
    totalsTypes.forEach(tt => {
      let chart
      try {
        chart = ApexCharts.getChartByID(`incomes-chart-total-type-${tt}`)
      } catch (error) {
        chart = null
      }

      if (chart) {
        const selectedDataPoints = chart.w.globals.selectedDataPoints
        const hasSelectedDataPoints = selectedDataPoints.length > 0 && selectedDataPoints[0].length > 0
        if (selectedItem) {
          const selectedItemIndex = list
            .filter(itm => itm[totalsTypesKeys[tt]])
            .findIndex(itm => itm.key === selectedItem.key && totalsTypesKeys[tt] === selectedItem.diffKey)
          // if chart has selected data points, but the point is not in the list, deselect it
          if (selectedItemIndex === -1 && hasSelectedDataPoints) {
            //console.log(`Has selected item, char has selcted data point out of list, - DESELECT CHART ID ${tt}`)
            chart.toggleDataPointSelection(0, chart.w.globals.selectedDataPoints[0][0])
          }
          // if point in the list, but no data points selected, or incorrect points selcted, reselect
          if (
            selectedItemIndex > -1 &&
            (selectedDataPoints.length === 0 || !selectedDataPoints[0].includes(selectedItemIndex))
          ) {
            // console.log(
            //   `Has selected item, no data points, or incorrect data points selected - SELECT INDEX ${selectedItemIndex} CHART ID ${tt}`
            // )
            chart.toggleDataPointSelection(0, selectedItemIndex)
          }
        }
        if (!selectedItem && hasSelectedDataPoints) {
          //console.log(`no selected item, but chart has selected data point - DESELECT CHART ID ${tt}`)
          chart.toggleDataPointSelection(0, chart.w.globals.selectedDataPoints[0][0])
        }
      }
    })
  }, [selectedItem])

  if (listLoading) {
    return <Loader />
  }

  return (
    <div className='agency-house-analytics-incomes-charts-container'>
      {totalsTypes.map(tt => (
        <AgencyHouseAnalyticsIncomesChart
          key={tt}
          totalsName={
            periodViewType === analyticsPeriodViewType.monthly
              ? hebrew()
                ? incomesTotalsObject[tt].nameMonthlyView
                : incomesTotalsObject[tt].nameEnMonthlyView
              : hebrew()
              ? incomesTotalsObject[tt].name
              : incomesTotalsObject[tt].nameEn
          }
          totalsValue={getTotalsValue(tt)}
          series={getSeries(tt)}
          categories={getCategories(tt)}
          colors={getColors(tt)}
          selectItem={selectItemHandler(tt)}
          selectedItem={selectedItem}
          chartId={`incomes-chart-total-type-${tt}`}
        />
      ))}
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesChartsContainer
