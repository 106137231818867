import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { sendEmail } from '../../actions/landingActions'
import './LandingContacts.scss'
import { LANDING_INFO_SET } from '../../../../types/actionTypes'

const LandingContacts = () => {
  // const sendEmailSuccess = useSelector(state => state.landing.sendEmailSuccess)
  // const sendEmailLoading = useSelector(state => state.landing.sendEmailLoading)
  // const sendEmailError = useSelector(state => state.landing.sendEmailError)

  const formDefaultValues = {
    fullName: '',
    mobilePhone: '',
    messageContent: '',
  }
  const { register, handleSubmit, reset, errors, getValues } = useForm({
    defaultValues: formDefaultValues,
  })
  const { t } = useTranslation('landing')
  const dispatch = useDispatch()

  const submitHandler = data => {
    //dispatch(sendEmail(data))
    //reset(formDefaultValues)
  }

  const linkClickHandler = e => {
    const data = getValues()
    dispatch({ type: LANDING_INFO_SET, payload: { ...data } })
  }

  return (
    <section className='landing-contacts' id='contacts'>
      <div className='landing-section-container'>
        <div className='landing-contacts-container'>
          <div className='landing-contacts-form-container'>
            <div className='landing-contacts-form-header'>{t('contacts.formHeader')}</div>
            <form className='landing-contacts-form' onSubmit={handleSubmit(submitHandler)}>
              <div className='landing-contacts-form-inputs'>
                <input type='text' name='fullName' placeholder={t('contacts.inputName')} ref={register()} />
                <input
                  type='tel'
                  name='mobilePhone'
                  placeholder={t('contacts.inputPhone')}
                  ref={register({ required: true })}
                />
                <textarea
                  name='messageContent'
                  placeholder={t('contacts.inputMessage')}
                  ref={register({ required: true })}
                  style={{ resize: 'none' }}
                />
              </div>
              {/* <button type='submit' disabled={errors.length > 0}>
                  {t('contacts.btnCaption')}
                </button> */}
              <div className='landing-contacts-form-link'>
                <a href='#/early' onClick={linkClickHandler}>
                  {t('contacts.btnCaption')}
                </a>
              </div>
            </form>
          </div>
          <div className='landing-contacts-nav-container'>
            <nav className='landing-contacts-navbar'>
              <ul>
                <li>
                  <a href='#home' className='current'>
                    {t('navbar.home')}
                  </a>
                </li>
                {/* <li>
                    <a href='#downloads'>{t('navbar.howItWorks')}</a>
                  </li>
                  <li>
                    <a href='#portfolio'>{t('navbar.products')}</a>
                  </li> */}
                {/* <li>
                  <a href='/#prices'>{t('navbar.prices')}</a>
                </li> */}
                <li>
                  <a href='/#marketing'>{t('navbar.marketing')}</a>
                </li>
                <li>
                  <a href='#contacts'>{t('navbar.contactUs')}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='landing-contacts-logo-container'>
            <div>
              <img src='./assets/Logo/Logo_full.svg' alt='AGent_logo' />
            </div>
            <div>
              איי.ג'נט
              <br />
              ט.ו.מ.ר תוכנה לביטוח
            </div>
            <div>
              <a href='mailto:info@ai-gentins.com'>info@ai-gentins.com</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default LandingContacts
