import { getLastChars } from './charsUtils'

export const formatAsNumber = number => {
  if (number === null || number === undefined || isNaN(number)) number = 0
  return number.toLocaleString('en-US')
}

export const formatAsCurrency = (number, { currency = '₪', fractionDigits = 2, dashesForZero = true } = {}) => {
  if (isNaN(number) || number === null || number === undefined || (dashesForZero && +number === 0)) return '--'
  const minusSign = number < 0 ? '-' : ''
  const result = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  }).format(Math.abs(number))
  return minusSign + result + currency
}

export const formatAsPercent = (
  number,
  { maximumFractionDigits = 2, placeholder = '--', dashesForZero = true } = {}
) => {
  if (isNaN(number) || number === null || number === undefined || (dashesForZero && +number === 0)) return placeholder
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits,
  }).format(number / 100)
}

export const formatAsShortDate = (date, separator = '/', options = { fullYear: false }) => {
  if (date === null || date === undefined) return ''
  date = new Date(date)
  const day = getLastChars(date.getDate())
  const month = getLastChars(date.getMonth() + 1)
  const year = getLastChars(date.getFullYear(), options.fullYear ? 4 : 2)
  const result = [day, month, year].join(separator)
  return result
}

export const formatAsPhoneNumber = phone => {
  if (phone === null || phone === undefined || phone.length < 9) return ''
  const phoneLength = phone.length
  const end = phone.slice(phoneLength - 2, phoneLength)
  const mid = phone.slice(phoneLength - 4, phoneLength - 2)
  const start = phone.slice(phoneLength - 7, phoneLength - 4)
  const code = phone.slice(phoneLength - 9, phoneLength - 7)
  const phoneLengthWithoutCountry = phone.length - 9
  let area = ''
  if (phoneLengthWithoutCountry > 0) {
    area = phone.slice(0, phoneLengthWithoutCountry)
    if (!area.includes('+')) area = '+'.concat(area)
  }
  const formattedPhone = [area, code, start, mid, end].join(' ')
  return formattedPhone
}
