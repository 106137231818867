import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import MeatBallsMenu from '../../../../components/Common/MeatBallsMenu/MeatBallsMenu'
import ModalWindowImport from '../../../../components/ModalWindowImport/ModalWindowImport'
import {
  downloadLostPoliciesFile,
  downloadLostPoliciesFileLink,
  exportIrregularPoliciesFile,
  exportPoliciesFile,
  uploadLostPoliciesFile,
} from '../../../../http/requests'
import { policiesListType } from '../../../../types/tablesTypes'
import { getAllPolicies } from '../../actions/policiesActions'
import PoliciesFilterDropdown from '../PoliciesFilter/PoliciesFilterDropdown'
import CustomersPoliciesSearchInput from '../../../../components/CustomersPoliciesSearchInput/CustomersPoliciesSearchInput'
import './PoliciesFilterBar.css'
import PoliciesFilterDates from '../PoliciesFilterDates/PoliciesFilterDates'

const PoliciesFilterBar = ({
  currentListType,
  irregularFilter,
  updateIrregularFilter,
  signalsFilter,
  updateSignalsFilter,
  companiesFilter,
  updateCompaniesFilter,
  categoriesFilter,
  updateCategoriesFilter,
  searchTerm,
  setSearchTerm,
  clearFilters,
  datesFilter,
}) => {
  const [showImportModal, setShowImportModal] = useState(false)
  const [importModalError, setImportModalError] = useState(null)
  const [fileReadSuccess, setFileReadSuccess] = useState(false)
  const [importFiles, setImportFiles] = useState([])
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const { t } = useTranslation('policies')
  const dispatch = useDispatch()

  const filtersApplied =
    companiesFilter.some(c => c.checked) ||
    categoriesFilter.some(c => c.checked) ||
    irregularFilter.some(i => i.checked) ||
    signalsFilter.some(i => i.checked) ||
    datesFilter.some(d => d.selected) ||
    searchTerm.length > 0

  const importContextMenuItems = [
    {
      text: t('importLostPolicies.menuTitle'),
      clickHandler: () => setShowImportModal(true),
    },
  ]
  const exportIrregularContextMenuItems = [
    {
      text: t('exportIrregularPoliciesBtn'),
      clickHandler: async () => {
        const requestDto = {
          companies: companiesFilter.filter(c => c.checked).map(({ id }) => id),
          categories: categoriesFilter.filter(c => c.checked).map(({ id }) => id),
          irregularReasons: irregularFilter.filter(c => c.checked).map(({ id }) => id),
          signals: signalsFilter.filter(c => c.checked).map(({ id }) => id),
          months: datesFilter.filter(c => c.selected).map(({ date }) => date),
          searchText: searchTerm,
        }
        exportIrregularPoliciesFile(requestDto)
      },
    },
  ]

  const exportPolicesContextMenuItems = [
    {
      text: t('exportPoliciesBtn'),
      clickHandler: async () => {
        const requestDto = {
          companies: companiesFilter.filter(c => c.checked).map(({ id }) => id),
          categories: categoriesFilter.filter(c => c.checked).map(({ id }) => id),
          irregularReasons: [],
          signals: [],
          months: datesFilter.filter(c => c.selected).map(({ date }) => date),
          searchText: searchTerm,
          listType: currentListType,
        }
        exportPoliciesFile(requestDto)
      },
    },
  ]
  const closeModalHandler = () => {
    setShowImportModal(false)
  }

  const downloadTemplate = async () => {
    await downloadLostPoliciesFile()
  }

  const readFile = async files => {
    setFileReadSuccess(true)
    setImportFiles([files[0]])
  }

  const deleteFile = file => {
    setImportFiles(importFiles.filter(itm => itm.path !== file.path))
  }

  const submitImportedData = async () => {
    try {
      setImportModalError(null)
      setSubmitLoading(true)
      setSubmitSuccess(false)
      const {
        data: { hasError, errorMessage },
      } = await uploadLostPoliciesFile(importFiles[0])
      if (hasError) {
        setImportModalError(errorMessage)
        setImportFiles([])
        setFileReadSuccess(false)
      } else {
        setFileReadSuccess(true)
        setSubmitSuccess(true)
        dispatch(getAllPolicies())
      }
    } catch (error) {
      setImportModalError('Upload Error')
      setImportFiles([])
      setFileReadSuccess(false)
    } finally {
      setSubmitLoading(false)
    }
  }

  return (
    <div className='policies-filterbar'>
      <div className='policies-filterbar-search'>
        <CustomersPoliciesSearchInput
          placeholder={t('searchInput.placeholder')}
          searchValue={searchTerm}
          setSearchValue={setSearchTerm}
        />
      </div>
      <div className='policies-filterbar-filters'>
        <div className='customers-filterbar-import'>
          <MeatBallsMenu
            menuItems={[
              ...(currentListType !== policiesListType.irregular && currentListType !== policiesListType.signals
                ? exportPolicesContextMenuItems
                : []),
              ...(currentListType === policiesListType.lostData ? importContextMenuItems : []),
              ...(currentListType === policiesListType.irregular || currentListType === policiesListType.signals
                ? exportIrregularContextMenuItems
                : []),
            ]}
          />
        </div>
        {currentListType === policiesListType.irregular && (
          <PoliciesFilterDropdown
            title={t('irregFilter.dropdownTitle')}
            filterItems={irregularFilter}
            setChecked={updateIrregularFilter}
          />
        )}
        {currentListType === policiesListType.signals && (
          <PoliciesFilterDropdown
            title={t('irregFilter.dropdownTitle')}
            filterItems={signalsFilter}
            setChecked={updateSignalsFilter}
          />
        )}
        <PoliciesFilterDates />
        <PoliciesFilterDropdown
          title={t('companyFilter.dropdownTitle')}
          filterItems={companiesFilter}
          setChecked={updateCompaniesFilter}
        />
        <PoliciesFilterDropdown
          title={t('categoryFilter.dropdownTitle')}
          filterItems={categoriesFilter}
          setChecked={updateCategoriesFilter}
        />
        <div>
          <button onClick={clearFilters} className='polices-clear-filters' disabled={!filtersApplied}>
            {t('clearFilters')}
          </button>
        </div>
      </div>
      {showImportModal && (
        <ModalWindowImport
          closeModal={closeModalHandler}
          title={t('importLostPolicies.modalTitle')}
          heading={t('importLostPolicies.subtitle')}
          templateDownloadTitle={t('importLostPolicies.templateLink')}
          downloadTemplate={downloadTemplate}
          dndPlaceholder={t('importLostPolicies.dragNDropPlaceholder')}
          uploadBtnCaption={t('importLostPolicies.uploadBtn')}
          importBtnCaption={t('importLostPolicies.importBtn')}
          fileTypes={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
          errors={importModalError}
          setErrors={setImportModalError}
          readFiles={readFile}
          readSuccess={fileReadSuccess}
          setReadSucces={setFileReadSuccess}
          files={importFiles}
          deleteFiles={deleteFile}
          submit={submitImportedData}
          submitSuccess={submitSuccess}
          submitLoading={submitLoading}
        />
      )}
    </div>
  )
}

export default PoliciesFilterBar
