import $api from '..'
import { urlProfile } from '../../environment/urls'

export const getProfileConnectedAgenciesRequest = async () => await $api.get(`${urlProfile()}get-invites`)

export const cancelProfileConnectedAgenciesRequest = async inviteId =>
  await $api.delete(`${urlProfile()}cancel-association/${inviteId}`)

export const updateUserInfoRequest = async userInfo => await $api.put(`${urlProfile()}user-info`, userInfo)

export const getProfileNextPaymentRequest = async () => await $api.get(`${urlProfile()}next-payment`)

export const getProfileNextPaymentDetailsRequest = async () =>
  await $api.get(`${urlProfile()}next-payment-detailed-info`)

export const getProfilePaymentsHistoryRequest = async () => await $api.get(`${urlProfile()}payment-history`)
