import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { policyStatus } from '../../../types/policyStatusType'
import './PolicyStatusChip.scss'

const PolicyStatusChip = ({ status, shortName = false, styles }) => {
  const { i18n } = useTranslation()
  if (!policyStatus[status]) {
    return null
  }

  const { backgroundColor, color, text, textEn, textShort, textEnShort } = policyStatus[status]
  const chipContent = i18n.language === 'he' ? (shortName ? textShort : text) : shortName ? textEnShort : textEn
  return (
    <>
      {status && (
        <div className='policy-status-chip-container'>
          <div className={classNames('policy-status-chip')} style={{ backgroundColor, color, ...styles }}>
            {chipContent}
          </div>
        </div>
      )}
    </>
  )
}

export default PolicyStatusChip
