import InputCredentials from '../InputCredentials/InputCredentials'
import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import { CloseRounded } from '@mui/icons-material'
import './ProfileEditCompany.scss'

const ProfileEditCompany = ({ closeSidebar, company }) => {
  const { t } = useTranslation('profile')

  return (
    <div className='profile-sidebar-wrapper'>
      <div className='profile-sidebar'>
        <header>
          <div>
            <div>
              <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company.id} />
            </div>
            <p>{hebrew() ? company.name : company.nameEn}</p>
          </div>
          <button onClick={closeSidebar}>
            <CloseRounded />
          </button>
        </header>
        <main>
          <p className='profile-sidebar-title'>{t('editCompanyModal.modalTitle')}</p>
          <p className='profile-sidebar-subtitle'>{t('editCompanyModal.modalSubtitle')}</p>
          <InputCredentials company={company} />
        </main>
      </div>
    </div>
  )
}

export default ProfileEditCompany
