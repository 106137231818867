import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import { incomesDataTypes, incomesTotalsObject, incomesTotalsType } from '../../../../types/analyticsIncomesTypes'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import AgencyHouseLobbyChanges from '../../../agencyHouseLobby/components/AgencyHouseLobbyChanges/AgencyHouseLobbyChanges'
import AgencyHouseAnalyticsIncomesTotalsCard from './AgencyHouseAnalyticsIncomesTotalsCard'
import './AgencyHouseAnalyticsIncomesTotals.scss'

const AgencyHouseAnalyticsIncomesTotals = ({ dataType, periodViewType, totals, totalsLoading }) => {
  const { t } = useTranslation('agencyHouseLobby')
  const incomesDataType = incomesDataTypes.find(dt => dt.id === dataType)
  const totalsValues = totals
    .filter(
      t => incomesDataType.totals.includes(t.type) && incomesTotalsObject[t.type].periodViews.includes(periodViewType)
    )
    .map(t => ({
      ...incomesTotalsObject[t.type],
      value: totals.find(tv => tv.type === t.type).value,
      diff: totals.find(tv => tv.type === t.type).diff,
    }))
  //.filter(t => (dataType === requestDataTypes.commission ? t.type !== incomesTotalsType.commissionVAT : true))
  const financialDataType = dataType !== requestDataTypes.commissionRate
  const monthlyView = periodViewType === analyticsPeriodViewType.monthly

  const totalName = ({ name, nameEn, nameMonthlyView, nameEnMonthlyView }) => {
    if (monthlyView) {
      return hebrew() ? nameMonthlyView : nameEnMonthlyView
    }
    return hebrew() ? name : nameEn
  }

  const totalValue = c => (
    <>
      <h5>
        {c.value === null || c.value === undefined ? (
          '--'
        ) : financialDataType ? (
          <>
            <span>₪</span>
            {formatAsCurrency(c.value < 100000000 ? c.value : Math.round(c.value), {
              currency: '',
              fractionDigits: c.value < 100000000 ? 2 : 0,
            })}
          </>
        ) : (
          formatAsPercent(c.value, {
            ...(c.type === incomesTotalsType.commissionRateAccumulation && { maximumFractionDigits: 4 }),
          })
        )}
      </h5>
      {dataType === requestDataTypes.commission && c.type === incomesTotalsType.commissionMonthly && (
        <div className='commission-vat'>
          ({t('totals.inclVat')}:{' '}
          {formatAsCurrency(totals.find(t => t.type === incomesTotalsType.commissionVAT)?.value)})
        </div>
      )}
    </>
  )

  const totalDiff = c => (
    <AgencyHouseLobbyChanges
      value={c.diff}
      showValue={
        dataType !== requestDataTypes.accumulation || (dataType === requestDataTypes.accumulation && monthlyView)
      }
      showPercentSymbol={dataType === requestDataTypes.commissionRate}
      showCurrencySymbol={dataType !== requestDataTypes.commissionRate}
      {...(!monthlyView && { changesText: t('totals.prevPeriodChanges') })}
      {...(c.type === incomesTotalsType.commissionRateAccumulation && { maximumFractionDigits: 4 })}
    />
  )

  return (
    <div className='agency-house-analytics-incomes-totals'>
      {totalsValues.map(t => (
        <AgencyHouseAnalyticsIncomesTotalsCard
          key={t.name}
          name={totalName(t)}
          icon={t.icon}
          value={totalValue(t)}
          difference={totalDiff(t)}
          loading={totalsLoading}
        />
      ))}
    </div>
  )
}

export default AgencyHouseAnalyticsIncomesTotals
