import $api from '..'
import { urlCompany, urlReportRecords, urlCrawlerStatus, urlCrawlerActions, urlAgency } from '../../environment/urls'

export const getReportRecordsDatesRequest = async ({ agency } = {}) =>
  await $api.get(`${urlReportRecords(agency)}reports-dates`)

export const getReportRecordsStatusRequest = async ({ month, agency } = {}) =>
  await $api.get(`${urlReportRecords(agency)}reports-month-status/${month}`)

export const getCompanyReportRecordsMonthStatusRequest = async ({ id, month, agency } = {}) =>
  await $api.get(`${urlCompany(agency)}getCrawlerCompanyByMonth/${id}/${month}`)

export const startScanRequest = async ({ companyId, companyUsers }) =>
  await $api.post(`${urlCrawlerActions()}scan`, { companyId, companyUsers })

export const stopScanRequest = async ({ companyId, userId }) =>
  await $api.get(`${urlCrawlerStatus()}cancel/${companyId}/${userId}`)

export const getScanStatusRequest = async (companyId, userId) =>
  await $api.get(`${urlCrawlerStatus()}${companyId}/${userId}`)

export const sendScanSmsRequest = async ({ companyId, userId, sms }) =>
  await $api.get(`${urlCrawlerActions()}send-code/${companyId}/${userId}/${sms}`)

export const sendScanAnswerRequest = async ({ companyId, userId, answer }) =>
  await $api.get(`${urlCrawlerActions()}send-answer/${companyId}/${userId}/${answer}`)

export const toggleReportDisableRequest = async reportRecordIds =>
  await $api.put(`${urlReportRecords()}report-records-toggle`, reportRecordIds)

export const getScanHistoryRequest = async ({ agency } = {}) => await $api.get(`${urlCompany(agency)}get-records`)

export const uploadReportByIdRequest = async (reportRecordId, file) => {
  const formData = new FormData()
  formData.append('file', file, file.name)
  formData.append('reportRecordId', reportRecordId)
  return await $api.post(`${urlCrawlerActions()}UploadFileById`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const uploadPairedReportsByIdRequest = async (firstFile, secondFile, reportRecordIds) => {
  const formData = new FormData()
  formData.append('firstFile', firstFile, firstFile.name)
  formData.append('secondFile', secondFile, secondFile.name)
  reportRecordIds.forEach(item => formData.append('reportRecordIds[]', item))
  return await $api.post(`${urlCrawlerActions()}UploadPairedFilesById`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const removeCompanyRequest = async companyId => await $api.delete(`${urlAgency()}remove-company/${companyId}`)
