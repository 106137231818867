import axios from 'axios'
import { createHashHistory } from 'history'
import { agencyHouseRoutes, agencyRoutes, urlMaster, useCrossDomainCookies } from '../environment/urls'
import { cookieGetItemValue } from '../services/cookieStorgeService'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { logout } from '../features/login/actions/loginActions'
import store from '../app/store'
import { SHARED_SET_NO_PAYMENT } from '../types/actionTypesShared'
import { isRegularAgency } from '../features/login/reducers/loginSelectors'

const $api = axios.create({ withCredentials: true })
const history = createHashHistory()

$api.interceptors.request.use(config => {
  let url = config.url
  const page = window.location.hash.split('/')
  const currRoutePage = page[1]
  const agencyDbName = page[2]
  const bigAgencyDownloadPage =
    agencyHouseRoutes.download.split('/')[1] === currRoutePage && agencyDbName && !isRegularAgency()

  if (config.url.includes('{agency}')) {
    let agencyDnsName = cookieGetItemValue('agencyDnsName')
    if (!agencyDnsName) {
      store.dispatch(logout())
      return
      // cancelSource.cancel('No Agency Name Found')
      // store.dispatch(logout())
      // return
    }
    const currUrlParts = window.location.hash.split('/')
    const currPage = currUrlParts[1]
    if (currPage) {
      document.cookie = `page=${
        agencyRoutes[currPage === agencyRoutes.scan ? agencyRoutes.download : currPage] ?? currPage
      }${useCrossDomainCookies}`
    }
    let updatedUrl = url.replace('{agency}', bigAgencyDownloadPage ? agencyDbName : agencyDnsName)
    config.url = updatedUrl
  }
  config.headers.Authorization = `Bearer ${localStorage.getItem('AccessToken')}`
  return config
})

function getRefreshToken() {
  return localStorage.getItem('RefreshToken')
}

const masterUrl = urlMaster()
const refreshAuthLogic = failedRequest =>
  $api
    .post(`${masterUrl}account/refresh-token`, '"' + getRefreshToken() + '"', {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(tokenRefreshResponse => {
      //console.log('tokenRefreshResponse', tokenRefreshResponse);
      let AccessToken = tokenRefreshResponse.data.Data.AccessToken
      let RefreshToken = tokenRefreshResponse.data.Data.RefreshToken
      localStorage.setItem('AccessToken', AccessToken)
      localStorage.setItem('RefreshToken', RefreshToken)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + AccessToken
      return Promise.resolve()
    })
    .catch(err => {
      store.dispatch(logout())
      history.push('/')
    })
createAuthRefreshInterceptor($api, refreshAuthLogic)

$api.interceptors.response.use(
  response => {
    return response
  },
  err => {
    return new Promise((resolve, reject) => {
      // console.log('in Err interceptor responce')
      // console.log(err)
      // console.log(err.message)
      const originalReq = err.config
      if (err.message === 'Operation canceled due to new request.' || err?.constructor?.name === 'Cancel') {
        //console.log('ignore request cancelation err')
        return
      }
      if (err?.response?.status === 450) {
        console.log('getting 450 - NO PAYMENT')
        store.dispatch({ type: SHARED_SET_NO_PAYMENT, payload: true })
        history.push('/nopayment')
      }
      if (err?.response?.status === 451) {
        console.log('getting 451 - NO CARD')
        store.dispatch({ type: SHARED_SET_NO_PAYMENT, payload: true })
        history.push('/nopayment?nocard=true')
      }
      if (err?.response?.status === 460) {
        console.log('getting 460')
        history.push('/nosubscription')
      }
      if (err?.response?.status === 401 && err.config && !err.config._retry) {
        originalReq._retry = true
        store.dispatch(logout())
      } else reject(err)
    })
  }
)

export default $api

const cancelTokens = {}

export const getCancelToken = tokenKey => {
  if (typeof cancelTokens[tokenKey] != typeof undefined) {
    cancelTokens[tokenKey].cancel(`Operation ${tokenKey} was canceled due to new request.`)
  }
  cancelTokens[tokenKey] = axios.CancelToken.source()
  return { cancelToken: cancelTokens[tokenKey].token }
}
