import { NavLink } from 'react-router-dom'
import './Logo.scss'

const Logo = ({ light = false, link = '/dashboard' }) => {
  return (
    <div className={`logo`}>
      <div className={`${light ? 'light' : 'full'}`}>
        <NavLink to={`${link}`}>
          <img src={`./assets/Logo/Logo_${light ? 'light' : 'full'}.svg`} alt='AGent_logo' />
        </NavLink>
      </div>
    </div>
  )
}

export default Logo
