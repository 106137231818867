import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { numbersOnly } from '../../../../services/inputs'
import { Add, ArrowBack, ArrowForward } from '@mui/icons-material'
import { hebrew } from '../../../../i18n'
import { Slider } from '@mui/material'
import './PaymentCalc.scss'

const MIN_CUSTOMERS = 500
const MAX_CUSTOMERS = 40000

const offsetConfig = {
  84: {
    customerStartOffset: 20000,
    customersEndOffset: MAX_CUSTOMERS,
    percentStartOffset: 84,
    percentEndOffset: 100,
  },
  67: {
    customerStartOffset: 15000,
    customersEndOffset: 20000,
    percentStartOffset: 67,
    percentEndOffset: 84,
  },
  50: {
    customerStartOffset: 10000,
    customersEndOffset: 15000,
    percentStartOffset: 50,
    percentEndOffset: 67,
  },
  34: {
    customerStartOffset: 6000,
    customersEndOffset: 10000,
    percentStartOffset: 34,
    percentEndOffset: 50,
  },
  17: {
    customerStartOffset: 2000,
    customersEndOffset: 6000,
    percentStartOffset: 17,
    percentEndOffset: 34,
  },
  1: {
    customerStartOffset: MIN_CUSTOMERS,
    customersEndOffset: 2000,
    percentStartOffset: 1,
    percentEndOffset: 17,
  },
}

const marks = [
  {
    value: 1,
    label: '500',
  },
  {
    value: 17,
    label: '2,000',
  },
  {
    value: 34,
    label: '6,000',
  },
  {
    value: 50,
    label: '10,000',
  },
  {
    value: 67,
    label: '15,000',
  },
  {
    value: 84,
    label: '20,000',
  },
  {
    value: 100,
    label: '40,000 MAX',
  },
]

const price = {
  starter: 300,
  basic: 450,
}

const PaymentCalc = () => {
  const [customersCount, setCustomersCount] = useState(MIN_CUSTOMERS)
  const [customersPercentCount, setCustomersPercentCount] = useState(1)

  const { t } = useTranslation('common')

  const onChangeHandler = e => {
    numbersOnly(e)
    const {
      target: { value },
    } = e
    setCustomersCount(+value)
    let offsetKey
    let percents = 0
    if (value >= MAX_CUSTOMERS) percents = 100
    else {
      offsetKey =
        value > 20000 ? 84 : value > 15000 ? 67 : value > 10000 ? 50 : value > 6000 ? 34 : value > 2000 ? 17 : 1
      percents = getCountRelativeValue(
        value,
        offsetConfig[offsetKey].percentStartOffset,
        offsetConfig[offsetKey].percentEndOffset,
        offsetConfig[offsetKey].customerStartOffset,
        offsetConfig[offsetKey].customersEndOffset
      )
    }
    setCustomersPercentCount(percents)
  }

  const getCountRelativeValue = (
    percent,
    customerStartOffset,
    customersEndOffset,
    percentStartOffset,
    percentEndOffset
  ) => {
    const percentValue = percent - percentStartOffset
    const percentRange = percentEndOffset - percentStartOffset
    const countRange = customersEndOffset - customerStartOffset
    return customerStartOffset + Math.floor((percentValue * countRange) / percentRange)
  }

  const setCustomersCountByPercent = percent => {
    let offsetKey
    let count = 0
    if (percent === 100) count = MAX_CUSTOMERS
    else {
      offsetKey = percent > 84 ? 84 : percent > 67 ? 67 : percent > 50 ? 50 : percent > 34 ? 34 : percent > 17 ? 17 : 1
      count = getCountRelativeValue(
        percent,
        offsetConfig[offsetKey].customerStartOffset,
        offsetConfig[offsetKey].customersEndOffset,
        offsetConfig[offsetKey].percentStartOffset,
        offsetConfig[offsetKey].percentEndOffset
      )
    }
    setCustomersCount(count)
  }

  const getCustomersCost = () => {
    let cost = 0
    if (customersCount < 2000) cost = price.starter
    else if (customersCount < 15000) cost = price.basic
    else if (customersCount < MAX_CUSTOMERS) {
      const customersOver15 = customersCount - 15000
      cost = price.basic + customersOver15 * 0.08
    } else {
      return t('priceCalc.contactUs')
    }
    return (
      Intl.NumberFormat('he-IL', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        trailingZeroDisplay: 'stripIfInteger',
      }).format(cost) + '₪'
    )
  }

  return (
    <div className='payment-calc'>
      <div className='payment-calc-text'>
        <p>{t('priceCalc.title')}</p>
        <h5>{t('priceCalc.mainTitle')}</h5>
        <p>{t('priceCalc.desc')}</p>
        <p>{t('priceCalc.remark')}</p>
      </div>
      <div className='payment-calc-slider'>
        <Slider
          aria-label='customers'
          valueLabelDisplay='on'
          valueLabelFormat={() => new Intl.NumberFormat('he-IL').format(customersCount)}
          value={customersPercentCount}
          marks={marks}
          min={1}
          max={100}
          onChange={(event, newValue) => {
            setCustomersPercentCount(newValue)
            setCustomersCountByPercent(newValue)
          }}
        />
      </div>
      <div className='payment-calc-results'>
        <div className='labeled-container'>
          <label>{t('priceCalc.totalCustomers')}</label>
          <input
            className='value-container input'
            type='text'
            value={new Intl.NumberFormat('he-IL').format(customersCount)}
            onChange={onChangeHandler}
          />
        </div>
        <div className='svg-ico-container'>{hebrew() ? <ArrowBack /> : <ArrowForward />}</div>
        {customersCount > 15000 && customersCount < MAX_CUSTOMERS && (
          <>
            <div className='labeled-container'>
              <div>{t('priceCalc.price')}</div>
              <div className='value-container'>{price.basic + '₪'}</div>
            </div>
            <div className='svg-ico-container'>
              <Add />
            </div>
            <div className='labeled-container'>
              <div>{t('priceCalc.extraPercents')}</div>
              <div className='value-container'>{t('priceCalc.extraPercentsValue', { percents: 8 })}</div>
            </div>
            <div className='svg-ico-container'>=</div>
          </>
        )}
        <div className='labeled-container'>
          <div>{t('priceCalc.cost')}</div>
          <div className='value-container cost'>{getCustomersCost()}</div>
        </div>
      </div>
      <p className='payment-calc-results-remark'>{t('priceCalc.calcRemark')}</p>
    </div>
  )
}

export default PaymentCalc
