import { urlRegistration } from '../../../environment/urls'
import $api from '../../../http'
import { LANDING_EMAIL_FAIL, LANDING_EMAIL_REQUEST, LANDING_EMAIL_SUCCESS } from '../../../types/actionTypes'

const masterUrl = urlRegistration()

export const sendEmail = ({ fullName,
  mobilePhone,
  messageContent, }) => async dispatch => {
    dispatch({ type: LANDING_EMAIL_REQUEST })
    try {
      const {
        data: { data },
      } = await $api.post(`${masterUrl}agency/send-email`, {
        fullName,
        phone: mobilePhone,
        message: messageContent
      })

      dispatch({
        type: LANDING_EMAIL_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: LANDING_EMAIL_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }