import React, { useEffect, useState } from 'react'
import ModalSidebar from '../../../../components/Common/ModalSidebar/ModalSidebar'
import { useTranslation } from 'react-i18next'
import { deleteCustomersGroupCustomerRequest, getCustomersGroupRequest } from '../../../../http/requests'
import CustomersPoliciesSearchInput from '../../../../components/CustomersPoliciesSearchInput/CustomersPoliciesSearchInput'
import Loader from '../../../../components/Common/Loader/Loader'
import { formatAsCurrency } from '../../../../utils/formatAs'
import { useDispatch, useSelector } from 'react-redux'
import { hebrew } from '../../../../i18n'
import AddCustomerGroupModal from '../../../../components/AddCustomerGroupModal/AddCustomerGroupModal'
import NoData from '../../../../components/Common/NoData/NoData'
import CustomerGroupChart from '../../../../components/Charts/CustomerGroupChart/CustomerGroupChart'
import { userPreferenceType } from '../../../../types/agencyUserTypes'
import PrivateValue from '../../../../HOCs/PrivateValue'
import { getCustomerGroupTypes } from '../../../customer/actions/customerActions'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import './CustomersGroupDetailsSidbar.scss'

const CustomersGroupDetailsSidbar = ({
  closeSidebar,
  groupId,
  //groupTypes = [],
  onEditSuccess = () => {},
}) => {
  const { groupTypes } = useSelector(({ customer }) => customer)
  const [group, setGroup] = useState({ customers: [] })
  const [groupLoading, setGroupLoading] = useState(true)
  const [groupError, setGroupError] = useState(null)
  const [groupSilentLoading, setGroupSilentLoading] = useState(false)
  const [chartsLoading, setChartsLoading] = useState(false)
  const [customersLoading, setCustomersLoading] = useState([])
  const [showEditModal, setShowEditModal] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const [groupUpdated, setGroupUpdated] = useState(false)
  const { t } = useTranslation('customers')
  const { t: tCustomer } = useTranslation('customer')
  const dispatch = useDispatch()

  const groupTypeName = typeId => {
    const type = groupTypes.find(grt => grt.id === typeId)
    return hebrew() ? type?.name : type?.englishName
  }

  const groupName = group.name ?? t('customersGroupNamePlaceholder', { groupId: group.id })

  const showEditModalHandler = () => setShowEditModal(true)

  const filteredCustomers = group.customers.filter(c => c.name.toLowerCase().includes(searchTerm.toLowerCase()))

  const closeSidebarHanlder = () => {
    groupUpdated && onEditSuccess()
    closeSidebar()
  }

  const closeDisabled =
    groupLoading ||
    groupSilentLoading ||
    chartsLoading ||
    customersLoading.some(cId => group.customers.map(c => c.id).includes(cId))

  const fetchGroupDetails = async (setLoading = setGroupLoading) => {
    try {
      setLoading(true)
      setGroupError(null)
      const res = await getCustomersGroupRequest(groupId)
      if (!res) {
        closeSidebarHanlder()
      } else {
        setGroup(res)
        setCustomersLoading(prev => prev.filter(cId => res.customers.map(c => c.id).includes(cId)))
      }
      setLoading(false)
    } catch (error) {
      setGroupError('Error getting group')
      setLoading(false)
      console.log('Error getting group')
    }
  }

  const deleteGroupCustomer = async customerId => {
    try {
      setCustomersLoading(prev => [...prev, customerId])
      const { success } = await deleteCustomersGroupCustomerRequest(group.id, customerId)
      if (success) {
        setGroupUpdated(true)
        setDeleteSuccess(success)
      }
    } catch (error) {
      console.log('error deleting')
    }
  }

  useEffect(() => {
    fetchGroupDetails()
  }, [groupId])

  useEffect(() => {
    if (deleteSuccess) {
      setDeleteSuccess(false)
      if (group.customers.length > 1) {
        fetchGroupDetails(setChartsLoading)
      } else {
        closeSidebarHanlder()
      }
    }
  }, [groupId, deleteSuccess])

  useEffect(() => {
    dispatch(getCustomerGroupTypes())
  }, [dispatch])

  if (groupLoading || groupError) {
    return (
      <ModalSidebar closeSidebar={closeSidebarHanlder} closeDisabled={closeDisabled}>
        {groupLoading ? (
          <Loader />
        ) : (
          <div className='customers-group-details-sidebar-error'>
            <NoData text={groupError} />
            <ButtonRounded onClick={() => fetchGroupDetails()} disabled={groupLoading}>
              {t('tryAgain')}
            </ButtonRounded>
          </div>
        )}
      </ModalSidebar>
    )
  }

  return (
    <ModalSidebar closeSidebar={closeSidebarHanlder} loadingOverlay={groupSilentLoading} closeDisabled={closeDisabled}>
      <div className='customers-group-details-sidebar'>
        <div className='customers-group-details-sidebar-main'>
          <div className='customers-group-details-sidebar-header'>
            <div className='customers-group-details-sidebar-group-name-type'>
              <div className='customers-group-details-sidebar-group-type'>{groupTypeName(group.type)}</div>
              <div className='customers-group-details-sidebar-group-name'>
                <div>{groupName}</div>
                <button onClick={showEditModalHandler}>
                  <div>
                    <img src='./assets/edit-icon/1@2x.png' alt='edit' />
                  </div>
                </button>
              </div>
            </div>
            <div className='customers-group-details-sidebar-group-categories'>
              <button disabled>
                <div>
                  <img src='./assets/customer-groups-actions/examine-products/examine-products.png' alt='prd' />
                </div>
                <div>{t('groupDetailsSidebar.categoriesBtn')}</div>
              </button>
            </div>
          </div>
          <div className='customers-group-details-sidebar-charts'>
            {chartsLoading && (
              <div className='customers-group-details-sidebar-charts-loading-overlay'>
                <Loader />
              </div>
            )}
            <div className='customers-group-details-sidebar-chart'>
              <CustomerGroupChart
                title={tCustomer('charts.commissionLastMonth')}
                requiredPrefernce={userPreferenceType.comission}
                diff={group.totalCommission.difference}
                latest={group.totalCommission.latest}
                trend={group.totalCommission.trend}
                values={group.totalCommission.values}
                remark={tCustomer('charts.commissionRemark')}
                noDataText={tCustomer('charts.noData')}
              />
            </div>
            <div className='customers-group-details-sidebar-chart'>
              <CustomerGroupChart
                title={tCustomer('charts.premium')}
                requiredPrefernce={userPreferenceType.premium}
                diff={group.totalPremium.difference}
                latest={group.totalPremium.latest}
                trend={group.totalPremium.trend}
                values={group.totalPremium.values}
                remark={tCustomer('charts.premiumRemark')}
                noDataText={tCustomer('charts.noData')}
              />
            </div>
            <div className='customers-group-details-sidebar-chart'>
              <CustomerGroupChart
                title={tCustomer('charts.accumulation')}
                requiredPrefernce={userPreferenceType.accumulation}
                diff={group.totalAccumulation.difference}
                latest={group.totalAccumulation.latest}
                trend={group.totalAccumulation.trend}
                values={group.totalAccumulation.values}
                showChanges={true}
                noDataText={tCustomer('charts.noData')}
              />
            </div>
          </div>
        </div>
        <div className='customers-group-details-sidebar-actions'>
          <div className='customers-group-details-sidebar-search'>
            <CustomersPoliciesSearchInput
              searchValue={searchTerm}
              setSearchValue={setSearchTerm}
              placeholder={t('groupDetailsSidebar.searchCustomer')}
            />
          </div>
          <div className='customers-group-details-sidebar-add-member'>
            <button onClick={showEditModalHandler}>{t('groupDetailsSidebar.addMemberBtn')}</button>
          </div>
        </div>
        <div className='customers-group-details-sidebar-customers-list-container'>
          {filteredCustomers.length === 0 ? (
            <NoData text='Nothing found' />
          ) : (
            <>
              <div className='customers-group-details-sidebar-customers-list-header  customers-group-details-sidebar-customers-list-grid'>
                <div>{t('groupDetailsSidebar.customerName')}</div>
                <div>{/* {familyGroup && t('groupDetailsSidebar.rank')} */}</div>
                <div>{t('groupDetailsSidebar.commissionLastMonth')}</div>
                <div></div>
              </div>
              <div className='customers-group-details-sidebar-customers-list'>
                {filteredCustomers.map(c => (
                  <div
                    key={c.id}
                    className='customers-group-details-sidebar-customers-item  customers-group-details-sidebar-customers-list-grid'
                  >
                    {customersLoading.includes(c.id) && (
                      <div className='customers-group-details-sidebar-customers-item-loading-overlay'>
                        <Loader />
                      </div>
                    )}
                    <div>{c.name}</div>
                    <div>
                      {/* {familyGroup && (
                        <div className='customers-group-details-sidebar-customers-item-rank'>
                          {c.rank ? (
                            <>
                              {c.rank === customerRank.one && <img src='./assets/star-icon-yellow/star.png' alt='*' />}
                              {t('groupDetailsSidebar.rankValue', { rank: c.rank })}
                            </>
                          ) : (
                            'N/A'
                          )}
                        </div>
                      )} */}
                    </div>
                    <div style={{ direction: 'ltr', textAlign: 'end' }}>
                      <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
                        {formatAsCurrency(c.commission)}
                      </PrivateValue>
                    </div>
                    <div className='customers-group-details-sidebar-customers-item-actions'>
                      <div>
                        <button onClick={() => deleteGroupCustomer(c.id)}>
                          <img src='./assets/delete-icon/Delete.png' alt='dlt' />
                        </button>
                      </div>
                      <div>
                        <a href={`#/customers/${c.id}`}>
                          <img src='./assets/export/export.png' alt='cstm' />
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
      {showEditModal && (
        <AddCustomerGroupModal
          closeModal={() => setShowEditModal(false)}
          group={group}
          onEditSuccess={() => {
            setGroupUpdated(true)
            fetchGroupDetails(setGroupSilentLoading)
          }}
        />
      )}
    </ModalSidebar>
  )
}

export default CustomersGroupDetailsSidbar
