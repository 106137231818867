import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PoliciesDashboard from '../../features/policies/components/PoliciesDashboard/PoliciesDashboard'
import PoliciesFilterBar from '../../features/policies/components/PoliciesFilterBar/PoliciesFilterBar'
import PoliciesListContainer from '../../features/policies/components/PoliciesListContainer/PoliciesListContainer'
import PolicyDetails from '../../features/policy/components/PolicyDetails/PolicyDetails'
import NotifyDialog from '../../components/NotifyDialog/NotifyDialog'
import { irregularStatus } from '../../types/irregularStatusTypes'
import { sortBy, sortOrder } from '../../types/sortByTypes'
import {
  getAllPolicies,
  getPoliciesDatesFilter,
  getPoliciesFilters,
  getPoliciesTotals,
} from '../../features/policies/actions/policiesActions'
import { useHistory } from 'react-router'
import {
  POLICIES_DATES_FILTER_APPLY,
  POLICIES_FILTERS_CLEAR,
  POLICIES_IRREG_FILTER_APPLY,
  POLICIES_RESET,
  POLICIES_SIGNALS_FILTER_APPLY,
  POLICY_RESET,
  POLICY_UNCHECK_ALL,
} from '../../types/actionTypes'
import { policiesListType } from '../../types/tablesTypes'
import { selectPolicy } from '../../features/policy/actions/policyActions'
import { getCategories } from '../../features/shared/actions/sharedActions'
import { isMobile } from 'react-device-detect'
import NoMobileLayout from '../../components/NoMobileLayout/NoMobileLayout'
import AgentGroupsBanner from '../../components/AgentGroupsBanner/AgentGroupsBanner'
import './PoliciesScreen.scss'
import { printDatesInRange } from '../../utils/date'
import BackToBigAgency from '../../features/shared/components/BackToBigAgency/BackToBigAgency'

const PoliciesScreen = () => {
  const { t, i18n } = useTranslation('common')
  const { t: tPolicies } = useTranslation('policies')
  const history = useHistory()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(history.location.search)
  const currentListType = policiesListType[searchParams.get('type')] ?? policiesListType.all
  const setCurrentListType = listType => {
    if (currentListType !== listType) {
      searchParams.set('type', listType)
      history.push(`/policies?${searchParams.toString()}`)
      dispatch({ type: POLICY_UNCHECK_ALL })
    }
  }
  const totalPolices = useSelector(state => state.policies).allPolicies
  const allPoliciesLoading = useSelector(state => state.policies).allPoliciesLoading
  const companies = useSelector(state => state.policies).companiesFilter
  const categories = useSelector(state => state.policies).categoriesFilter
  const datesFilter = useSelector(state => state.policies).datesFilter
  const irregularReasonFilter = useSelector(state => state.policies).irregularReasonFilter
  const signalsFilter = useSelector(state => state.policies).signalsFilter
  const totals = useSelector(state => state.policies).totals
  const selectedPolicy = useSelector(state => state.policy).selectedPolicy
  const checkedPolicies = useSelector(state => state.policy).checkedPolicies
  const policy = useSelector(state => state.policies).allPolicies.find(policy => policy.id === selectedPolicy)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)
  const [page, setPage] = useState(1)
  const [companiesFilter, setCompaniesFilter] = useState([])
  const [categoriesFilter, setCategoriesFilter] = useState([])
  const [notifications, setNotifications] = useState([])
  const [sort, setSort] = useState({
    by: sortBy.commission,
    order: sortOrder.desc,
  })
  const [searchTerm, setSearchTerm] = useState(searchParams.get('search') ?? '')

  const addNotification = notification => {
    setNotifications(prev => {
      let hasNotification = prev.map(n => n.id).includes(notification.id)
      if (hasNotification) {
        return prev.map(n => (n.id === notification.id ? notification : n))
      } else prev.push(notification)
      return [...prev]
    })
  }
  const removeNotification = id => {
    setNotifications(notifications.filter(n => n.id !== id))
  }

  const updateIrregularFilter = (reasonId, checked) => {
    dispatch({ type: POLICIES_IRREG_FILTER_APPLY, payload: { reasonId, checked } })
  }

  const updateSignalsFilter = (signalId, checked) => {
    dispatch({ type: POLICIES_SIGNALS_FILTER_APPLY, payload: { signalId, checked } })
  }

  const updateCompaniesFilter = (companyId, checked) => {
    setCompaniesFilter(prev =>
      prev
        .map(company => (company.id === companyId ? { ...company, checked } : company))
        .map((item, _, arr) =>
          arr.every(itm => itm.checked) || arr.every(itm => !itm.checked) ? { ...item, checked: false } : item
        )
    )
  }

  const updateCategoriesFilter = (categoryId, checked) => {
    setCategoriesFilter(prev =>
      prev
        .map(category => (category.id === categoryId ? { ...category, checked } : category))
        .map((item, _, arr) =>
          arr.every(itm => itm.checked) || arr.every(itm => !itm.checked) ? { ...item, checked: false } : item
        )
    )
  }

  const searchHandler = value => {
    setSearchTerm(value)
    if (value === '') {
      searchParams.delete('search')
    } else {
      searchParams.set('search', value)
    }
    history.replace({ search: searchParams.toString() })
  }

  const clearFilters = () => {
    setCategoriesFilter(prev => prev.map(c => ({ ...c, checked: false })))
    setCompaniesFilter(prev => prev.map(c => ({ ...c, checked: false })))
    setSearchTerm('')
    searchParams.delete('search')
    history.replace({ search: searchParams.toString() })
    dispatch({ type: POLICIES_FILTERS_CLEAR })
    setPage(1)
    dispatch(getAllPolicies())
  }

  const policyMainFilter = policy => {
    const hasNoCompaniesFiltersApplied =
      companiesFilter.length === 0 ||
      companiesFilter.every(itm => itm.checked) ||
      companiesFilter.every(itm => !itm.checked)
    const selectedCompanies = companiesFilter.filter(itm => itm.checked).map(itm => itm.id)
    const hasNoCategoriesFiltersApplied =
      categoriesFilter.length === 0 ||
      categoriesFilter.every(itm => itm.checked) ||
      categoriesFilter.every(itm => !itm.checked)
    const selectedCategories = categoriesFilter.filter(itm => itm.checked).map(itm => itm.id)
    if (!hasNoCompaniesFiltersApplied && !selectedCompanies.includes(policy.companyId)) return
    if (!hasNoCategoriesFiltersApplied && !selectedCategories.includes(policy.categoryId)) return
    return (
      policy.policyNumber.toUpperCase().includes(searchTerm.trim().toUpperCase()) ||
      policy.clientName?.toUpperCase().includes(searchTerm.trim().toUpperCase())
    )
  }

  const setSortHandler = sortBy => {
    if (sort.by === sortBy) {
      setSort(prev => {
        return {
          ...prev,
          order: prev.order !== sortOrder.asc ? sortOrder.asc : sortOrder.desc,
        }
      })
    } else {
      setSort({ by: sortBy, order: sortOrder.desc })
    }
  }

  const comparator = (a, b) => {
    if (sort.by === sortBy.policyNumber) {
      a = a.policyNumber
      b = b.policyNumber
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.commission) {
      a = a.commission
      b = b.commission
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.commissionRate) {
      a = a.commissionRate
      b = b.commissionRate
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.commissionRatePremium) {
      a = a.comissionPremiumRate
      b = b.comissionPremiumRate
      if (a === b) {
        return 0
      }
      if (a === null) {
        return 1
      }
      if (b === null) {
        return -1
      }
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.commissionRateAccumulation) {
      a = a.comissionAccumulationRate
      b = b.comissionAccumulationRate
      if (a === b) {
        return 0
      }
      if (a === null) {
        return 1
      }
      if (b === null) {
        return -1
      }
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.premium) {
      a = a.premium
      b = b.premium
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.accumulation) {
      a = a.accumulation
      b = b.accumulation
      if (a < b) {
        return sort.order === sortOrder.asc ? -1 : 1
      }
      if (a > b) {
        return sort.order === sortOrder.asc ? 1 : -1
      }
      return 0
    } else if (sort.by === sortBy.customerName || sort.by === sortBy.company || sort.by === sortBy.category) {
      let key
      switch (sort.by) {
        case sortBy.customerName:
          key = 'clientName'
          break
        case sortBy.company:
          key = i18n.language === 'he' ? 'companyName' : 'companyNameEn'
          break
        case sortBy.category:
          key = i18n.language === 'he' ? 'categoryName' : 'categoryNameEn'
          break
        default:
          break
      }
      const x = a[key]
      const y = b[key]
      if (x === y) {
        return a.policyNumber < b.policyNumber ? -1 : a.policyNumber > b.policyNumber ? 1 : 0
      } else if (x === null) {
        return 1
      } else if (y === null) {
        return -1
      }
      return sort.order === sortOrder.asc
        ? x.normalize().localeCompare(y.normalize())
        : y.normalize().localeCompare(x.normalize())
    }
  }

  const policies = listType => {
    switch (listType) {
      case policiesListType.all:
        return {
          items: totalPolices.filter(policyMainFilter).sort(comparator),
          length: totalPolices.length,
        }
      case policiesListType.new:
        return {
          items: totalPolices.filter(policy => policy.isNewPolicy && policyMainFilter(policy)).sort(comparator),
          length: totalPolices.filter(policy => policy.isNewPolicy).length,
        }
      case policiesListType.inactive:
        return {
          items: totalPolices.filter(policy => !policy.active && policyMainFilter(policy)).sort(comparator),
          length: totalPolices.filter(policy => !policy.active).length,
        }
      case policiesListType.lostData:
        return {
          items: totalPolices
            .filter(policy => !policy.clientId && policy.active && policyMainFilter(policy))
            .sort(comparator),
          length: totalPolices.filter(policy => !policy.clientId && policy.active).length,
        }
      case policiesListType.irregular:
      case policiesListType.signals:
        return {
          items: totalPolices
            .filter(
              policy =>
                policy.irregularStatus === irregularStatus.irregular && policy.active && policyMainFilter(policy)
            )
            .sort(comparator),
          length: totalPolices.filter(policy => policy.irregularStatus === irregularStatus.irregular && policy.active)
            .length,
        }
      default:
        return {
          items: totalPolices.filter(policyMainFilter).sort(comparator),
          length: totalPolices.length,
        }
    }
  }

  useEffect(() => {
    dispatch(getPoliciesFilters())
    dispatch(getCategories())
    dispatch(getPoliciesDatesFilter())
    dispatch(getPoliciesTotals())
    // dispatch(getAllPolicies())
  }, [dispatch])

  useEffect(() => {
    setPage(1)
    dispatch(getAllPolicies())
  }, [dispatch, selectedGroupsIds])

  useEffect(() => {
    if (datesFilter.filter(d => d.selected).length === 0) return
    setPage(1)
    dispatch(getAllPolicies())
  }, [dispatch, datesFilter])

  useEffect(() => {
    setCompaniesFilter(
      companies.map(company => {
        return { ...company, checked: false }
      })
    )
    setCategoriesFilter(
      categories.map(category => {
        return { ...category, checked: false }
      })
    )
  }, [companies, categories])

  useEffect(() => {
    return () => {
      dispatch({ type: POLICIES_RESET })
      dispatch({ type: POLICY_RESET })
    }
  }, [dispatch])

  if (isMobile) {
    return <NoMobileLayout pageName={t('sidebar.policies')} />
  }

  return (
    <div className='policies-screen'>
      <div className='policies-screen-banners'>
        <BackToBigAgency />
        <AgentGroupsBanner />
      </div>
      <PoliciesDashboard currentListType={currentListType} setCurrentListType={setCurrentListType} policies={totals} />
      <div className='policies-main-container'>
        <PoliciesFilterBar
          currentListType={currentListType}
          irregularFilter={irregularReasonFilter}
          updateIrregularFilter={updateIrregularFilter}
          signalsFilter={signalsFilter}
          updateSignalsFilter={updateSignalsFilter}
          companiesFilter={companiesFilter}
          updateCompaniesFilter={updateCompaniesFilter}
          categoriesFilter={categoriesFilter}
          updateCategoriesFilter={updateCategoriesFilter}
          searchTerm={searchTerm}
          setSearchTerm={searchHandler}
          clearFilters={clearFilters}
          datesFilter={datesFilter}
        />
        {datesFilter.filter(d => d.selected).length > 0 && (
          <div className='policies-applied-dates-filter-container'>
            <p>{tPolicies('datesFilter.applied')}</p>
            <div className='policies-applied-dates-filter'>
              <p>
                {printDatesInRange(
                  datesFilter.filter(d => d.selected).map(({ date }) => date),
                  i18n.language
                )}
              </p>
              <button
                onClick={() => {
                  dispatch({ type: POLICIES_DATES_FILTER_APPLY, payload: [] })
                  setPage(1)
                  dispatch(getAllPolicies())
                }}
              >
                <img src='./assets/close-icon-alt-sm/Close.png' alt='x' />
              </button>
            </div>
          </div>
        )}
        <PoliciesListContainer
          page={page}
          setPage={setPage}
          currentListType={currentListType}
          items={policies(currentListType).items}
          sort={sort}
          setSortHandler={setSortHandler}
          irregularFilter={irregularReasonFilter}
          signalsFilter={signalsFilter}
          loading={allPoliciesLoading}
          selectedPolicy={selectedPolicy}
          checkedPolicies={checkedPolicies}
        />
        {policy && (
          <PolicyDetails policy={policy} closeDetails={() => dispatch(selectPolicy())} notify={addNotification} />
        )}
        {notifications.length > 0 && (
          <div className='notify-modals-wrapper'>
            <div className='notify-modals-container'>
              {notifications.map(notify => (
                <NotifyDialog
                  text={i18n.language === 'he' ? notify.text : notify.textEn}
                  closeNotify={() => removeNotification(notify.id)}
                  closeNotifyTimout={3500}
                  timeoutStep={500}
                  key={notify.id + notify.text}
                  confirm={() => notify.cancelAction && notify.cancelAction()}
                  onCloseAction={() => notify.action()}
                  confirmText={i18n.language === 'he' ? notify.actionText : notify.actionTextEn}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default PoliciesScreen
