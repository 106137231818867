import { userPreferenceType } from '../types/agencyUserTypes'

export function getItemFromLocalStorage(itemName) {
  const token = localStorage.getItem(itemName) || ''

  return token
}

export function setItemToLocalStorage(itemName, itemValue) {
  localStorage.setItem(itemName, itemValue)
}

export function removeItemFromLocalStorage(itemName) {
  localStorage.removeItem(itemName)
}

export function getPreferencesFromLocalStorage() {
  let preferences = localStorage.getItem('preferences')
  if (!preferences) return []
  preferences = preferences.split(';')
  preferences = preferences.filter(p => userPreferenceType.hasOwnProperty(p))
  return preferences
}

export const getObjectFromLocalStorage = key => JSON.parse(window.localStorage.getItem(key))

export const setObjectToLocalStorage = (object, key) => {
  const objectKey = key ?? object.constructor.name + '-' + new Date().getTime()
  localStorage.setItem(objectKey, JSON.stringify(object))
}
