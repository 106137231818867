import { cn } from '../../../utils/stylesUtils'
import Loader from '../Loader/Loader'
import './LoadingOverlay.scss'

const LoadingOverlay = ({ styles, fullscreen = false }) => {
  return (
    <div className={cn('loading-overlay', { fullscreen })} style={{ ...styles }}>
      <Loader />
    </div>
  )
}

export default LoadingOverlay
