import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from '../../../../components/Common/Button/Button'
import { logout } from '../../actions/loginActions'
import { useIdleTimer } from 'react-idle-timer'
import './LogoutConfirm.css'
import { CUSTOMERS_RESET } from '../../../../types/actionTypes'
import { createPortal } from 'react-dom'
import { sendConfirmLogoutRequest } from '../../../../http/requests/agencyLoginRequest'
import { getItemFromLocalStorage } from '../../../../services/localStorageService'

const LogoutConfirm = ({ signoutHandler, closeModal, autoLogout = false, autoLogoutTime = 10000 }) => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const dispatch = useDispatch()
  const appId = getItemFromLocalStorage('applicationId')
  const handleOnIdle = event => {
    if (autoLogout) {
      singOut()
    }
  }
  useIdleTimer({
    //timeout: 1000 * 5, //5 secs
    timeout: autoLogoutTime,
    onIdle: handleOnIdle,
  })
  const singOut = () => {
    closeModal()
    dispatch(logout())
    dispatch({ type: CUSTOMERS_RESET })
    sendConfirmLogoutRequest({
      appId: appId
    })
    history.push('/login')
  }
  return createPortal(
    <div className='logout-confirm-wrapper' onClick={closeModal}>
      <div className='logout-confirm-container' onClick={e => e.stopPropagation()}>
        <div onClick={closeModal} className='logout-confirm-close-icon'>
          <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
        </div>
        <div className='logout-confirm-content'>
          <div className='logout-confirm-heading'>{t('logoutConfirm.title')}</div>
          <div className='logout-confirm-buttons-group'>
            <div className='logout-confirm-submit-btn'>
              <Button caption={t('logoutConfirm.yes')} onClick={singOut} />
            </div>
            <div className='logout-confirm-cancel-btn'>
              <Button caption={t('logoutConfirm.no')} onClick={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default LogoutConfirm
