import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import LinedTitle from '../../../../components/Common/LinedTitle/LinedTitle'
import NoData from '../../../../components/Common/NoData/NoData'
import { getMonthName } from '../../../../services/getMonthName'
import { insuranceFieldType } from '../../../../types/insuranceField'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { getCommissionRateByField } from '../../../../utils/getCommissionRate'
import classNames from 'classnames'
import './CustomerPolicyPayments.scss'
import PrivateValue from '../../../../HOCs/PrivateValue'
import { userPreferenceType } from '../../../../types/agencyUserTypes'

const CustomerPolicyPayments = ({ policy, monthAgg }) => {
  const { t, i18n } = useTranslation('customer')
  const [paymentsVisibility, setPaymentsVisibility] = useState(
    monthAgg.reduce((previousValue, currentValue) => {
      return { ...previousValue, [currentValue.date]: false }
    }, {})
  )

  const elementary = policy.fieldId !== insuranceFieldType.lifeFinance

  const showPayments = paymentDate => {
    setPaymentsVisibility(prev => {
      return {
        ...prev,
        [paymentDate]: !prev[paymentDate],
      }
    })
  }

  if (monthAgg.filter(agg => agg.commission || agg.premium || agg.accumulation).length === 0)
    return <NoData text={t('policies.noPayments')} />

  return (
    <>
      <div className='customer-policy-payments-list-header'>
        <div className='customer-policy-payments-title-container'>
          <LinedTitle count={monthAgg.length} title={t('tableHeadings.paymentsDetails')} />
        </div>
        <div
          className={classNames('customer-policy-payments-grid heading', {
            elementary,
          })}
        >
          <div></div>
          <div>{t('tableHeadings.date')}</div>
          <div>{t('tableHeadings.premium')}</div>
          <div>{t('tableHeadings.commission')}</div>
          {!elementary && <div>{t('tableHeadings.accumulation')}</div>}
          <div className='commission-rate'>
            <div className='commission-rate-heading'>{t('tableHeadings.commissionRate')}</div>
            {!elementary && (
              <div className='commission-split'>
                <div>{t('tableHeadings.premium')}</div>
                <div>{t('tableHeadings.accumulation')}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {monthAgg
        .filter(agg => agg.commission || agg.premium || agg.accumulation)
        .map(agg => {
          const year = new Date(agg.date).getFullYear()
          const month = getMonthName(agg.date, i18n.language)
          return (
            <div key={agg.date.toString() + agg.id} className='payment-item'>
              <div
                className={classNames('customer-policy-payments-grid item-total', {
                  elementary,
                })}
              >
                <div>
                  {agg.payments.length > 0 && (
                    <img
                      src={
                        paymentsVisibility[agg.date] ? './assets/minus-icon/Close.png' : './assets/plus-icon/Add.png'
                      }
                      alt='add'
                      className='img-icon'
                      onClick={() => showPayments(agg.date)}
                    />
                  )}
                </div>
                <div>
                  {month} {year}
                </div>
                <PrivateValue requiredPrefernces={[userPreferenceType.premium]}>
                  <div dir='ltr' style={{ textAlign: 'right' }}>
                    {formatAsCurrency(agg.premium)}
                  </div>
                </PrivateValue>
                <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
                  <div dir='ltr' style={{ textAlign: 'right' }}>
                    {formatAsCurrency(agg.commission)}
                  </div>
                </PrivateValue>
                {!elementary && (
                  <PrivateValue requiredPrefernces={[userPreferenceType.accumulation]}>
                    <div dir='ltr' style={{ textAlign: 'right' }}>
                      {formatAsCurrency(agg.accumulation)}
                    </div>
                  </PrivateValue>
                )}
                <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
                  <div dir='ltr' style={{ textAlign: 'right' }} className='commission-rate'>
                    {!elementary ? (
                      <div className='commission-split'>
                        <div>{formatAsPercent(agg.comissionAccumulationRate)}</div>
                        <div>{formatAsPercent(agg.comissionPremiumRate)}</div>
                      </div>
                    ) : (
                      <>
                        <div>{formatAsPercent(agg.comissionPremiumRate)}</div>
                      </>
                    )}
                  </div>
                </PrivateValue>
              </div>
              {paymentsVisibility[agg.date] &&
                agg.payments
                  .filter(pmnt => pmnt.commission || pmnt.premium || pmnt.accumulation)
                  .map(pmnt => {
                    return (
                      <div
                        key={pmnt.id}
                        className={classNames('customer-policy-payments-grid item-specific', {
                          elementary,
                        })}
                      >
                        <div></div>
                        <div>{pmnt.policyType}</div>
                        <PrivateValue requiredPrefernces={[userPreferenceType.premium]}>
                          <div dir='ltr' style={{ textAlign: 'right' }}>
                            {formatAsCurrency(pmnt.premium)}
                          </div>
                        </PrivateValue>
                        <PrivateValue requiredPrefernces={[userPreferenceType.comission]}>
                          <div dir='ltr' style={{ textAlign: 'right' }}>
                            {formatAsCurrency(pmnt.commission)}
                          </div>
                        </PrivateValue>
                        {!elementary && (
                          <PrivateValue requiredPrefernces={[userPreferenceType.accumulation]}>
                            <div dir='ltr' style={{ textAlign: 'right' }}>
                              {formatAsCurrency(pmnt.accumulation)}
                            </div>
                          </PrivateValue>
                        )}
                        <PrivateValue requiredPrefernces={[userPreferenceType.comissionRate]}>
                          <div dir='ltr' style={{ textAlign: 'right' }} className='commission-rate'>
                            {!elementary ? (
                              <div className='commission-split'>
                                <div>{formatAsPercent(pmnt.comissionAccumulationRate)}</div>
                                <div>{formatAsPercent(pmnt.comissionPremiumRate)}</div>
                              </div>
                            ) : (
                              <>
                                <div>{formatAsPercent(pmnt.comissionPremiumRate)}</div>
                              </>
                            )}
                          </div>
                        </PrivateValue>
                      </div>
                    )
                  })}
            </div>
          )
        })}
    </>
  )
}

export default CustomerPolicyPayments
