import {
  analyticsIncomesList,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
} from '../../../../types/analyticsIncomesTypes'
import { hebrew } from '../../../../i18n'
import TabsNavbar from '../../../../components/TabsNavbar/TabsNavbar'
import { InsertChart, TableChart } from '@mui/icons-material'
import classNames from 'classnames'
import { isRegularAgency } from '../../../login/reducers/loginSelectors'
import AgencyHouseAnalyticsPoliciesTable from '../AgencyHouseAnalyticsPoliciesTable/AgencyHouseAnalyticsPoliciesTable'
import AgencyHouseAnalyticsPoliciesChartsContainer from '../AgencyHouseAnalyticsPoliciesChartsContainer/AgencyHouseAnalyticsPoliciesChartsContainer'
import './AgencyHouseAnalyticsPoliciesListContainer.scss'

const AgencyHouseAnalyticsPoliciesListContainer = ({
  list,
  listLoading,
  listType,
  setListType,
  listViewType,
  setListViewType,
  periodViewType,
  dataType,
  totals,
  selectItem,
  selectedItem,
}) => {
  const ListComponent =
    listViewType === analyticsIncomesListViewType.table
      ? AgencyHouseAnalyticsPoliciesTable
      : AgencyHouseAnalyticsPoliciesChartsContainer

  return (
    <div className='agency-house-analytics-policies-list-container'>
      <TabsNavbar
        items={Object.values(analyticsIncomesList)
          .filter(l => (isRegularAgency() ? l.type !== analyticsIncomesListType.agents : true))
          .map(t => ({
            ...t,
            id: t.type,
            text: hebrew() ? t.name : t.nameEn,
          }))}
        currentItem={listType}
        setCurrentItem={setListType}
      />
      <div className='agency-house-analytics-policies-list-view-selector'>
        <button
          onClick={() => setListViewType(analyticsIncomesListViewType.chart)}
          className={classNames({ selected: listViewType === analyticsIncomesListViewType.chart })}
        >
          <InsertChart />
        </button>
        <button
          onClick={() => setListViewType(analyticsIncomesListViewType.table)}
          className={classNames({ selected: listViewType === analyticsIncomesListViewType.table })}
        >
          <TableChart />
        </button>
      </div>
      <ListComponent
        listType={listType}
        periodViewType={periodViewType}
        list={list}
        listLoading={listLoading}
        dataType={dataType}
        totals={totals}
        selectItem={selectItem}
        selectedItem={selectedItem}
      />
    </div>
  )
}

export default AgencyHouseAnalyticsPoliciesListContainer
