import axios from 'axios'
import $api, { getCancelToken } from '.'
import {
  urlAgency,
  urlAgentGroups,
  urlCompany,
  urlCrawlerActions,
  urlCustomer,
  urlCustomerGroups,
  urlCustomers,
  urlMaster,
  urlPolicy,
  urlProfile,
  urlRegistration,
  urlReportRecords,
  urlTreatment,
  urlWorkstation,
} from '../environment/urls'
import { policiesListTypeMapToApi } from '../types/tablesTypes'
const registrationUrl = urlRegistration()
const agencyUrl = urlAgency()
const policyUrl = urlPolicy()
const treatmentUrl = urlTreatment()
const masterUrl = urlMaster()
const profileUrl = urlProfile()
const workstationUrl = urlWorkstation()
const agentGroupsUrl = urlAgentGroups()
const customersUrl = urlCustomers()
const customerUrl = urlCustomer()
const customerGroupsUrl = urlCustomerGroups()

let cancelTasksCustomersRequest
let cancelTasksPoliciesRequest
let cancelWorkstationFiltersRequest
let cancelAllTasksRequest
let cancelFilterCustomersRequest

export const requestRegisterSms = async phone =>
  await $api.post(`${registrationUrl}account/requestRegisterSmsCode`, '"' + phone + '"', {
    headers: { 'Content-Type': 'application/json' },
  })

export const sendRegisterSms = async (phone, code) =>
  await $api.post(`${registrationUrl}account/confirmRegisterSmsCode`, {
    phone,
    code,
  })

export const getAgencies = async userId => await $api.get(`${registrationUrl}agency/agencies/${userId}`)

export const getInsuranceFields = async () => await $api.get(`${registrationUrl}company/insuranceFields`)

export const getInsuranceFieldsCompanies = async (fields = []) =>
  await $api.post(`${registrationUrl}company/insuranceFieldsWithComapnies`, fields)

export const registerAgency = async ({
  phoneNumber,
  idNumber,
  customerName,
  agencyName,
  agencyIdNumber,
  agentEmail,
  value,
  isEmail,
}) =>
  await $api.post(`${registrationUrl}agency/createAgency`, {
    phoneNumber,
    idNumber,
    customerName,
    agencyName,
    agencyIdNumber,
    agentEmail,
    value,
    isEmail,
  })

export const registerBigAgency = async ({ phoneNumber, agencyName, agencyIdNumber, agentEmail, value, isEmail }) =>
  await $api.post(`${registrationUrl}agency/createBigAgency`, {
    phoneNumber,
    agencyName,
    agencyIdNumber,
    agentEmail,
    value,
    isEmail,
  })

export const getProfileInvitationCompanies = async (agency = '{agency}') =>
  await $api.get(`${urlProfile(agency)}invitation-companies`)

export const getSubscriptions = async () => await $api.get(`${masterUrl}account/subscriptions`)

export const getCurrentSubscription = async () => await $api.get(`${profileUrl}current-subscription`)

export const addAgencyCompanies = async companies => await $api.post(`${agencyUrl}add-companies`, companies)

export const getPaymentDetails = async () => await $api.get(`${masterUrl}payment/details`)

export const createPayment = async (subscriptionTypeId, isYearSubscription) =>
  await $api.post(`${masterUrl}payment/create`, { subscriptionTypeId, isYearSubscription })

export const updatePaymentSubscription = async ({
  processId,
  processToken,
  subscriptionTypeId,
  isYearSubscription,
  subscriptionCompanyId,
}) =>
  await $api.post(`${masterUrl}payment/update/${+processId}/${processToken}`, {
    subscriptionTypeId,
    isYearSubscription,
    subscriptionCompanyId,
  })

export const cancelSubscription = async () => await $api.put(`${masterUrl}account/cancel-subscription`)

export const renewSubscription = async () => await $api.put(`${masterUrl}account/renew-subscription`)

export const getPaymentResult = async ({ processId, processToken }) =>
  await $api.post(`${masterUrl}payment/process/${+processId}/${processToken}`)

export const updateNotificationsSettings = async () =>
  await $api.get(`${masterUrl}account/disable-enable-notifications`)

export const getTasksRequest = async ({
  users = [],
  taskTypes = [],
  statuses = [],
  categories = [],
  from,
  to,
} = {}) => {
  if (typeof cancelAllTasksRequest != typeof undefined) {
    cancelAllTasksRequest.cancel('Operation canceled due to new request.')
  }
  cancelAllTasksRequest = axios.CancelToken.source()
  return await $api.post(
    `${workstationUrl}get-tasks`,
    {
      users,
      taskTypes,
      statuses,
      categories,
      from: from && new Date(Date.UTC(from.getFullYear(), from.getMonth(), from.getDate(), 0, 0, 0)),
      to: to && new Date(Date.UTC(to.getFullYear(), to.getMonth(), to.getDate(), 23, 59, 59)),
    },
    {
      cancelToken: cancelAllTasksRequest.token,
    }
  )
}

export const getTasksFiltersRequest = async () => {
  if (typeof cancelWorkstationFiltersRequest != typeof undefined) {
    cancelWorkstationFiltersRequest.cancel('Operation canceled due to new request.')
  }
  cancelWorkstationFiltersRequest = axios.CancelToken.source()
  return await $api.get(`${workstationUrl}get-filters`, {
    cancelToken: cancelWorkstationFiltersRequest.token,
  })
}

export const getTasksPoliciesRequest = async policyNumberCustomerName => {
  if (typeof cancelTasksPoliciesRequest != typeof undefined) {
    cancelTasksPoliciesRequest.cancel('Operation canceled due to new request.')
  }
  cancelTasksPoliciesRequest = axios.CancelToken.source()
  return await $api.get(`${workstationUrl}get-policy/${policyNumberCustomerName}`, {
    cancelToken: cancelTasksPoliciesRequest.token,
  })
}

export const getTasksCustomersRequest = async customerName => {
  if (typeof cancelTasksCustomersRequest != typeof undefined) {
    cancelTasksCustomersRequest.cancel('Operation canceled due to new request.')
  }
  cancelTasksCustomersRequest = axios.CancelToken.source()
  return await $api.get(`${workstationUrl}get-customer/${customerName}`, {
    cancelToken: cancelTasksCustomersRequest.token,
  })
}

export const setTasksStatusRequest = async ({ ids, note, status }) => {
  const {
    data: {
      data: { success },
    },
  } = await $api.put(`${treatmentUrl}update-batch`, {
    ids,
    note,
    status,
  })
  return success
}

export const setTasksAgentRequest = async ({ ids, agentId }) => {
  const {
    data: {
      data: { success },
    },
  } = await $api.post(`${treatmentUrl}update-treatments-responsible-user`, {
    ids,
    userId: agentId,
  })
  return success
}

export const addTaskRequest = async ({ agentId, policyId, irregularityId, customerId, type, note }) => {
  const {
    data: {
      data: { success },
    },
  } = await $api.put(`${treatmentUrl}add`, { agentId, policyId, irregularityId, customerId, type: +type, note })
  return success
}

export const downloadLostPoliciesFile = () => {
  $api
    .get(`${policyUrl}export-empty-clientId`, { responseType: 'blob' })
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      console.log(url)
      link.href = url
      link.download = 'lost_polices.xlsx'
      link.click()
    })
    .catch(response => {
      console.error('Could not Download the Excel report from the backend.', response)
    })
}

export const exportIrregularPoliciesFile = ({
  companies,
  categories,
  irregularReasons,
  signals,
  months,
  searchText,
}) => {
  $api
    .post(
      `${policyUrl}export-irregular-policies`,
      { companies, categories, irregularReasons, signals, months, searchText },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'irregluar_polices.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export the irregular report from the backend.', response)
    })
}

export const exportPoliciesFile = ({ companies, categories, irregularReasons, months, searchText, listType }) => {
  $api
    .post(
      `${policyUrl}export-policies`,
      { companies, categories, irregularReasons, months, searchText, listType: policiesListTypeMapToApi[listType] },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'Policies.xlsx'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Export the policies from the backend.', response)
    })
}

export const uploadLostPoliciesFile = async file => {
  const formData = new FormData()
  formData.append('policyFile', file)
  formData.append('fileName', 'policyFile')
  return await $api.post(`${policyUrl}import-with-clientId`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

export const filterCustomersRequest = async (searchText = '', currentPage = 0, pageSize = 25) => {
  if (typeof cancelFilterCustomersRequest != typeof undefined) {
    cancelFilterCustomersRequest.cancel('Operation canceled due to new request.')
  }
  //Save the cancel token for the current request
  cancelFilterCustomersRequest = axios.CancelToken.source()
  const {
    data: { data },
  } = await $api.post(
    `${customersUrl}filter-clients`,
    {
      searchText,
      currentPage,
      pageSize,
    },
    { cancelToken: cancelFilterCustomersRequest.token }
  )
  return data
}

export const getCustomerGroupsRequest = async id => {
  const {
    data: { data },
  } = await $api.get(`${customerGroupsUrl}get-client-groups/${id}`)
  return data
}

export const addCustomersGroupRequest = async ({ groupName, groupType, selectedCustomers, ownerCustomerId }) => {
  const { data } = await $api.post(`${customerGroupsUrl}add-customer-group`, {
    groupName,
    groupType,
    SelectedClients: selectedCustomers,
    ownerCustomerId,
  })
  return data
}

export const editCustomersGroupRequest = async ({ id, groupName, groupType, selectedCustomers, ownerCustomerId }) => {
  const { data } = await $api.post(`${customerGroupsUrl}update-customer-group`, {
    id,
    groupName,
    groupType,
    SelectedClients: selectedCustomers,
    ownerCustomerId,
  })
  return data
}

export const getCustomersGroupsRequest = async ({
  categories = [],
  companies = [],
  groupTypes = [],
  sortBy,
  descending,
  pageSize = 25,
  page = 1,
  collapsedGroups = [],
  name = '',
  totalElements,
  calculateByGroup,
}) => {
  const {
    data: { data },
  } = await $api.post(
    `${customerGroupsUrl}get-groups`,
    {
      categories,
      companies,
      groupTypes,
      pageSize,
      page,
      descending,
      sortBy,
      collapsedGroups: collapsedGroups.map(gr => gr.toString()),
      name,
      totalElements,
      calculateByGroup,
    },
    getCancelToken('getCustomersGroupsRequest')
  )
  return data
}

export const getCustomersGroupRequest = async id => {
  const {
    data: { data },
  } = await $api.get(`${customerGroupsUrl}get-group/${id}`)
  return data
}

export const deleteCustomersGroupRequest = async id => {
  const {
    data: { data },
  } = await $api.get(`${customerGroupsUrl}delete-customer-group/${id}`)
  return data
}

export const deleteCustomersGroupCustomerRequest = async (groupId, customerId) => {
  const {
    data: { data },
  } = await $api.get(`${customerGroupsUrl}remove-client-from-group/${groupId}/${customerId}`)
  return data
}

export const getAgentNumbersRequest = async () => await $api.get(`${agentGroupsUrl}agents`)
export const removeAgentNumberRequest = async agentId =>
  await $api.put(`${urlCrawlerActions()}deactivate-agent/${agentId}`)
export const restoreAgentNumberRequest = async agentId =>
  await $api.put(`${urlCrawlerActions()}activate-agent/${agentId}`)

export const getAllAgentGroupsRequest = async () => await $api.get(`${agentGroupsUrl}agent-groups`)

export const getAgentGroupsRequest = async () => await $api.get(`${agentGroupsUrl}user-agent-groups`)

export const addAgentGroupRequest = async ({ name, agentIds }) =>
  await $api.post(`${agentGroupsUrl}add-group`, { name, agents: agentIds })

export const removeGroupRequest = async id => await $api.get(`${agentGroupsUrl}remove-group/${id}`)

export const updateGroupRequest = async group => await $api.post(`${agentGroupsUrl}update-group`, group)

export const removeGroupAgentRequest = async (groupId, agentId) =>
  await $api.post(`${agentGroupsUrl}remove-agent-from-group/${agentId}/${groupId}`)

export const policesTotalsRequest = async () => await $api.get(`${policyUrl}totals`)

export const policesDatesFilterRequest = async () =>
  await $api.post(`${policyUrl}get-dates-for-filter`, {
    companies: [],
    categories: [],
    searchText: '',
    months: [],
  })

export const setReportsPasswordRequest = async (reportsPasswords = []) =>
  await $api.post(`${agencyUrl}company-email-passwords`, reportsPasswords)

export const getReportErrorScreenshotRequest = async ({ reportRecordId, agency = '{agency}' }) =>
  await $api.get(`${urlCrawlerActions(agency)}error-screenshot/${reportRecordId}`)

export const downloadReportRequest = async ({ reportRecordId, agency = '{agency}' }) =>
  await $api
    .get(`${urlReportRecords(agency)}export-report-file/${reportRecordId}`, { responseType: 'blob' })
    .then(response => {
      const blob = response.data
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = 'Report.zip'
      link.click()
      link.remove()
    })
    .catch(response => {
      console.error('Could not Download the Excel report from the backend.', response)
    })
