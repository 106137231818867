import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import useOutsideClick from '../../../hooks/useOutsideClick'
import InputSearch from '../InputSearch/InputSearch'
import Loader from '../Loader/Loader'
import CheckMark from '../Marks/CheckMark/CheckMark'
import './ModalWithSelection.scss'

const ModalWithSelection = ({
  items,
  currentItem,
  closeModal = () => {},
  onClickHandler = () => {},
  loading,
  selectSuccess,
  showSearch = false,
  searchPlaceholder,
}) => {
  const [selectedItem, setSelectedItem] = useState(currentItem)
  const [filteredItems, setFilteredItems] = useState(items)
  const ref = useRef()

  const selectItem = itemId => {
    setSelectedItem(items.find(item => item.id === itemId))
    onClickHandler(itemId)
  }

  const filterItems = text => {
    setFilteredItems(items.filter(itm => itm.value.toLowerCase().includes(text.toLowerCase())))
  }

  useEffect(() => {
    if (selectSuccess) {
      closeModal()
    }
  }, [selectSuccess, currentItem, closeModal])

  useOutsideClick(ref, () => {
    if (!loading) closeModal()
  })

  return (
    <div className='modal-selection' ref={ref}>
      {showSearch && <InputSearch placeholder={searchPlaceholder} searchHandler={filterItems} />}
      <div className='modal-selection-items'>
        {filteredItems.map(itm => (
          <div
            key={itm.id}
            className={classNames('modal-selection-item', { selected: itm.id === selectedItem?.id })}
            {...(itm.id !== selectedItem?.id && {
              onClick: () => selectItem(itm.id),
            })}
          >
            <div>{itm.value}</div>
            {itm.id === selectedItem?.id && (
              <div>
                <CheckMark green={true} />
              </div>
            )}
          </div>
        ))}
      </div>
      {loading && (
        <div className='modal-selection-loader'>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default ModalWithSelection
