import { CloseOutlined } from '@mui/icons-material'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import { useTranslation } from 'react-i18next'
import { scanStatusType } from '../../../../types/scanStatus'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import { useForm } from 'react-hook-form'
import { useRef } from 'react'
import { hebrew } from '../../../../i18n'
import { companyAuth, companyAuthType } from '../../../../types/companyAuthType'
import { cn } from '../../../../utils/stylesUtils'
import './ScanCompanyPopupSms.scss'

const ScanCompanyPopupSms = ({
  sendSmsHandler,
  scanStatus,
  company,
  closePopup,
  authType = companyAuthType.all,
  requestError,
  sendError,
  requestLoadingStatus,
  sendLoadingStatus,
  user,
}) => {
  const { t, i18n } = useTranslation('scan')
  const smsCode = ''
  const incorrectCode = scanStatus === scanStatusType.IncorrectSMSCode
  const unexpectedPage = scanStatus === scanStatusType.UnexpectedPage
  const waitingTimeout = scanStatus === scanStatusType.WaitingSmsTimeOut

  const inputsLength = company.smsLettersCount

  const { register, handleSubmit, errors, reset, watch } = useForm({
    defaultValues: [...Array(inputsLength)].reduce(
      (a, v, i) => ({
        ...a,
        [`digit${i + 1}`]: smsCode.length > 0 ? smsCode[i] : v,
      }),
      {}
    ),
  })

  const refs = useRef([])
  const watchAllFields = watch()

  const currentAuth = hebrew() ? companyAuth[authType].name : companyAuth[authType].nameEn

  const submitHandler = data => {
    const smsCode = Object.values(data).join('')
    sendSmsHandler(user.id, smsCode)
  }

  const numbersOnly = index => e => {
    e.target.value = e.target.value.replace(/\D/g, '')
    if (e.target.value.length !== 0 && refs.current[index + 1] !== undefined) {
      refs.current[index + 1].focus()
    }
  }

  const keyDownHandler = index => e => {
    const { key } = e
    if (key === 'Backspace') {
      if (e.target.value.length === 0 && refs.current[index - 1] !== undefined) {
        refs.current[index - 1].focus()
      }
      return
    }
    if (key === 'ArrowLeft') {
      if (refs.current[index - 1] !== undefined) {
        refs.current[index - 1].focus()
      }
      return
    }
    if (key === 'ArrowRight') {
      if (refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
    if (key === 'Delete') {
      if (e.target.value.length === 0 && refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
    if (e.target.value.length > 0) {
      if (refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
  }

  const hasEmptyFields = () => {
    return Object.values(watchAllFields).some(field => field === '') || Object.keys(watchAllFields).length === 0
  }

  return (
    <ModalWindow renderPortal showCloseIcon={false} loadingOverlay={sendLoadingStatus}>
      <div className='scan-company-popup-sms-error'>
        <header>
          <div>
            <p>{user?.name}</p>
            <p>{t('credentials.waitingSmsPopupHedaing')}</p>
          </div>
          <button onClick={closePopup}>
            <CloseOutlined />
          </button>
        </header>
        <p>{t('credentials.waitingSmsInputsHedaing')}</p>
        <div className='input-sms-form-container'>
          <form action='' autoComplete='off' onSubmit={handleSubmit(submitHandler)}>
            <div
              className={cn('input-sms-inputs-container', {
                'error-code': incorrectCode,
              })}
              dir='ltr'
            >
              {[...Array(inputsLength)].map((_, i) => (
                <input
                  disabled={incorrectCode}
                  key={`digit${i + 1}`}
                  type='text'
                  name={`digit${i + 1}`}
                  id={`digit${i + 1}`}
                  placeholder={smsCode.length > 0 ? smsCode[i] : '*'}
                  maxLength='1'
                  onInput={numbersOnly(i)}
                  onKeyDown={keyDownHandler(i)}
                  ref={e => {
                    register(e, {
                      required: !waitingTimeout,
                      message: 'required',
                    })
                    refs.current[i] = e
                  }}
                />
              ))}
              {(incorrectCode && (
                <div className='input-sms-form-error'>{t('mainContainer.smsStage.incorrectCode')}</div>
              )) ||
                (unexpectedPage && (
                  <div className='input-sms-form-error'>
                    <span>{t('mainContainer.smsStage.unexpectedPage')}</span>
                    <span>
                      {t('mainContainer.credentials.proceedToCompany', {
                        companyName: i18n.language === 'he' ? company.name : company.nameEn,
                      })}
                      <a href={company.website} target='_blank' rel='noopener noreferrer'>
                        {t('mainContainer.credentials.clickHere')}
                      </a>
                    </span>
                  </div>
                )) ||
                (waitingTimeout && (
                  <div className='input-sms-form-error'>{t('mainContainer.smsStage.waitingTimeout')}</div>
                )) ||
                (Object.keys(errors).length !== 0 && (
                  <div className='input-sms-form-error'>All feilds are required</div>
                )) ||
                (requestError && <div className='input-sms-form-error'>Server error</div>) ||
                (sendError && (
                  <div className='input-sms-form-error'>
                    {Object.keys(sendError).length !== 0 ? sendError : 'server error'}
                  </div>
                ))}
            </div>
            <div className='input-sms-button-container'>
              <ButtonRounded
                disabled={
                  (hasEmptyFields() && !incorrectCode && !waitingTimeout) ||
                  (Object.keys(errors).length !== 0 && !waitingTimeout) ||
                  requestLoadingStatus ||
                  sendLoadingStatus
                }
                typeSubmit={true}
              >
                {t('credentials.sendSmsBtn')}
              </ButtonRounded>
            </div>
          </form>
        </div>
      </div>
    </ModalWindow>
  )
}

export default ScanCompanyPopupSms
