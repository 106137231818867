import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import './ProfileCompanyItem.scss'
import { CheckBox, CheckBoxOutlineBlankOutlined, DeleteTwoTone, EditTwoTone } from '@mui/icons-material'
import { hebrew } from '../../../../i18n'
import { companyConnectionType } from '../../../../types/companies'

const ProfileCompanyItem = ({ company, removeCompany, editCompany, selectCompany, selected }) => {
  const { t, i18n } = useTranslation('profile')
  return (
    <>
      <div className={classNames('profile-company-item profile-company-item-grid', { selected })}>
        {/* <button onClick={selectCompany} className={classNames({ selected })}>
          {selected ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </button> */}
        <div className='profile-company-info'>
          <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company.id} />
          <p>{hebrew() ? company.name : company.nameEn}</p>
        </div>
        <p className='profile-company-fields'>
          {company.fields.map(f => (hebrew() ? f.name : f.engilshName)).join(' • ')}
        </p>
        <div className='profile-company-site' title={company.website}>
          <a href={company.website} target='_blank' rel='noopener noreferrer'>
            {company.website.replace('https://', '').replace('http://', '').replace('www.', '').split('/')[0]}
          </a>
        </div>
        <div className='profile-company-actions'>
          {company.connectionType === companyConnectionType.email && (
            <button onClick={editCompany}>
              <EditTwoTone />
            </button>
          )}
          <button onClick={removeCompany}>
            <DeleteTwoTone />
          </button>
        </div>
      </div>
      {false && (
        <div className='profile-company-card'>
          <div className='profile-company-card-header'>
            <div className='profile-company-logo'>
              <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company.id} />
            </div>
            <div className='profile-company-card-header-icons'>
              <div
                className={classNames('profile-company-card-header-icon', {
                  active: false,
                })}
                onClick={() => editCompany(company.id)}
              >
                <div className='icon-tooltip'>{t('companiesCard.editCompanyTooltip')}</div>
                <div className='icon-background-hover'></div>
                <img src='./assets/edit-icon/1.png' alt='edt' className='img-icon' />
              </div>

              <div
                className={classNames('profile-company-card-header-icon', {
                  active: false,
                })}
                onClick={() => {
                  removeCompany(company)
                }}
              >
                <div className='icon-tooltip'>{t('companiesCard.deleteCompanyTooltip')}</div>
                <div className='icon-background-hover'></div>
                <img src='./assets/delete-icon-sm/Delete.png' alt='dlt' className='img-icon' />
              </div>
            </div>
          </div>
          <div className='profile-company-card-body'>
            <div className='profile-company-name'>{i18n.language === 'he' ? company.name : company.nameEn}</div>
            <div className='profile-company-fields'>
              {company.fields
                .map(field => {
                  if (i18n.language === 'he') return field.name
                  return field.engilshName
                })
                .join(', ')}
            </div>
          </div>
          <div className='profile-company-card-footer'>
            <div className='profile-company-email' title={company.website}>
              <a href={company.website} target='_blank' rel='noopener noreferrer'>
                {company.website.replace('https://', '').replace('http://', '').replace('www.', '').split('/')[0]}
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ProfileCompanyItem
