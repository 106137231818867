import { useTranslation } from 'react-i18next'
import Button from '../../../../components/Common/Button/Button'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import './CategoriesConfirmModal.scss'

const CategoriesConfirmModal = ({
  closeModal,
  closeOnOutsideClick = false,
  categories,
  policies,
  newCategoryId,
  updatePoliciesCategory,
  loading,
}) => {
  const { t, i18n } = useTranslation('customer')
  const currCategory =
    policies.length > 1
      ? i18n.language === 'he'
        ? 'קשורים'
        : 'related'
      : i18n.language === 'he'
      ? policies[0].categoryName
      : policies[0].categoryNameEn
  const newCategory =
    i18n.language === 'he'
      ? categories.find(c => c.id === newCategoryId)?.name
      : categories.find(c => c.id === newCategoryId)?.englishName
  return (
    <ModalWindow
      closeModal={() => {
        if (!loading) {
          closeModal()
        }
      }}
      closeOnOutsideClick={closeOnOutsideClick}
    >
      <div className='modal-category-wrapper'>
        <div className='modal-category-title'>{t('changeCategoryModal.title')}</div>
        <div className='modal-category-text'>
          {t('changeCategoryModal.text', {
            currCategory,
            newCategory,
          })}
        </div>
        <div className='modal-category-btns'>
          <Button
            caption={t('changeCategoryModal.btnAll')}
            onClick={() =>
              updatePoliciesCategory(
                policies.map(p => p.id),
                newCategoryId,
                true
              )
            }
            disabled={loading}
          />
          <Button
            caption={t(`changeCategoryModal.btn${policies.length > 1 ? 'Selected' : 'Single'}`)}
            onClick={() =>
              updatePoliciesCategory(
                policies.map(p => p.id),
                newCategoryId,
                false
              )
            }
            disabled={loading}
          />
        </div>
      </div>
    </ModalWindow>
  )
}

export default CategoriesConfirmModal
