import { useEffect, useRef } from 'react'
import Logo from '../Logo/Logo'
import Navbar from './Navbar/Navbar'
import Extras from './Extras/Extras'
import './Sidebar.scss'
import { isMobile } from 'react-device-detect'
import { hideSidebar, sidebarIsVisible } from '../../utils/sidebar'
import { useLocation } from 'react-router'
import AgentGroupsSelector from '../AgentGroupsSelector/AgentGroupsSelector'

const Sidebar = () => {
  const { pathname } = useLocation()
  const ref = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      if (isMobile && sidebarIsVisible()) {
        if (ref.current && !ref.current.contains(event.target)) {
          hideSidebar()
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      hideSidebar()
    }
  }, [ref, pathname])

  return (
    <div className='sidebar' ref={ref}>
      <div>
        <Logo light={true} />
        <AgentGroupsSelector />
      </div>
      <Navbar />
      <Extras />
    </div>
  )
}

export default Sidebar
