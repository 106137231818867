import { useRef, useEffect } from 'react'
import classNames from 'classnames'
import './TwoFactorInput.scss'

export const twoFactorStep = {
  phoneNumber: 1,
  code: 2,
}

const TwoFactorInput = ({
  step = twoFactorStep.phoneNumber,
  getCodeBtnCaption = 'Get Code',
  sendCodeBtnCaption = 'Send Code',
  phoneNumber = '',
  setPhoneNumber = () => {},
  phoneNumberPlaceholder = '+972 00-0000000',
  codeLength = 6,
  code = [],
  setCode = () => {},
  onSubmit,
  submitBtnDisabled = false,
  inputDisabled = false,
  error = null,
  inputType = 'tel',
}) => {
  const submitBtnCaption = step === twoFactorStep.phoneNumber ? getCodeBtnCaption : sendCodeBtnCaption

  const refs = useRef([])

  const numbersOnly = index => e => {
    let {
      target: { value },
    } = e
    setCode(code.map((c, i) => (i === index ? value : c)))
    if (value.length !== 0 && refs.current[index + 1] !== undefined) {
      refs.current[index + 1].focus()
    }
  }

  const keyDownHandler = index => e => {
    const { key } = e
    if (key === 'Backspace') {
      if (e.target.value.length === 0 && refs.current[index - 1] !== undefined) {
        refs.current[index - 1].focus()
      }
      return
    }
    if (e.target.value.length > 0) {
      if (refs.current[index + 1] !== undefined) {
        refs.current[index + 1].focus()
      }
      return
    }
  }

  const renderInputs = () => {
    switch (step) {
      case twoFactorStep.phoneNumber:
      default:
        return (
          <input
            type={inputType}
            placeholder={phoneNumberPlaceholder}
            value={phoneNumber ?? ''}
            onChange={setPhoneNumber}
            name='phoneNumber'
            id='phoneNumber'
            size={17}
            disabled={inputDisabled}
            autoFocus
          />
        )
      case twoFactorStep.code:
        return [...Array(codeLength)].map((_, i) => (
          <input
            size={1}
            className='input-otp'
            key={`digit${i + 1}`}
            type='text'
            name={`digit${i + 1}`}
            id={`digit${i + 1}`}
            placeholder='*'
            maxLength='1'
            onChange={numbersOnly(i)}
            onKeyDown={keyDownHandler(i)}
            value={code[i] ?? ''}
            ref={e => (refs.current[i] = e)}
            disabled={inputDisabled}
            autoComplete='off'
            inputMode='numeric'
          />
        ))
    }
  }

  useEffect(() => {
    if (!inputDisabled && step === twoFactorStep.code && code.every(c => c === '')) {
      refs.current[0].focus()
    }
  }, [code, step, inputDisabled])

  return (
    <div className={classNames('two-factor-inputs-container', { 'has-error': error })}>
      <div className='two-factor-inputs-group'>{renderInputs()}</div>
      <button onClick={onSubmit} disabled={submitBtnDisabled}>
        {submitBtnCaption}
      </button>
    </div>
  )
}

export default TwoFactorInput
