export const policyTableType = {
  payments: 'payments',
  history: 'history',
}

export const customerTableType = {
  policies: 'policies',
  externalPayments: 'externalPayments',
  irregular: 'irregular',
  inactive: 'inactive',
  portfolioHistory: 'portfolioHistory',
  salesPotential: 'salesPotential',
}

export const policiesListType = {
  all: 'all',
  new: 'new',
  tracking: 'tracking',
  lostData: 'lostData',
  irregular: 'irregular',
  inactive: 'inactive',
  signals: 'signals',
  active: 'active',
}

export const policiesListTypeMapToApi = {
  [policiesListType.all]: 1,
  [policiesListType.active]: 2,
  [policiesListType.new]: 3,
  [policiesListType.lostData]: 4,
  [policiesListType.inactive]: 5,
  [policiesListType.irregular]: 6,
}

export const customersListType = {
  total: 'total',
  rating: 'rating',
  ratingRed: 'rating-red',
  ratingOrange: 'rating-orange',
  ratingGreen: 'rating-green',
  distribution: 'distribution',
  irregular: 'irregular',
  inactive: 'inactive',
  groups: 'groups',
}

export const workstationTableType = {
  tasksWaiting: 'tasksWaiting',
  tasksHandled: 'tasksHandled',
}
