import { urlAnalytics } from '../../environment/urls'
import $api, { getCancelToken } from '..'

export const getAgencyAnalyticsDatesRequest = async () =>
  await $api.get(`${urlAnalytics()}get-regular-dates-filter-values`)

export const getAgencyAnalyticsTotalsRequest = async ({
  timePeriods = [],
  dataType,
  fromNewPolicies = false,
  periodType,
  companies = [],
  categories = [],
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-totals-values`,
    { timePeriods, dataType, fromNewPolicies, periodType, companies, categories, calculateByFamilyGroups },
    getCancelToken('getAgencyAnalyticsTotalsRequest')
  )

export const getAgencyAnalyticsListRequest = async ({
  timePeriods = [],
  dataType,
  entityType,
  fromNewPolicies = false,
  isChartView,
  periodType,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-analytics-table-data`,
    {
      timePeriods,
      dataType,
      periodType,
      entityType,
      fromNewPolicies,
      isChartView,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsListRequest')
  )

export const getAgencyAnalyticsDetailsRequest = async ({
  timePeriods = [],
  dataType,
  mainEntityType,
  mainEntityId,
  topLevelEntityType,
  fromNewPolicies = false,
  isChartView,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-analytics-sidecard-data`,
    {
      timePeriods,
      dataType,
      filteringEntityType: mainEntityType,
      filteringEntityId: mainEntityId,
      topLevelEntityType: topLevelEntityType,
      fromNewPolicies,
      isChartView,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsDetailsRequest')
  )

export const getAgencyAnalyticsPeriodDetailsRequest = async ({
  period,
  dataType,
  entityTypeOrder = [],
  fromNewPolicies = false,
  isChartView,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-sidecard-data-by-period`,
    {
      period,
      dataType,
      entityTypeOrder,
      fromNewPolicies,
      isChartView,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsPeriodDetailsRequest')
  )

export const getAgencyAnalyticsPoliciesTotalsRequest = async ({
  timePeriods = [],
  fromNewPolicies = false,
  periodType,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-policies-totals-values`,
    { timePeriods, fromNewPolicies, periodType, categories, companies, calculateByFamilyGroups },
    getCancelToken('getAgencyAnalyticsPoliciesTotalsRequest')
  )

export const getAgencyAnalyticsPoliciesListRequest = async ({
  timePeriods = [],
  dataType,
  entityType,
  fromNewPolicies = false,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-analytics-policies-table-data`,
    {
      timePeriods,
      entityType,
      fromNewPolicies,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsPoliciesListRequest')
  )

export const getAgencyAnalyticsPoliciesDetailsRequest = async ({
  timePeriods = [],
  mainEntityType,
  mainEntityId,
  topLevelEntityType,
  fromNewPolicies = false,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-analytics-polices-sidecard-data`,
    {
      timePeriods,
      filteringEntityType: mainEntityType,
      filteringEntityId: mainEntityId,
      topLevelEntityType: topLevelEntityType,
      fromNewPolicies,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsPoliciesDetailsRequest')
  )

export const getAgencyAnalyticsPolicesPeriodDetailsRequest = async ({
  period,
  dataType,
  entityTypeOrder = [],
  fromNewPolicies = false,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-polices-sidecard-data-by-period`,
    {
      period,
      dataType,
      entityTypeOrder,
      fromNewPolicies,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsPolicesPeriodDetailsRequest')
  )

export const getAgencyAnalyticsCustomersListRequest = async ({
  timePeriods = [],
  entityType,
  isChartView,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-analytics-customers-table-data`,
    {
      timePeriods,
      entityType,
      isChartView,
      calculateByFamilyGroups,
      categories,
      companies,
    },
    getCancelToken('getAgencyAnalyticsCustomersListRequest')
  )

export const getAgencyAnalyticsCustomersDetailsRequest = async ({
  timePeriods = [],
  mainEntityType,
  mainEntityId,
  topLevelEntityType,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-regular-analytics-customers-sidecard-data`,
    {
      timePeriods,
      filteringEntityType: mainEntityType,
      filteringEntityId: mainEntityId,
      topLevelEntityType: topLevelEntityType,
      calculateByFamilyGroups,
      categories,
      companies,
    },
    getCancelToken('getAgencyAnalyticsCustomersDetailsRequest')
  )

export const getAgencyAnalyticsCustomersPeriodDetailsRequest = async ({
  period,
  dataType,
  entityTypeOrder = [],
  fromNewPolicies = false,
  categories,
  companies,
  calculateByFamilyGroups,
}) =>
  await $api.post(
    `${urlAnalytics()}get-customers-sidecard-data-by-period`,
    {
      period,
      dataType,
      entityTypeOrder,
      fromNewPolicies,
      categories,
      companies,
      calculateByFamilyGroups,
    },
    getCancelToken('getAgencyAnalyticsCustomersPeriodDetailsRequest')
  )

export const getAgencyAnalyticsCustomersRatingRequest = async () =>
  await $api.get(
    `${urlAnalytics()}get-customers-raiting-data`,
    getCancelToken('getAgencyAnalyticsCustomersRatingRequest')
  )
