import {
  POLICIES_ADD_CUSTOMER_FAIL,
  POLICIES_ADD_CUSTOMER_REQUEST,
  POLICIES_ADD_CUSTOMER_SUCCESS,
  POLICIES_ALL_FAIL,
  POLICIES_ALL_REQUEST,
  POLICIES_ALL_SUCCESS,
  POLICIES_DATES_FILTER_FAIL,
  POLICIES_DATES_FILTER_REQUEST,
  POLICIES_DATES_FILTER_SUCCESS,
  POLICIES_FILTERS_FAIL,
  POLICIES_FILTERS_REQUEST,
  POLICIES_FILTERS_SUCCESS,
  POLICIES_TOTALS_FAIL,
  POLICIES_TOTALS_REQUEST,
  POLICIES_TOTALS_SUCCESS,
} from '../../../types/actionTypes'
import $api, { getCancelToken } from '../../../http'
import { urlCustomer, urlPolicy } from '../../../environment/urls'
import { policesDatesFilterRequest, policesTotalsRequest } from '../../../http/requests'
import { insuranceCategory } from '../../../types/insuranceCategory'
import { getCustomersFiltersRequest } from '../../../http/requests/customersRequests'

const policyUrl = urlPolicy()
const customerUrl = urlCustomer()

export const getAllPolicies = () => async (dispatch, getState) => {
  dispatch({ type: POLICIES_ALL_REQUEST })
  try {
    const { datesFilter } = getState().policies
    const requestBody = {
      companies: [],
      categories: [],
      searchText: '',
      months: datesFilter.filter(d => d.selected).map(({ date }) => date),
    }
    const {
      data: { data: allPolicies },
    } = await $api.post(`${policyUrl}all`, requestBody)

    dispatch({
      type: POLICIES_ALL_SUCCESS,
      payload: allPolicies,
    })
  } catch (error) {
    dispatch({
      type: POLICIES_ALL_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPoliciesTotals = () => async dispatch => {
  dispatch({ type: POLICIES_TOTALS_REQUEST })
  try {
    const {
      data: { data },
    } = await policesTotalsRequest()
    dispatch({ type: POLICIES_TOTALS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: POLICIES_TOTALS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPoliciesDatesFilter = () => async dispatch => {
  dispatch({ type: POLICIES_DATES_FILTER_REQUEST })
  try {
    const {
      data: { data },
    } = await policesDatesFilterRequest()
    dispatch({ type: POLICIES_DATES_FILTER_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: POLICIES_DATES_FILTER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getPoliciesFilters = () => async dispatch => {
  dispatch({ type: POLICIES_FILTERS_REQUEST })
  try {
    const {
      data: { data },
    } = await getCustomersFiltersRequest()
    const companiesFilter = data.map(company => {
      return { id: company.id, name: company.name, nameEn: company.nameEn }
    })
    const categoriesFilter = data
      .map(company => company.categories)
      .flat()
      .filter(item => item)
      .filter((item, index, self) => index === self.findIndex(categ => categ.id === item.id))
      .map(ctgr => {
        return { id: ctgr.id, name: ctgr.name, nameEn: ctgr.englishName, fieldId: ctgr.fieldId }
      })
      .sort((a, b) => insuranceCategory[a.id].sortOrder - insuranceCategory[b.id].sortOrder)

    dispatch({
      type: POLICIES_FILTERS_SUCCESS,
      payload: { companiesFilter, categoriesFilter },
    })
  } catch (error) {
    dispatch({
      type: POLICIES_FILTERS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addPolicyCustomer = (policy, customer) => async dispatch => {
  dispatch({ type: POLICIES_ADD_CUSTOMER_REQUEST })
  try {
    const requestBody = {
      clientId: customer.id,
      policyId: policy.id,
      name: customer.name,
      idNumber: customer.idNumber,
      address: customer.address,
      phone: customer.phone,
    }
    const routeToAdd = `${customer.id ? 'update' : 'create'}-client-by-policy`
    const {
      data: { data },
    } = await $api.post(`${customerUrl}${routeToAdd}`, requestBody)
    let success = false
    if (data.success) success = true
    if (data.clientId) {
      success = true
      customer.id = data.clientId
    }

    dispatch({
      type: POLICIES_ADD_CUSTOMER_SUCCESS,
      payload: { success, policy, customer },
    })
  } catch (error) {
    dispatch({
      type: POLICIES_ADD_CUSTOMER_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
