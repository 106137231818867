import { BarChartRounded, FiberNewRounded, SupervisorAccountRounded, Warning } from '@mui/icons-material'
import AgencyHouseLobbyTotalsCard from './AgencyHouseLobbyTotalsCard'
import { useTranslation } from 'react-i18next'
import CashPlusIconSvg from '../../../../components/Common/SvgIcons/CashPlusIconSvg/CashPlusIconSvg'
import AgencyHouseLobbyChanges from '../AgencyHouseLobbyChanges/AgencyHouseLobbyChanges'
import { formatAsCurrency, formatAsNumber } from '../../../../utils/formatAs'
import AgencyHouseLobbyTotalsChart from './AgencyHouseLobbyTotalsChart'
import { getMonthName } from '../../../../services/getMonthName'
import { hebrew } from '../../../../i18n'
import NoChartDataSvgIcon from '../../../../components/Common/SvgIcons/NoChartDataSvgIcon/NoChartDataSvgIcon'
import './AgencyHouseLobbyTotals.scss'
import { useHistory } from 'react-router'
import { agencyHouseRoutes, agencyRoutes } from '../../../../environment/urls'
import { requestDataTypes } from '../../../../types/requestDataTypes'
import MoneyShekelIconSvg from '../../../../components/Common/SvgIcons/MoneyShekelIconSvg/MoneyShekelIconSvg'
import { agencyHouseAnalyticsSubroute } from '../../../../environment/urls'
import { isRegularAgency } from '../../../login/reducers/loginSelectors'
import FamilyGroupsToggle from '../../../../components/FamilyGroupsToggle/FamilyGroupsToggle'

const AgencyHouseLobbyTotalsValue = ({ value = null, warn = false, noDataText = '--' }) => (
  <div className='agency-house-lobby-totals-card-value-container'>
    <div className='agency-house-lobby-totals-card-value'>
      {value ? (
        <>
          <span>₪</span>
          {formatAsCurrency(value, { currency: '' })}
        </>
      ) : (
        <span className='agency-house-lobby-totals-card-no-data'>{noDataText}</span>
      )}
    </div>
    {warn && (
      <div className='agency-house-lobby-totals-card-value-warn'>
        <Warning />
      </div>
    )}
  </div>
)

const AgencyHouseLobbyTotals = ({
  MonthlyCommission,
  MonthlyPremium,
  MonthlyAccumulation,
  NewCommission,
  NewPoliciesCount,
  NewPoliciesFromExistingClientsCount,
  NewPoliciesFromNewClientsCount,
  AverageCommission,
  CommissionAverageForLastThreeMonth,
  totalsLoading,
}) => {
  const {
    t,
    i18n: { language: lng },
  } = useTranslation('agencyHouseLobby')
  const tTotals = (key, params) => t(`totals.${key}`, { ...params })
  const history = useHistory()

  const onCardClickHandler = productDataType => e => {
    e.stopPropagation()
    history.push(`${agencyHouseRoutes.analytics}${agencyHouseAnalyticsSubroute.incomes}?dataType=${productDataType}`)
  }

  const avrgCommissionChartData = () => {
    const data = []
    const labels = []

    CommissionAverageForLastThreeMonth.forEach(v => {
      data.push(v.Value)
      const date = new Date(v.Year, v.Month - 1, 1)
      labels.push(getMonthName(date, lng, 'short'))
    })
    return { data: hebrew() ? data.reverse() : data, labels: hebrew() ? labels.reverse() : labels }
  }
  return (
    <div className='agency-house-lobby-totals'>
      <AgencyHouseLobbyTotalsCard
        icon={<MoneyShekelIconSvg />}
        name={tTotals('monthlyCommission')}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.commission)}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={MonthlyCommission?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('monthlyCommission') })}
          />
          <AgencyHouseLobbyChanges value={MonthlyCommission?.Difference} />
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<BarChartRounded />}
        name={tTotals('monthlyPremium')}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.premium)}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={MonthlyPremium?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('monthlyPremium') })}
          />
          <AgencyHouseLobbyChanges value={MonthlyPremium?.Difference} />
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<CashPlusIconSvg />}
        name={tTotals('accumulation')}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.accumulation)}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={MonthlyAccumulation?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('accumulation') })}
          />
          <AgencyHouseLobbyChanges value={MonthlyAccumulation?.Difference} />
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<FiberNewRounded />}
        name={tTotals('newPoliciesCommission')}
        loading={totalsLoading}
        {...(isRegularAgency() && { onCardClick: () => history.push(agencyRoutes.policies + '?type=new') })}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={NewCommission?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('newPoliciesCommission') })}
          />
          <p className='agency-house-lobby-totals-card-heading'>
            {tTotals('newPolicies', {
              policiesCount: NewPoliciesCount ? formatAsNumber(NewPoliciesCount) : '--',
            })}
          </p>
          <div className='agency-house-lobby-totals-card-values-splitted'>
            <div className='agency-house-lobby-totals-card-values-splitted-value'>
              <p>
                {NewPoliciesFromExistingClientsCount && NewPoliciesFromExistingClientsCount !== 0
                  ? tTotals('policies', { policiesCount: NewPoliciesFromExistingClientsCount })
                  : '--'}
              </p>
              <p>{tTotals('fromExistingCustomers')}</p>
            </div>
            <div className='agency-house-lobby-totals-card-values-splitted-value'>
              <p>
                {NewPoliciesFromNewClientsCount && NewPoliciesFromNewClientsCount !== 0
                  ? tTotals('policies', { policiesCount: NewPoliciesFromNewClientsCount })
                  : '--'}
              </p>
              <p>{tTotals('fromNewCustomers')}</p>
            </div>
          </div>
        </div>
      </AgencyHouseLobbyTotalsCard>
      <AgencyHouseLobbyTotalsCard
        icon={<SupervisorAccountRounded />}
        name={tTotals('avrgCustomerCommission')}
        loading={totalsLoading}
        onCardClick={onCardClickHandler(requestDataTypes.commission)}
      >
        <div className='agency-house-lobby-totals-card-values'>
          <AgencyHouseLobbyTotalsValue
            value={AverageCommission?.Value}
            noDataText={tTotals('noDataText', { dataType: tTotals('avrgCustomerCommission') })}
          />
          <AgencyHouseLobbyChanges value={AverageCommission?.Difference} />
        </div>
        <div className='agency-house-lobby-totals-card-chart'>
          <>
            {(isRegularAgency() || history.location.pathname.includes('Agency')) && (
              <div className='agency-house-lobby-totals-card-family-toggle-wrapper'>
                <FamilyGroupsToggle />
              </div>
            )}
            {CommissionAverageForLastThreeMonth.length < 2 ? (
              <div className='no-chart-data'>
                <NoChartDataSvgIcon />
                <p>{tTotals('noChartData')}</p>
              </div>
            ) : (
              <AgencyHouseLobbyTotalsChart
                trend={AverageCommission?.Difference >= 0}
                labels={avrgCommissionChartData().labels}
                data={avrgCommissionChartData().data}
              />
            )}
          </>
        </div>
      </AgencyHouseLobbyTotalsCard>
    </div>
  )
}

export default AgencyHouseLobbyTotals
