import { useState, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import InputText from '../../../../components/Common/InputText/InputText'
import Button from '../../../../components/Common/Button/Button'
import Loader from '../../../../components/Common/Loader/Loader'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { addPolicyCustomer } from '../../../policies/actions/policiesActions'
import { POLICIES_ADD_CUSTOMER_RESET } from '../../../../types/actionTypes'
import './AddPolicyCustomerModal.scss'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'

const AddPolicyCustomerModal = ({ closeModal, policy }) => {
  const { t } = useTranslation('policies')
  const dispatch = useDispatch()
  const { addCustomerSuccess, addCustomerLoading } = useSelector(state => state.policies)
  const [name, setName] = useState(policy.clientName ?? '')
  const [idNumber, setIdNumber] = useState('')
  const [address, setAdress] = useState('')
  const [phone, setPhone] = useState('')

  const disabled = name.length === 0 || idNumber.length !== 9 || addCustomerLoading

  const submitHandler = e => {
    e.preventDefault()
    dispatch(addPolicyCustomer(policy, { name, idNumber, address, phone }))
  }

  const bodyOverflowRef = useRef()

  useEffect(() => {
    if (addCustomerSuccess) {
      closeModal()
    }
  }, [addCustomerSuccess, closeModal])

  useEffect(() => {
    //bodyOverflowRef.current = document.body.style.overflow
    //document.body.style.overflow = 'hidden'
    return () => {
      //document.body.style.overflow = bodyOverflowRef.current
      dispatch({ type: POLICIES_ADD_CUSTOMER_RESET })
    }
  }, [dispatch])

  return (
    // <ModalWindow>
    //   {addCustomerLoading && (
    //     <div className='add-policy-customer-modal-content-loader-overlay'>
    //       <Loader />
    //     </div>
    //   )}
    //   <div className='add-policy-customer-modal-content'>
    //     <div className='add-policy-customer-modal-title'>{t('addCustomerModal.title')}</div>
    //     <div className='add-policy-customer-modal-inputs-group-container'>
    //       <div className='add-policy-customer-modal-inputs-group-title'>{t('addCustomerModal.subtitle')}</div>
    //       <form action='' onSubmit={submitHandler}>
    //         <div className='add-policy-customer-modal-inputs-group'>
    //           <InputText
    //             name='name'
    //             id='name'
    //             label={t('addCustomerModal.customerName')}
    //             required
    //             value={name}
    //             onChange={setName}
    //           />
    //           <InputText
    //             inputType='number'
    //             name='idNumber'
    //             id='idNumber'
    //             label={t('addCustomerModal.customerId')}
    //             value={idNumber}
    //             onChange={setIdNumber}
    //             required
    //             showCharsCount
    //             charsCount={9}
    //           />
    //           <InputText
    //             name='address'
    //             id='address'
    //             label={t('addCustomerModal.customerAddress')}
    //             value={address}
    //             onChange={setAdress}
    //           />
    //           <InputText
    //             type={'number'}
    //             name='phone'
    //             id='phone'
    //             label={t('addCustomerModal.customerPhone')}
    //             value={phone}
    //             onChange={setPhone}
    //           />
    //         </div>
    //         <div className='add-policy-customer-modal-add-btn'>
    //           <Button caption={t('addCustomerModal.addBtn')} typeSubmit disabled={disabled} />
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </ModalWindow>
    <div
      className='add-policy-customer-modal-wrapper'
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <div className='add-policy-customer-modal-container'>
        <div onClick={closeModal} className='add-policy-customer-modal-close-icon'>
          <img src='./assets/close-icon-sm/Close.png' alt='cls' className='img-icon' />
        </div>
        {addCustomerLoading && (
          <div className='add-policy-customer-modal-content-loader-overlay'>
            <Loader />
          </div>
        )}
        <div className='add-policy-customer-modal-content'>
          <div className='add-policy-customer-modal-title'>{t('addCustomerModal.title')}</div>
          <div className='add-policy-customer-modal-inputs-group-container'>
            <div className='add-policy-customer-modal-inputs-group-title'>{t('addCustomerModal.subtitle')}</div>
            <form action='' onSubmit={submitHandler}>
              <div className='add-policy-customer-modal-inputs-group'>
                <InputText
                  name='name'
                  id='name'
                  label={t('addCustomerModal.customerName')}
                  required
                  value={name}
                  onChange={setName}
                />
                <InputText
                  inputType='number'
                  name='idNumber'
                  id='idNumber'
                  label={t('addCustomerModal.customerId')}
                  value={idNumber}
                  onChange={setIdNumber}
                  required
                  showCharsCount
                  charsCount={9}
                />
                <InputText
                  name='address'
                  id='address'
                  label={t('addCustomerModal.customerAddress')}
                  value={address}
                  onChange={setAdress}
                />
                <InputText
                  type={'number'}
                  name='phone'
                  id='phone'
                  label={t('addCustomerModal.customerPhone')}
                  value={phone}
                  onChange={setPhone}
                />
              </div>
              <div className='add-policy-customer-modal-add-btn'>
                <Button caption={t('addCustomerModal.addBtn')} typeSubmit disabled={disabled} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddPolicyCustomerModal
