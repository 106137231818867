import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { formatAsCurrency, formatAsPercent } from '../../../../utils/formatAs'
import { customersFilterByGroup } from '../../../customers/actions/customersActions'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ArrowBack } from '@mui/icons-material'
import { hebrew } from '../../../../i18n'
import './AgencyHouseAnalyticsRatingCards.scss'

const AgencyHouseAnalyticsRatingCards = ({ cards, selectCard }) => {
  const { t } = useTranslation('agencyHouseAnalytics')
  const history = useHistory()
  const dispatch = useDispatch()
  const distributionChartColors = [
    '#02b64a',
    'rgba(120, 192, 149, 1)',
    'rgba(200, 241, 217, 1)',
    'rgba(208, 117, 129, 1)',
    '#fb4659',
  ]
  const distributionChartTextColors = [
    'var(--text-primary-color)',
    null,
    null,
    'var(--text-primary-color)',
    'var(--text-primary-color)',
  ]

  const distributionCardClickHandler = groupIndex => {
    dispatch(customersFilterByGroup(groupIndex, true))
    history.push('/customers?type=distribution')
  }

  return (
    <div className='agency-house-analytics-cards'>
      {cards.map(({ order, selected = false, customersCount, total, value, avrgCommission }) => (
        <div
          key={order}
          className={classNames('agency-house-analytics-card', { selected })}
          {...(!selected && { onClick: () => selectCard(order) })}
        >
          <div className='agency-house-analytics-card-body'>
            <div className='agency-house-analytics-card-main'>
              <div className='agency-house-analytics-card-chips'>
                <div
                  className='agency-house-analytics-card-chip'
                  style={{
                    background: distributionChartColors[order - 1],
                    color: distributionChartTextColors[order - 1],
                  }}
                >
                  {t('rank', { rank: order })}
                </div>
                <div className='agency-house-analytics-card-chip'>{t('customersCount', { customersCount })}</div>
              </div>
              <div className='agency-house-analytics-card-main-value' style={{ textAlign: hebrew() ? 'end' : 'start' }}>
                {formatAsCurrency(total, { dashesForZero: false })}
              </div>
              <p>{t('ratingTotalCommission')}</p>
            </div>
            <div className='agency-house-analytics-card-details'>
              <div>
                <div
                  className='agency-house-analytics-card-details-value'
                  style={{ textAlign: hebrew() ? 'end' : 'start' }}
                >
                  {formatAsPercent(value, { dashesForZero: false })}
                </div>
                <p>{t('ratingPercent')}</p>
              </div>
              <div>
                <div
                  className='agency-house-analytics-card-details-value'
                  style={{ textAlign: hebrew() ? 'end' : 'start' }}
                >
                  {formatAsCurrency(avrgCommission, { dashesForZero: false })}
                </div>
                <p>{t('ratingAverage')}</p>
              </div>
            </div>
          </div>
          {selected && (
            <div className='agency-house-analytics-card-footer'>
              <button onClick={() => distributionCardClickHandler(order - 1)}>
                <span>{t('goToCustomers')}</span>
                <ArrowBack />
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default AgencyHouseAnalyticsRatingCards
