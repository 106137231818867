import { useDispatch } from 'react-redux'
import { themeToggle } from '../../../themes/actions/themeActions'
import { InvertColors } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import LogoLightSvg from '../../../../components/LogoLightSvg/LogoLightSvg'
import './RegisterHeader.scss'

const { APP_CONFIG } = window

const RegisterHeader = () => {
  const { t } = useTranslation('registration')
  const dispatch = useDispatch()

  const switchTheme = () => dispatch(themeToggle())

  return (
    <header className='register-header'>
      <div className='register-header-logo'>
        {/* <img src={`./assets/Logo/Logo_light.svg`} alt='AiGent' /> */}
        <LogoLightSvg />
      </div>
      <h2>{t('workMethod')}</h2>
      <div className='register-header-actions'>
        <p>{t('alreadyRegistered')}</p>
        <NavLink to='/login'>{t('btnLogin')}</NavLink>
        {(process.env.REACT_APP_API_ENV === 'development' || APP_CONFIG?.STAGE_ENV) && (
          <button onClick={switchTheme}>
            <InvertColors />
          </button>
        )}
      </div>
    </header>
  )
}

export default RegisterHeader
