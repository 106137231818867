import { BarChartRounded, PolicyRounded, SupervisorAccountRounded } from '@mui/icons-material'
import { requestDataTypeObject, requestDataTypes } from './requestDataTypes'
import { analyticsPeriodViewType, getAnalyticsPeriodViewsFiltered } from './analyticsTypes'
import CashPlusIconSvg from '../components/Common/SvgIcons/CashPlusIconSvg/CashPlusIconSvg'
import MoneyShekelIconSvg from '../components/Common/SvgIcons/MoneyShekelIconSvg/MoneyShekelIconSvg'

export const analyticsIncomesListType = {
  agents: 1,
  companies: 2,
  categories: 3,
}

export const analyticsIncomesExtraListType = {
  timePeriods: 4,
}

export const analyticsIncomesList = {
  [analyticsIncomesListType.agents]: {
    type: analyticsIncomesListType.agents,
    nameEn: 'Agents',
    name: 'סוכנים',
    detailsName: 'כל הסוכנים',
    detailsNameEn: 'All Agents',
  },
  [analyticsIncomesListType.companies]: {
    type: analyticsIncomesListType.companies,
    nameEn: 'Companies',
    name: 'יצרנים',
    detailsName: 'כל היצרנים',
    detailsNameEn: 'All Companies',
  },
  [analyticsIncomesListType.categories]: {
    type: analyticsIncomesListType.categories,
    nameEn: 'Categories',
    name: 'מוצרים',
    detailsName: 'כל המוצרים',
    detailsNameEn: 'All Categories',
  },
}

export const incomesTotalsType = {
  commissionMonthly: 1,
  commissionAverage: 2,
  premium: 3,
  premiumAverage: 4,
  commissionRatePremium: 5,
  commissionRateAccumulation: 6,
  accumulation: 7,
  accumulationOpeningBalance: 8,
  accumulationClosingBalance: 9,
  accumulationDifference: 10,
  commissionVAT: 11,
}
export const incomesTotalsObject = {
  [incomesTotalsType.commissionMonthly]: {
    type: incomesTotalsType.commissionMonthly,
    name: 'סה”כ עמלה לתקופה לא כולל מע"מ',
    nameEn: 'Total commission (excl VAT) for the period',
    nameMonthlyView: 'עמלה חודשית לא כולל מע"מ',
    nameEnMonthlyView: 'Monthly Commission (excl VAT)',
    icon: <MoneyShekelIconSvg />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    detailsMainValue: true,
    detailsHeaderName: 'סה”כ עמלה לא כולל מע"מ: ',
    detailsHeaderNameEn: 'Total Commission (excl VAT): ',
  },
  // [incomesTotalsType.commissionVAT]: {
  //   type: incomesTotalsType.commissionVAT,
  //   name: 'סה”כ עמלה עם מע"מ לתקופה',
  //   nameEn: 'Total commission (incl VAT) for the period',
  //   nameMonthlyView: 'עמלה עם מע"מ חודשית',
  //   nameEnMonthlyView: 'Monthly Commission (incl VAT)',
  //   icon: <MoneyShekelIconSvg />,
  //   periodViews: getAnalyticsPeriodViewsFiltered(),
  //   detailsMainValue: false,
  //   detailsHeaderName: 'סה”כ עמלה עם מע"מ: ',
  //   detailsHeaderNameEn: 'Total Commission (incl VAT): ',
  // },
  [incomesTotalsType.commissionAverage]: {
    type: incomesTotalsType.commissionAverage,
    name: 'עמלה ממוצעת ללקוח',
    nameEn: 'Average commission per customer',
    nameMonthlyView: 'עמלה ממוצעת ללקוח',
    nameEnMonthlyView: 'Average commission per customer',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
  },
  [incomesTotalsType.premium]: {
    type: incomesTotalsType.premium,
    name: 'סה”כ פרמיה לתקופה',
    nameEn: 'Total premium for the period',
    nameMonthlyView: 'פרמיה חודשית',
    nameEnMonthlyView: 'Monthly Premium',
    icon: <BarChartRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    detailsMainValue: true,
    detailsHeaderName: 'סה"כ פרמיה: ',
    detailsHeaderNameEn: 'Total Premium: ',
  },
  [incomesTotalsType.premiumAverage]: {
    type: incomesTotalsType.premiumAverage,
    name: 'ממוצע פרמיה ללקוח',
    nameEn: 'Average premium per customer',
    nameMonthlyView: 'ממוצע פרמיה ללקוח',
    nameEnMonthlyView: 'Average premium per customer',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
  },
  [incomesTotalsType.commissionRatePremium]: {
    type: incomesTotalsType.commissionRatePremium,
    name: 'שיעור עמלה מפרמיה',
    nameEn: 'Commission Rate by Premium',
    nameMonthlyView: 'שיעור עמלה מפרמיה',
    nameEnMonthlyView: 'Commission Rate by Premium',
    icon: <BarChartRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    detailsMainValue: true,
    detailsHeaderName: 'סה"כ שיעור עמלה פרמיה: ',
    detailsHeaderNameEn: 'Total Premium Commission Rate: ',
  },
  [incomesTotalsType.commissionRateAccumulation]: {
    type: incomesTotalsType.commissionRateAccumulation,
    name: 'שיעור עמלה מצבירה',
    nameEn: 'Commission Rate by Accumulation',
    nameMonthlyView: 'שיעור עמלה מצבירה',
    nameEnMonthlyView: 'Commission Rate by Accumulation',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    //detailsMainValue: true,
    detailsHeaderName: 'סה"כ שיעור עמלה צבירה: ',
    detailsHeaderNameEn: 'Total Accumulation Commission Rate: ',
  },
  [incomesTotalsType.accumulation]: {
    type: incomesTotalsType.accumulation,
    name: 'צבירה',
    nameEn: 'Accumulation',
    nameMonthlyView: 'צבירה',
    nameEnMonthlyView: 'Accumulation',
    icon: <CashPlusIconSvg />,
    periodViews: [analyticsPeriodViewType.monthly],
    detailsMainValue: true,
    detailsHeaderName: 'סה"כ צבירה: ',
    detailsHeaderNameEn: 'Total Accumulation: ',
  },
  [incomesTotalsType.accumulationOpeningBalance]: {
    type: incomesTotalsType.accumulationOpeningBalance,
    name: 'יתרת פתיחה לתקופה',
    nameEn: 'Opening balance for the period',
    nameMonthlyView: 'יתרת פתיחה לתקופה',
    nameEnMonthlyView: 'Opening balance for the period',
    shortName: 'פתיחה',
    shortNameEn: 'open',
    icon: <MoneyShekelIconSvg />,
    periodViews: getAnalyticsPeriodViewsFiltered([analyticsPeriodViewType.monthly]),
  },
  [incomesTotalsType.accumulationClosingBalance]: {
    type: incomesTotalsType.accumulationClosingBalance,
    name: 'יתרת סגירה לתקופה',
    nameEn: 'Closing balance for the period',
    nameMonthlyView: 'יתרת סגירה לתקופה',
    nameEnMonthlyView: 'Closing balance for the period',
    shortName: 'סגירה',
    shortNameEn: 'closing',
    icon: <MoneyShekelIconSvg />,
    periodViews: getAnalyticsPeriodViewsFiltered([analyticsPeriodViewType.monthly]),
    detailsMainValue: true,
    detailsHeaderName: 'יתרת סגירה לתקופה: ',
    detailsHeaderNameEn: 'Closing balance for the period: ',
  },
  [incomesTotalsType.accumulationDifference]: {
    type: incomesTotalsType.accumulationDifference,
    name: 'סה”כ הפרש במהלך התקופה',
    nameEn: 'Total difference during the period',
    nameMonthlyView: 'סה”כ הפרש במהלך התקופה',
    nameEnMonthlyView: 'Total difference during the period',
    shortName: 'הפרש',
    shortNameEn: 'diff',
    icon: <MoneyShekelIconSvg />,
    periodViews: getAnalyticsPeriodViewsFiltered([analyticsPeriodViewType.monthly]),
  },
}
export const incomesDataTypes = [
  {
    ...requestDataTypeObject[requestDataTypes.commission],
    totals: [incomesTotalsType.commissionMonthly, incomesTotalsType.commissionAverage],
  },
  {
    ...requestDataTypeObject[requestDataTypes.premium],
    totals: [incomesTotalsType.premium, incomesTotalsType.premiumAverage],
  },
  {
    ...requestDataTypeObject[requestDataTypes.commissionRate],
    totals: [incomesTotalsType.commissionRatePremium, incomesTotalsType.commissionRateAccumulation],
  },
  {
    ...requestDataTypeObject[requestDataTypes.accumulation],
    totals: [
      incomesTotalsType.accumulation,
      incomesTotalsType.accumulationOpeningBalance,
      incomesTotalsType.accumulationClosingBalance,
      incomesTotalsType.accumulationDifference,
    ],
  },
]
export const policiesDataType = {
  all: 1,
  new: 2,
}
export const policiesTotalsType = {
  totalPolicies: 1,
  averagePolicyCustomers: 2,
  totalNewPolicies: 3,
  existingCustomersPolicies: 4,
  newCustomersPolicies: 5,
}
export const policiesTotalsObject = {
  [policiesTotalsType.totalPolicies]: {
    type: policiesTotalsType.totalPolicies,
    name: 'סה”כ פוליסות',
    nameEn: 'Total policies',
    nameMonthlyView: 'סה”כ פוליסות',
    nameEnMonthlyView: 'Total policies',
    icon: <PolicyRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    detailsMainValue: true,
    detailsHeaderName: 'סה”כ פוליסות: ',
    detailsHeaderNameEn: 'Total policies: ',
  },
  [policiesTotalsType.averagePolicyCustomers]: {
    type: policiesTotalsType.averagePolicyCustomers,
    name: 'ממוצע פוליסות ללקוח',
    nameEn: 'Average policy per customer',
    nameMonthlyView: 'ממוצע פוליסות ללקוח',
    nameEnMonthlyView: 'Average policy per customer',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
  },
  [policiesTotalsType.totalNewPolicies]: {
    type: policiesTotalsType.totalNewPolicies,
    name: 'סה”כ פוליסות חדשות',
    nameEn: 'Total new policies',
    nameMonthlyView: 'סה”כ פוליסות חדשות',
    nameEnMonthlyView: 'Total new policies',
    shortName: 'סהכ',
    shortNameEn: 'total',
    icon: <PolicyRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    detailsMainValue: true,
    detailsHeaderName: 'סה”כ פוליסות חדשות: ',
    detailsHeaderNameEn: 'Total new policies: ',
  },
  [policiesTotalsType.existingCustomersPolicies]: {
    type: policiesTotalsType.existingCustomersPolicies,
    name: 'פוליסות מלקוחות קיימים',
    nameEn: 'Policies from existing customers',
    nameMonthlyView: 'פוליסות מלקוחות קיימים',
    nameEnMonthlyView: 'Policies from existing customers',
    shortName: 'מקיימים',
    shortNameEn: 'existing',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
  },
  [policiesTotalsType.newCustomersPolicies]: {
    type: policiesTotalsType.newCustomersPolicies,
    name: 'פוליסות מלקוחות חדשים',
    nameEn: 'Policies from new customers',
    nameMonthlyView: 'פוליסות מלקוחות חדשים',
    nameEnMonthlyView: 'Policies from new customers',
    shortName: 'מחדשים',
    shortNameEn: 'new',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
  },
}
export const policiesDataTypes = [
  {
    id: policiesDataType.all,
    totals: [policiesTotalsType.totalPolicies, policiesTotalsType.averagePolicyCustomers],
  },
  {
    id: policiesDataType.new,
    totals: [
      policiesTotalsType.totalNewPolicies,
      policiesTotalsType.existingCustomersPolicies,
      policiesTotalsType.newCustomersPolicies,
    ],
  },
]
export const customersDataType = {
  all: 1,
}
export const customersTotalsType = {
  customers: 1,
  newCustomers: 2,
}
export const customersTotalsObject = {
  [customersTotalsType.customers]: {
    type: customersTotalsType.customers,
    name: 'סהכ לקוחות',
    nameEn: 'Total customers',
    shortName: 'סהכ',
    shortNameEn: 'total',
    nameMonthlyView: 'סהכ לקוחות',
    nameEnMonthlyView: 'Total customers',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
    detailsMainValue: true,
    detailsHeaderName: 'סהכ לקוחות: ',
    detailsHeaderNameEn: 'Total customers: ',
  },
  [customersTotalsType.newCustomers]: {
    type: customersTotalsType.newCustomers,
    name: 'לקוחות חדשים (מתוך הסהכ)',
    nameEn: 'New customers (from total)',
    shortName: 'חדשים',
    shortNameEn: 'new',
    nameMonthlyView: 'לקוחות חדשים (מתוך הסהכ)',
    nameEnMonthlyView: 'New customers (from total)',
    icon: <SupervisorAccountRounded />,
    periodViews: getAnalyticsPeriodViewsFiltered(),
  },
}
export const customersDataTypes = [
  {
    id: customersDataType.all,
    totals: [customersTotalsType.customers, customersTotalsType.newCustomers],
  },
]
export const analyticsIncomesListViewType = {
  table: 1,
  chart: 2,
}

export const getIncomesTotalsTypes = (dataType, periodViewType) =>
  incomesDataTypes
    .find(t => t.id === dataType)
    .totals.filter(tt => incomesTotalsObject[tt].periodViews.includes(periodViewType))

export const getPoliciesTotalsTypes = (dataType, periodViewType) =>
  policiesDataTypes
    .find(t => t.id === dataType)
    .totals.filter(tt => policiesTotalsObject[tt].periodViews.includes(periodViewType))

export const getCustomersTotalsTypes = ({
  dataType = customersDataType.all,
  periodViewType = analyticsPeriodViewType.monthly,
} = {}) =>
  customersDataTypes
    .find(t => t.id === dataType)
    .totals.filter(tt => customersTotalsObject[tt].periodViews.includes(periodViewType))
