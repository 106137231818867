import { useRef, useEffect, useState, useLayoutEffect } from 'react'
import ClearButton from '../Common/ClearButton/ClearButton'
import Loader from '../Common/Loader/Loader'
import { useBottomScrollListener } from 'react-bottom-scroll-listener'
import { urlCustomers } from '../../environment/urls'
import $api from '../../http'
import { useTranslation } from 'react-i18next'
import NoData from '../Common/NoData/NoData'
import './SearchCustomerModal.scss'
import classNames from 'classnames'
import AddPolicyCustomerModal from '../../features/policy/components/AddPolicyCustomerModal/AddPolicyCustomerModal'

const SearchCustomerModal = ({ setShowAddCustomerModal, closeModal, onSelect, loading, policy }) => {
  const { t } = useTranslation('customers')
  const [searchText, setSearchText] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const pageSize = 15
  const [loadingItems, setLoadingItems] = useState(true)
  const [items, setItems] = useState([])
  const pagesCount = Math.ceil(totalItems === 0 ? 1 : totalItems / pageSize)
  const [positionTop, setPositionTop] = useState(false)
  //const [showAddCustomerModal, setShowAddCustomerModal] = useState(false)

  const bodyOverflowRef = useRef()
  const scrollRef = useBottomScrollListener(() => {
    if (currentPage < pagesCount && !loadingItems) {
      setCurrentPage(prev => prev + 1)
    }
  })

  const searchListContainerRef = useRef(null)
  const searchInputRef = useRef(null)

  const setChecked = (item, e) => {
    e.stopPropagation()
    onSelect(item)
  }

  const searchHandler = e => {
    setCurrentPage(1)
    setSearchText(e.target.value)
    setItems([])
    setTotalItems(0)
  }

  const clearInput = () => {
    const e = {
      target: {
        value: '',
      },
    }
    searchHandler(e)
  }

  const handleHideSearchList = event => {
    if (
      event.key === 'Escape' ||
      (searchListContainerRef.current && !searchListContainerRef.current.contains(event.target))
    ) {
      event.stopPropagation()
      closeModal()
    }
  }

  const markBold = (str, substr) => {
    const n = str.toUpperCase()
    const q = substr.toUpperCase()
    const x = n.indexOf(q, 0)
    if (!q || x === -1) {
      return str // bail early
    }
    const l = q.length
    return (
      <>
        {str.substring(0, x)}
        <b>{str.substring(x, x + l)}</b>
        {str.substring(x + l)}
      </>
    )
  }

  useLayoutEffect(() => {
    const windowHeight = window.innerHeight
    const modalBottom = searchListContainerRef?.current?.getBoundingClientRect().bottom
    modalBottom + 50 > windowHeight && setPositionTop(true)
  }, [])

  useEffect(() => {
    //   document.addEventListener('click', handleHideSearchList, true)
    bodyOverflowRef.current = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      //     document.removeEventListener('click', handleHideSearchList, true)
      document.body.style.overflow = bodyOverflowRef.current
    }
  })

  useEffect(() => {
    const searchCustomers = async () => {
      const url = `${urlCustomers()}filter-clients`
      const requestBody = {
        searchText: searchText.toUpperCase(),
        currentPage: currentPage - 1,
        pageSize,
      }
      setLoadingItems(true)
      await $api
        .post(url, requestBody)
        .then(res => {
          const {
            data: {
              data: { items, totalCount },
            },
          } = res
          setItems(prev => [...prev, ...items])
          setTotalItems(totalCount)
          setLoadingItems(false)
        })
        .catch(e => {
          setLoadingItems(false)
          console.log(e)
        })
    }
    searchCustomers()
  }, [searchText, currentPage])

  return (
    <>
      <div
        className={classNames('search-customer-modal', { 'position-top': positionTop })}
        ref={searchListContainerRef}
        onClick={e => e.stopPropagation()}
      >
        {loading && (
          <div className='search-customer-modal-loader-overlay'>
            <Loader />
          </div>
        )}
        <div className='search-customer-input-container'>
          <div
            onClick={e => {
              e.stopPropagation()
              searchInputRef.current.focus()
            }}
            className='search-customer-input-icon'
          >
            <img src='./assets/search-icon-sm/Search.png' alt='srch' className='img-icon' />
          </div>
          <input
            type='text'
            placeholder={t('filters.searchPlaceholder')}
            value={searchText}
            onChange={searchHandler}
            ref={searchInputRef}
          />
          {searchText.length > 0 && <ClearButton onCloseClick={clearInput} />}
        </div>
        <div className='search-customer-list-container'>
          <div className='search-customer-list' ref={scrollRef}>
            {items.length === 0 && !loadingItems && <NoData text='Nothing Found' />}
            {items.map(customer => {
              return (
                <div className='search-customer-list-item' key={customer.id} onClick={e => setChecked(customer, e)}>
                  <div className='search-customer-list-item-content'>
                    <div className='search-customer-list-item-name'>{markBold(customer.name, searchText)}</div>
                    <div className='search-customer-list-item-idnum' dir='ltr'>
                      {customer.unHashedIdNumber}
                    </div>
                  </div>
                </div>
              )
            })}
            {loadingItems && <Loader />}
          </div>
        </div>
        <div className='search-customers-add-btn-container'>
          <button onClick={setShowAddCustomerModal}>
            <div className='search-customers-add-icon'>
              <img src='./assets/icon-add/Add.png' className='img-icon' alt='add' />
            </div>
            <div>{t('createCustomerBtn')}</div>
          </button>
        </div>
      </div>
    </>
  )
}

export default SearchCustomerModal
