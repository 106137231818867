import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './ProfileEditEmailCompany.scss'
import { hebrew } from '../../../../i18n'
import { companies } from '../../../../types/companies'
import ProfileEditEmailCompanyInstructions from '../ProfileEditEmailCompanyInstructions/ProfileEditEmailCompanyInstructions'
import { getRandomInt } from '../../../../services/getRandom'
import { delay } from '../../../../services/delay'
import { setReportsPasswordRequest } from '../../../../http/requests'

const ProfileEditEmailCompany = ({ closeSidebar, company }) => {
  const { t } = useTranslation('profile')
  const [showPasswordInput, setShowPasswordInput] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [passwords, setPasswords] = useState(
    Object.fromEntries(
      company.reportTypes.map(rt => {
        return [rt.id, '']
      })
    )
  )
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  const submitHandler = async () => {
    try {
      setSubmitLoading(true)
      setSubmitError(null)
      const reportsPasswords = Object.entries(passwords).map(([reportNumber, password]) => ({
        reportNumber: +reportNumber,
        password,
      }))
      const {
        data: { hasError },
      } = await setReportsPasswordRequest(reportsPasswords)
      !hasError && closeSidebar()
    } catch (error) {
      setSubmitError(null)
    } finally {
      setSubmitLoading(false)
    }
  }
  const submitDisabled = !passwords || Object.values(passwords).some(p => p.length === 0) || submitLoading

  return (
    <div className='profile-sidebar-email-wrapper'>
      <div className='profile-sidebar-email-download'>
        <div
          className='sidebar-close-btn'
          onClick={() => (showPasswordInput ? !submitLoading && setShowPasswordInput(false) : closeSidebar())}
        >
          <img
            src={`./assets/${showPasswordInput ? 'arrow-right-common/Right.png' : 'close-icon/Close.png'}`}
            className='img-icon'
            alt='close'
          />
        </div>
        <div className='profile-sidebar-content'>
          <div className='profile-sidebar-logo'>
            <img src={`./assets/companies-logos-light/${company.id}.png`} alt={company.id} />
          </div>
          <div className='profile-sidebar-company-name'>{hebrew() ? company.name : company.nameEn}</div>
          <div className='profile-sidebar-title'>{t('editEmailCompanyModal.modalTitle')}</div>
          {showPasswordInput ? (
            <div className='profile-company-credentials'>
              {company.reportTypes.map(rt => (
                <div className='input-container' key={rt.id}>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name={rt.name}
                    id={rt.id}
                    placeholder=' '
                    value={passwords[rt.id] ?? ''}
                    autoComplete={'one-time-code'}
                    onChange={({ target: { value } }) => setPasswords({ [rt.id]: value })}
                  />
                  <div
                    className='password-icon'
                    onMouseDownCapture={() => setShowPassword(true)}
                    onMouseUp={() => setShowPassword(false)}
                  >
                    <img src={`./assets/eye/${showPassword ? 'Hide' : 'Show'}.png`} alt='hide' />
                  </div>
                  <label htmlFor={company.id}>{t('filePassword')}</label>
                  <span className='line'></span>
                  {submitError && <span className='error-small'>{'error'}</span>}
                </div>
              ))}
              <div className='profile-sidebar-company-instructions-btn'>
                <button disabled={submitDisabled} onClick={submitHandler}>
                  {t('saveBtn')}
                </button>
              </div>
            </div>
          ) : (
            <>
              <div className='profile-sidebar-company-instructions'>
                <ProfileEditEmailCompanyInstructions company={company} />
              </div>
              <div className='profile-sidebar-company-instructions-btn'>
                <button onClick={() => setShowPasswordInput(true)}>{t('nextBtn')}</button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfileEditEmailCompany
