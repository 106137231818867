import { getAgencyAnalyticsDatesRequest } from '../../../http/requests/agencyAnalyticsRequests'
import {
  getAgencyHouseAnalyticsAgenciesFilterRequest,
  getAgencyHouseAnalyticsCompaniesCategoriesFilterRequest,
  getAgencyHouseAnalyticsDatesRequest,
} from '../../../http/requests/agencyHouseRequests'
import { getCustomersFiltersRequest } from '../../../http/requests/customersRequests'
import {
  AGENT_HOUSE_ANALYTICS_FILTERS_FAIL,
  AGENT_HOUSE_ANALYTICS_FILTERS_REQUEST,
  AGENT_HOUSE_ANALYTICS_FILTERS_SUCCESS,
} from '../../../types/actionTypes'
import { insuranceCategory } from '../../../types/insuranceCategory'
import { lowercaseObjectKeys } from '../../../utils/objectUtils'
import { isRegularAgency } from '../../login/reducers/loginSelectors'

export const getAgencyHouseAnalyticsFilters = () => async dispatch => {
  dispatch({ type: AGENT_HOUSE_ANALYTICS_FILTERS_REQUEST })
  try {
    const getDatesRequest = isRegularAgency() ? getAgencyAnalyticsDatesRequest : getAgencyHouseAnalyticsDatesRequest
    const { data: resp } = await getDatesRequest()
    let dates = resp.Data ?? resp.data
    let companies = [],
      categories = []
    if (!isRegularAgency()) {
      const {
        data: { Data },
      } = await getAgencyHouseAnalyticsCompaniesCategoriesFilterRequest()
      let resp = lowercaseObjectKeys(Data)
      companies = resp.companies
      categories = resp.categories
    } else {
      const {
        data: { data },
      } = await getCustomersFiltersRequest()
      companies = data.map(({ id, name, nameEn }) => ({ id, name, nameEn }))
      categories = data
        .map(company => company.categories)
        .flat()
        .filter(item => item)
        .filter(
          (item, index, self) => index === self.findIndex(categ => categ.id === item.id && categ.name === item.name)
        )
        .map(({ id, name, englishName }) => ({ id, nameEn: englishName, name }))
        .sort((a, b) => insuranceCategory[a.id].sortOrder - insuranceCategory[b.id].sortOrder)
    }
    let agencies = []
    if (!isRegularAgency()) {
      const {
        data: { Data: resp },
      } = await getAgencyHouseAnalyticsAgenciesFilterRequest()
      agencies = resp
    }

    dispatch({
      type: AGENT_HOUSE_ANALYTICS_FILTERS_SUCCESS,
      payload: {
        dates: lowercaseObjectKeys(dates),
        agencies: lowercaseObjectKeys(agencies).map(a => ({ ...a, name: a.agencyName, nameEn: a.agencyName })),
        categories,
        companies,
      },
    })
  } catch (error) {
    dispatch({
      type: AGENT_HOUSE_ANALYTICS_FILTERS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
