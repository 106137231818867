import { useHistory } from 'react-router'
import AgencyHouseAnalyticsFiltersbar from '../AgencyHouseAnalyticsFiltersbar/AgencyHouseAnalyticsFiltersbar'
import { useEffect, useState } from 'react'
import useQuery from '../../../../hooks/useQuery'
import { hebrew } from '../../../../i18n'
import { useDispatch, useSelector } from 'react-redux'
import {
  AGENT_HOUSE_ANALYTICS_SET_LISTTYPE,
  AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE,
} from '../../../../types/actionTypes'
import {
  analyticsIncomesExtraListType,
  analyticsIncomesList,
  analyticsIncomesListType,
  analyticsIncomesListViewType,
  customersTotalsType,
} from '../../../../types/analyticsIncomesTypes'
import queryString from 'query-string'
import { getAgencyHouseAnalyticsCustomersListRequest } from '../../../../http/requests/agencyHouseRequests'
import { getCheckedIds, lowercaseObjectKeys } from '../../../../utils/objectUtils'
import { isRegularAgency } from '../../../login/reducers/loginSelectors'
import { getAgencyAnalyticsCustomersListRequest } from '../../../../http/requests/agencyAnalyticsRequests'
import './AgencyHouseAnalyticsCustomers.scss'
import AgencyHouseAnalyticsCustomersListContainer from '../AgencyHouseAnalyticsCustomersListContainer/AgencyHouseAnalyticsCustomersListContainer'
import AgencyHouseAnalyticsCustomersDetailsCard from '../AgencyHouseAnalyticsCustomersDetailsCard/AgencyHouseAnalyticsCustomersDetailsCard'
import { analyticsPeriodViewType } from '../../../../types/analyticsTypes'
import { getMonthNameByNumber } from '../../../../services/getMonthName'
import { familyGroupMode } from '../../../shared/reducers/sharedSelectors'

const AgencyHouseAnalyticsCustomers = () => {
  const [totals, setTotals] = useState(
    Object.values(customersTotalsType).map(type => ({
      type,
      value: null,
      diff: 0,
    }))
  )
  const [list, setList] = useState([])
  const [listLoading, setListLoading] = useState(false)
  const [detailsItem, setDetailsItem] = useState(null)

  const dispatch = useDispatch()
  const {
    incomesListType,
    incomesListViewType,
    periodViewType,
    selectedDates,
    selectedAgencies,
    categories,
    companies,
    filtersLoading,
  } = useSelector(({ agencyHouseAnalytics }) => agencyHouseAnalytics)
  const { selectedGroupsIds } = useSelector(({ shared }) => shared)
  const calculateByFamilyGroups = familyGroupMode()

  const history = useHistory()
  let query = useQuery()
  const currentListType = query.get('listType')
  const currentListViewType = query.get('listViewType')

  const selectListType = listType => {
    let listTypeToSet = listType
    if (isRegularAgency() && listType === analyticsIncomesListType.agents)
      listTypeToSet = analyticsIncomesListType.companies
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, listType: listTypeToSet }).toString() })
  }

  if (
    !currentListType ||
    !Object.values(analyticsIncomesListType)
      .filter(lt => (isRegularAgency() ? lt !== analyticsIncomesListType.agents : true))
      .includes(+currentListType)
  ) {
    selectListType(incomesListType)
  }

  if (
    currentListType &&
    Object.values(analyticsIncomesListType)
      .filter(lt => (isRegularAgency() ? lt !== analyticsIncomesListType.agents : true))
      .includes(+currentListType) &&
    incomesListType !== +currentListType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_LISTTYPE, payload: +currentListType })
  }

  const selectListViewType = listViewType => {
    const parsed = queryString.parse(history.location.search)
    history.replace({ search: new URLSearchParams({ ...parsed, listViewType }).toString() })
  }

  if (!currentListViewType || !Object.values(analyticsIncomesListViewType).includes(+currentListViewType)) {
    selectListViewType(incomesListViewType)
  }

  if (
    currentListViewType &&
    Object.values(analyticsIncomesListViewType).includes(+currentListViewType) &&
    incomesListViewType !== +currentListViewType
  ) {
    dispatch({ type: AGENT_HOUSE_ANALYTICS_SET_LISTVIEWTYPE, payload: +currentListViewType })
  }

  useEffect(() => {
    const fetchTable = async () => {
      try {
        setDetailsItem(null)
        setList([])
        setListLoading(true)
        const notMonthlyChartView =
          +currentListViewType === analyticsIncomesListViewType.chart &&
          periodViewType !== analyticsPeriodViewType.monthly
        const getListRequest = isRegularAgency()
          ? getAgencyAnalyticsCustomersListRequest
          : getAgencyHouseAnalyticsCustomersListRequest
        const { data: resp } = await getListRequest({
          categories: getCheckedIds(categories, 'selected'),
          companies: getCheckedIds(companies, 'selected'),
          agenciesIds: selectedAgencies.filter(a => a.selected).map(a => a.id),
          timePeriods: selectedDates.filter(a => a.selected).map(a => a.date),
          entityType: notMonthlyChartView ? analyticsIncomesExtraListType.timePeriods : +currentListType,
          isChartView: +currentListViewType === analyticsIncomesListViewType.chart,
          ...(isRegularAgency() && { calculateByFamilyGroups }),
        })
        let listItems = lowercaseObjectKeys(resp.Data ?? resp.data).items
        if (notMonthlyChartView) {
          listItems = listItems.map(li => {
            const [year, month] = li.key.split('-')
            return {
              ...li,
              name: `${getMonthNameByNumber(month, 'he')}, ${year}`,
              nameEn: `${getMonthNameByNumber(month, 'en')}, ${year}`,
            }
          })
        }
        setList(listItems)
        setTotals([
          {
            type: customersTotalsType.customers,
            value: resp.Data ? resp.Data.TotalCustomers : resp.data.totalCustomers,
            diff: 0,
          },
          {
            type: customersTotalsType.newCustomers,
            value: resp.Data ? resp.Data.TotalNewCustomers : resp.data.totalNewCustomers,
            diff: 0,
          },
        ])
        setListLoading(false)
      } catch (error) {
        setListLoading(false)
      }
    }
    if (
      (!isRegularAgency() &&
        companies.length > 0 &&
        categories.length > 0 &&
        selectedDates.length > 0 &&
        selectedAgencies.length > 0) ||
      (isRegularAgency() && selectedDates.length > 0 && companies.length > 0 && categories.length > 0)
    ) {
      if (Object.values(analyticsIncomesListViewType).includes(+currentListViewType)) {
        fetchTable()
      }
    }
  }, [
    selectedGroupsIds,
    calculateByFamilyGroups,
    selectedDates,
    selectedAgencies,
    companies,
    categories,
    currentListType,
    currentListViewType,
  ])

  useEffect(() => {
    detailsItem && setDetailsItem(null)
  }, [history.location, companies, categories])

  return (
    <div className='agency-house-analytics-customers'>
      <main>
        <AgencyHouseAnalyticsFiltersbar
          selectListType={selectListType}
          listTypes={Object.values(analyticsIncomesList)
            .filter(l => (isRegularAgency() ? l.type !== analyticsIncomesListType.agents : true))
            .map(t => ({
              ...t,
              id: t.type,
              value: hebrew() ? t.name : t.nameEn,
              selected: +currentListType === t.type,
            }))}
        />
        <div className='agency-house-analytics-customers-content'>
          <AgencyHouseAnalyticsCustomersListContainer
            listType={+currentListType}
            listViewType={+currentListViewType}
            setListViewType={selectListViewType}
            periodViewType={periodViewType}
            list={list}
            totals={totals}
            listLoading={listLoading || filtersLoading}
            selectItem={setDetailsItem}
            selectedItem={detailsItem}
          />
        </div>
      </main>
      <AgencyHouseAnalyticsCustomersDetailsCard
        item={detailsItem}
        closeCard={() => setDetailsItem(null)}
        listViewType={+currentListViewType}
      />
    </div>
  )
}

export default AgencyHouseAnalyticsCustomers
