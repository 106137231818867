import ReactApexChart from 'react-apexcharts'

const AgencyHouseLobbyTotalsChart = ({ trend, data = [], labels = [] }) => {
  const chartPrimaryColor = `var(--trend-${trend ? 'up' : 'down'}-color)`

  const state = {
    options: {
      chart: {
        toolbar: { show: false },
        parentHeightOffset: 0,
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        colors: [chartPrimaryColor],
      },
      grid: {
        show: false,
        padding: {
          top: -50,
          right: -5,
          bottom: -10,
        },
      },
      dataLabels: {
        enabled: false,
      },
      fill: {
        colors: [chartPrimaryColor],
        type: 'gradient',
        gradient: {
          gradientToColors: ['transparent'],
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
      },
      markers: {
        size: 5,
        colors: chartPrimaryColor,
        strokeColors: chartPrimaryColor,
        strokeWidth: 2,
        fillOpacity: 0.5,
      },
      xaxis: {
        type: 'category',
        categories: labels,
        labels: {
          style: {
            cssClass: 'agency-house-lobby-totals-chart-xaxis-label',
          },
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
    },
    series: [
      {
        name: 'Series 1',
        data,
      },
    ],
  }

  return <ReactApexChart options={state.options} series={state.series} type='area' height={'100%'} />
}

export default AgencyHouseLobbyTotalsChart
