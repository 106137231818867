import { urlAgency, urlCompany, urlMaster, urlPolicy } from '../../../environment/urls'
import $api from '../../../http'
import {
  addAgentGroupRequest,
  getAgentGroupsRequest,
  getAgentNumbersRequest,
  getAllAgentGroupsRequest,
  removeAgentNumberRequest,
  removeGroupAgentRequest,
  removeGroupRequest,
  restoreAgentNumberRequest,
  updateGroupRequest,
} from '../../../http/requests'
import { getAgencyInvitesRequest } from '../../../http/requests/agencyHouseRequests'
import { getRandomInt } from '../../../services/getRandom'
import {
  SHARED_ADD_GROUP_FAIL,
  SHARED_ADD_GROUP_REQUEST,
  SHARED_ADD_GROUP_SUCCESS,
  SHARED_AGENCY_INVITES_FAIL,
  SHARED_AGENCY_INVITES_REQUEST,
  SHARED_AGENCY_INVITES_SUCCESS,
  SHARED_AGENTS_FAIL,
  SHARED_AGENTS_REQUEST,
  SHARED_AGENTS_SUCCESS,
  SHARED_AGENT_GROUPS_FAIL,
  SHARED_AGENT_GROUPS_REQUEST,
  SHARED_AGENT_GROUPS_SUCCESS,
  SHARED_AGENT_NUMBERS_FAIL,
  SHARED_AGENT_NUMBERS_REQUEST,
  SHARED_AGENT_NUMBERS_SUCCESS,
  SHARED_ALL_AGENT_GROUPS_FAIL,
  SHARED_ALL_AGENT_GROUPS_REQUEST,
  SHARED_ALL_AGENT_GROUPS_SUCCESS,
  SHARED_CATEGORIES_FAIL,
  SHARED_CATEGORIES_REQUEST,
  SHARED_CATEGORIES_SUCCESS,
  SHARED_CRAWLER_FAIL,
  SHARED_CRAWLER_REQUEST,
  SHARED_CRAWLER_SUCCESS,
  SHARED_DESELECT_GROUPS,
  SHARED_REMOVE_GROUP_FAIL,
  SHARED_REMOVE_GROUP_REQUEST,
  SHARED_REMOVE_GROUP_SUCCESS,
  SHARED_ROLES_FAIL,
  SHARED_ROLES_REQUEST,
  SHARED_ROLES_SUCCESS,
  SHARED_SELECT_GROUPS,
  SHARED_UPDATE_GROUP_FAIL,
  SHARED_UPDATE_GROUP_REQUEST,
  SHARED_UPDATE_GROUP_SUCCESS,
  SHARED_AGENT_REMOVE_FAIL,
  SHARED_AGENT_REMOVE_REQUEST,
  SHARED_AGENT_REMOVE_SUCCESS,
  SHARED_AGENT_RESTORE_FAIL,
  SHARED_AGENT_RESTORE_REQUEST,
  SHARED_AGENT_RESTORE_SUCCESS,
} from '../../../types/actionTypesShared'
import { buildUserPreferences, getUserPreferencesList } from '../../../types/agencyUserTypes'
import { companies } from '../../../types/companies'
import { insuranceCategory } from '../../../types/insuranceCategory'

const policyUrl = urlPolicy()
const agencyUrl = urlAgency()
const masterUrl = urlMaster()
const companyUrl = getState => {
  let agencyDbName
  if (getState) {
    agencyDbName = getState().download.agencyDbName
  }
  return agencyDbName ? urlCompany(agencyDbName) : urlCompany()
}

export const getCategories = () => async dispatch => {
  dispatch({ type: SHARED_CATEGORIES_REQUEST })
  try {
    const {
      data: {
        data: { categories },
      },
    } = await $api.get(`${policyUrl}categories`)
    dispatch({
      type: SHARED_CATEGORIES_SUCCESS,
      payload: categories.sort((a, b) => insuranceCategory[a.id].sortOrder - insuranceCategory[b.id].sortOrder),
    })
  } catch (error) {
    dispatch({
      type: SHARED_CATEGORIES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAgents = () => async dispatch => {
  dispatch({ type: SHARED_AGENTS_REQUEST })
  try {
    const {
      data: {
        data: { agents },
      },
    } = await $api.get(`${agencyUrl}agents`)
    dispatch({
      type: SHARED_AGENTS_SUCCESS,
      payload: agents,
    })
  } catch (error) {
    dispatch({
      type: SHARED_AGENTS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getUserRoles = () => async dispatch => {
  dispatch({ type: SHARED_ROLES_REQUEST })
  try {
    const {
      data: { Data: roles },
    } = await $api.get(`${masterUrl}account/roles`)
    dispatch({
      type: SHARED_ROLES_SUCCESS,
      payload: roles,
    })
  } catch (error) {
    dispatch({
      type: SHARED_ROLES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getCrawlerCompanies = () => async (dispatch, getState) => {
  dispatch({
    type: SHARED_CRAWLER_REQUEST,
  })
  try {
    const {
      data: { data: companies },
    } = await $api.get(`${companyUrl(getState)}getCrawlerCompanies`)
    dispatch({
      type: SHARED_CRAWLER_SUCCESS,
      payload: companies,
    })
  } catch (error) {
    dispatch({
      type: SHARED_CRAWLER_FAIL,
      payload: error.message,
    })
  }
}

export const getAgentNumbers = () => async dispatch => {
  dispatch({ type: SHARED_AGENT_NUMBERS_REQUEST })
  try {
    const {
      data: { data },
    } = await getAgentNumbersRequest()
    dispatch({
      type: SHARED_AGENT_NUMBERS_SUCCESS,
      payload: {
        activeAgents: data.activeAgents.map(a => ({ ...a, colorIndex: getRandomInt(1, 3) - 1 })),
        deletedAgents: data.deletedAgents.map(a => ({ ...a, colorIndex: getRandomInt(1, 3) - 1 })),
      },
    })
  } catch (error) {
    dispatch({
      type: SHARED_AGENT_NUMBERS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const removeAgentNumber = agent => async dispatch => {
  dispatch({ type: SHARED_AGENT_REMOVE_REQUEST, payload: agent.id })
  try {
    await removeAgentNumberRequest(agent.id)
    dispatch({
      type: SHARED_AGENT_REMOVE_SUCCESS,
      payload: agent.id,
    })
  } catch (error) {
    dispatch({
      type: SHARED_AGENT_REMOVE_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id: agent.id,
      },
    })
  }
}

export const restoreAgentNumber = agent => async dispatch => {
  dispatch({ type: SHARED_AGENT_RESTORE_REQUEST, payload: agent.id })
  try {
    await restoreAgentNumberRequest(agent.id)
    dispatch({
      type: SHARED_AGENT_RESTORE_SUCCESS,
      payload: agent.id,
    })
  } catch (error) {
    dispatch({
      type: SHARED_AGENT_RESTORE_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id: agent.id,
      },
    })
  }
}

export const getAllAgentGroups = () => async dispatch => {
  dispatch({ type: SHARED_ALL_AGENT_GROUPS_REQUEST })
  try {
    const {
      data: { data: allAgentGroups },
    } = await getAllAgentGroupsRequest()
    dispatch({
      type: SHARED_ALL_AGENT_GROUPS_SUCCESS,
      payload: allAgentGroups.map(gr => ({ ...gr, colorIndex: getRandomInt(1, 3) - 1 })),
    })
  } catch (error) {
    dispatch({
      type: SHARED_ALL_AGENT_GROUPS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const getAgentGroups = () => async dispatch => {
  dispatch({ type: SHARED_AGENT_GROUPS_REQUEST })
  try {
    const {
      data: { data: agentGroups },
    } = await getAgentGroupsRequest()
    dispatch({
      type: SHARED_AGENT_GROUPS_SUCCESS,
      payload: agentGroups.map(gr => ({ ...gr, colorIndex: getRandomInt(1, 3) - 1 })),
    })
  } catch (error) {
    dispatch({
      type: SHARED_AGENT_GROUPS_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}

export const addAgentGroup =
  ({ name, agents }) =>
  async dispatch => {
    dispatch({ type: SHARED_ADD_GROUP_REQUEST })
    try {
      let success = false
      let group = null
      const agentIds = agents.map(a => a.id)
      const {
        data: { data: groupId },
      } = await addAgentGroupRequest({ name, agentIds })
      if (groupId) {
        success = true
        group = { id: groupId, name, agents }
      }
      dispatch({
        type: SHARED_ADD_GROUP_SUCCESS,
        payload: { success, group },
      })
    } catch (error) {
      dispatch({
        type: SHARED_ADD_GROUP_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      })
    }
  }

export const removeAgentGroup = id => async dispatch => {
  dispatch({ type: SHARED_REMOVE_GROUP_REQUEST, payload: id })
  try {
    const {
      data: {
        data: { success },
      },
    } = await removeGroupRequest(id)
    dispatch({
      type: SHARED_REMOVE_GROUP_SUCCESS,
      payload: { success, id },
    })
  } catch (error) {
    dispatch({
      type: SHARED_REMOVE_GROUP_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id,
      },
    })
  }
}

export const updateAgentGroup = group => async dispatch => {
  dispatch({ type: SHARED_UPDATE_GROUP_REQUEST, payload: group.id })
  try {
    const {
      data: {
        data: { success },
      },
    } = await updateGroupRequest({ ...group, agents: group.agents.map(a => a.id) })
    dispatch({
      type: SHARED_UPDATE_GROUP_SUCCESS,
      payload: { success, group },
    })
  } catch (error) {
    dispatch({
      type: SHARED_UPDATE_GROUP_FAIL,
      payload: {
        error: error.response && error.response.data.message ? error.response.data.message : error.message,
        id: group.id,
      },
    })
  }
}

export const selectAgentsGroups = ids => ({ type: SHARED_SELECT_GROUPS, payload: ids })

export const deselectAgentsGroups = ids => ({ type: SHARED_DESELECT_GROUPS, payload: ids })

export const getAgencyInvites = () => async dispatch => {
  dispatch({
    type: SHARED_AGENCY_INVITES_REQUEST,
  })
  try {
    let {
      data: { Data: invites },
    } = await getAgencyInvitesRequest()

    invites = invites.map(invt => ({
      id: invt.Id,
      agencyHouseName: invt.AgencyName,
      companies: invt.CompanyIds.map(c => ({
        id: c,
        name: companies.find(com => com.id === c)?.name,
        nameEn: companies.find(com => com.id === c)?.nameEn,
        iconPath: companies.find(com => com.id === c)?.iconPath,
      })),
      preferences: getUserPreferencesList().filter(pr => buildUserPreferences(invt.Preferences).includes(pr.id)),
    }))

    dispatch({
      type: SHARED_AGENCY_INVITES_SUCCESS,
      payload: invites,
    })
  } catch (error) {
    dispatch({
      type: SHARED_AGENCY_INVITES_FAIL,
      payload: error.response && error.response.data.message ? error.response.data.message : error.message,
    })
  }
}
