import { useTranslation } from 'react-i18next'
import { hebrew } from '../../../../i18n'
import { insuranceCategory } from '../../../../types/insuranceCategory'
import { formatAsCurrency, formatAsNumber } from '../../../../utils/formatAs'
import classNames from 'classnames'
import './AgencyHouseLobbySalesCategoryCard.scss'
import { useDispatch, useSelector } from 'react-redux'
import { agencyType } from '../../../../types/agencyTypes'
import { useHistory } from 'react-router'
import { agencyRoutes } from '../../../../environment/urls'
import { CUSTOMERS_FILTER_BY_CATEGORY, CUSTOMERS_FILTER_SET_ADVANCED } from '../../../../types/actionTypes'

const AgencyHouseLobbySalesCategoryCard = ({ category, potentialCategory }) => {
  const { t } = useTranslation('agencyHouseLobby')
  const categoryName = hebrew() ? category.name : category.nameEn
  const { cardIcon } = insuranceCategory[category.categoryId]
  const customers = t('sales.customers', { customers: formatAsNumber(category.customersCount) })
  const { AgencyType: loggedInAgencyType = agencyType.regular } = useSelector(({ login }) => login)
  const regularAgency = loggedInAgencyType === agencyType.regular

  const dispatch = useDispatch()
  const history = useHistory()

  const renderAverage = (value, text) => (
    <div>
      <p>₪{formatAsCurrency(value, { currency: '' })}</p>
      <p>{text}</p>
    </div>
  )

  const onCardClickHandler = () => {
    potentialCategory
      ? dispatch({
          type: CUSTOMERS_FILTER_SET_ADVANCED,
          payload: {
            categoriesNoFilter: [{ id: category.categoryId, checked: true }],
            companiesAndFilter: [],
            categoriesAndFilter: [],
            companiesNoFilter: [],
            advancedFiltersApplied: true,
          },
        })
      : dispatch({
          type: CUSTOMERS_FILTER_BY_CATEGORY,
          payload: category.categoryId,
        })
    history.push(`/${agencyRoutes.customers}?type=distribution`)
  }

  return (
    <div
      className={classNames('agency-house-lobby-sales-category-card', {
        potential: potentialCategory,
        clickable: regularAgency,
      })}
      {...(regularAgency && {
        onClick: onCardClickHandler,
      })}
    >
      <header>
        <p>{categoryName}</p>
        {cardIcon}
      </header>
      <main>
        <p>{customers}</p>
        <div>
          {renderAverage(category.commission, t('sales.avrgCommission'))}
          {renderAverage(category.premium, t('sales.avrgPremium'))}
        </div>
      </main>
    </div>
  )
}

export default AgencyHouseLobbySalesCategoryCard
