import { getMonthNameByNumber } from '../services/getMonthName'

const _MS_PER_DAY = 1000 * 60 * 60 * 24

export const getLastMonth = () => {
  const dayOfFirstDownload = 1
  const currentDate = new Date().getDate()
  const requiredMonth = new Date().getMonth() - (currentDate < dayOfFirstDownload ? 2 : 1)
  let requiredDate = new Date()
  requiredDate.setDate(1)
  requiredDate.setMonth(requiredMonth)

  return {
    date: createDateAsUTC(requiredDate),
    ...getFromTo(requiredDate.getFullYear(), requiredDate.getMonth()),
  }
}

export const getFromTo = (year, month) => {
  return { from: new Date(Date.UTC(year, month, 1, 0, 0, 0)), to: new Date(Date.UTC(year, month + 1, 0, 23, 59, 59)) }
}

export const getFromToForYear = () => {
  const year = getLastMonth().date.getFullYear()
  const month = new Date(getLastMonth().date)
  const from = new Date(
    new Date(getLastMonth().from.setFullYear(year - 1)).setMonth(getLastMonth().from.getMonth() + 1)
  )
  const to = getLastMonth().to
  return { year, to, month, from }
}

export function createDateAsUTC(date) {
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds())
  )
}

export function convertDateToUTC(date) {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )
}

export const getDateDiffInDays = (a, b) => {
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())
  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}

export const getDateMonth = (date = new Date()) => new Date(date).toISOString().slice(0, 7)
export const getDateMonthDay = (date = new Date()) => new Date(date).toISOString().slice(0, 10)

export const isExpired = date => {
  date = new Date(createDateAsUTC(new Date(date)).toISOString()).toISOString()
  const today = new Date(getDateMonthDay()).toISOString()
  return today >= date
}

export const printDatesInRange = (dates, language = 'en') =>
  dates
    .sort((a, b) => new Date(a) - new Date(b))
    .map(d => [+d.slice(5, 7), +d.slice(2, 4)])
    //determine if some months are selected in a row, and replace them with '-'
    .map(([m, y], _, arr) => {
      const rangeStart = !arr.some(i => i[1] === y && i[0] === m - 1)
      const rangeEnd = !arr.some(i => i[1] === y && i[0] === m + 1)
      const inRange = arr.some(i => i[1] === y && i[0] === m + 1 && i[0] === m + 2)
      if ((rangeStart || rangeEnd) && !inRange) return [m, y]
      else return ['-']
    })
    // remove '-' duplicates
    .filter((d, i, arr) => !(d[0] === '-' && arr[i + 1][0] === '-'))
    .map(([m, y]) => `${m === '-' ? m : getMonthNameByNumber(m, language) + ' ' + y}`)
    .join(', ')
    .replaceAll(', -, ', ' - ')

export function daysToEndOfMonth() {
  const today = new Date()
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0)
  const diffTime = endOfMonth - today
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

export function hoursToEndOfDay() {
  const now = new Date()
  const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59) // Set time to end of day
  const diffMilliseconds = endOfDay - now
  const diffHours = Math.floor(diffMilliseconds / (1000 * 60 * 60)) // Convert milliseconds to hours
  return diffHours
}
