import { useCallback, useEffect, useRef } from 'react'
import './ModalSidebar.scss'
import Loader from '../Loader/Loader'
import classNames from 'classnames'

const ModalSidebar = ({
  children,
  closeSidebar = () => {},
  closeOnClickOutSide = false,
  closeOnEsc = true,
  width = '600px',
  loadingOverlay = false,
  closeDisabled = false,
}) => {
  const bodyOverflowRef = useRef()

  const closeSidebarHandler = () => {
    !closeDisabled && closeSidebar()
  }

  const escFunction = useCallback(
    event => {
      if (event.key === 'Escape') {
        closeSidebarHandler()
      }
    },
    [closeSidebarHandler]
  )

  useEffect(() => {
    if (closeOnEsc) {
      document.addEventListener('keydown', escFunction, false)
    }
    return () => {
      if (closeOnEsc) {
        document.removeEventListener('keydown', escFunction, false)
      }
    }
  }, [escFunction, closeOnEsc])

  useEffect(() => {
    bodyOverflowRef.current = document.body.style.overflow
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = bodyOverflowRef.current
    }
  }, [])

  return (
    <div
      className='modal-sidebar-wrapper'
      {...(closeOnClickOutSide && {
        onClick: () => closeSidebarHandler(),
      })}
    >
      <div className='modal-sidebar' style={{ width }}>
        <div
          className={classNames('sidebar-close-btn', { disabled: closeDisabled })}
          onClick={() => closeSidebarHandler()}
        >
          <img src='./assets/close-icon/Close.png' className='img-icon' alt='close' />
        </div>
        <div className='modal-sidebar-content'>{children}</div>
        {loadingOverlay && (
          <div className='modal-sidebar-loading-overlay'>
            <Loader />
          </div>
        )}
      </div>
    </div>
  )
}

export default ModalSidebar
