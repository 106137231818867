import ReactApexChart from 'react-apexcharts'
import './AnnualDistributionChart.css'

const AnnualDistributionChart = ({
  series,
  colors,
  labels,
  width = '100%',
  height = '100%',
  donutSize = '82%',
  valueFormatter = val => val,
}) => {
  const state = {
    series: [...series],
    options: {
      colors: colors ?? ['#02b64a', '#67d392', '#d4f2e0', '#fd8b97', '#fb4659'],
      labels: labels ?? [],
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
        active: {
          filter: {
            type: 'none',
          },
        },
      },
      chart: {},
      legend: { show: false },
      plotOptions: {
        pie: {
          expandOnClick: true,
          donut: {
            size: donutSize,
            labels: {
              show: true,
              value: {
                formatter: valueFormatter,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: false,
      },
      tooltip: {
        enabled: false,
      },
      grid: {
        padding: {
          top: 1,
          right: 1,
          bottom: 1,
          left: 1,
        },
      },
    },
  }

  return (
    <div>
      <ReactApexChart
        options={state.options}
        series={state.series}
        width={width}
        // height={height}
        type='donut'
        className='apex-chart'
      />
    </div>
  )
}

export default AnnualDistributionChart
