import { useTranslation } from 'react-i18next'
import ModalWindow from '../../../../components/ModalWindow/ModalWindow'
import { CloseOutlined } from '@mui/icons-material'
import { useState } from 'react'
import ButtonRounded from '../../../../components/Common/ButtonRounded/ButtonRounded'
import InputSelect from '../../../../components/Common/InputSelect/InputSelect'
import { useDropzone } from 'react-dropzone'
import { UploadFile } from '@mui/icons-material'
import { cn } from '../../../../utils/stylesUtils'
import MicrosoftExcelIconSvg from '../../../../components/Common/SvgIcons/MicrosoftExcelIconSvg/MicrosoftExcelIconSvg'
import { bytesPrettyPrint } from '../../../../utils/bytesUtils'
import { uploadPairedReportsByIdRequest, uploadReportByIdRequest } from '../../../../http/requests/scanRequests'
import { reportRecordStatusType } from '../../../../types/reportRecordStatus'
import './ScanUploadReportModal.scss'

const uploadReportStatusType = {
  Ok: 1,
  monthMismatch: 2,
  wrongFormat: 3,
  missingData: 4,
  serverError: 5,
  inCorrectFileMonth: 70,
}
const uploadReportStatusTypeMap = {
  [reportRecordStatusType.saved]: 1,
  [reportRecordStatusType.reportNotFound]: 2,
  [reportRecordStatusType.fileCorrupted]: 3,
  [reportRecordStatusType.noData]: 4,
  [reportRecordStatusType.unknownScanError]: 5,
  [reportRecordStatusType.inCorrectFileMonth]: 70,
}
const pairedReportNumbers = { 6: [6, 7], 8: [8, 9], 11: [11, 9], 16: [16, 17] }

const ScanUploadReportModal = ({ closeModal, reports, onUploadSuccess = () => {}, users }) => {
  const { t } = useTranslation('scan')
  const [selectedReport, setSelectedReport] = useState(reports.length === 1 ? reports[0].id : null)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [files, setFiles] = useState([])
  const maxFiles = 2
  const [uploadStatus, setUploadStatus] = useState(null)

  const reportsValues = reports
    .map((r, _, arr) => {
      let pair = null
      if (pairedReportNumbers[r.reportNumber]) {
        pair = arr.find(ri => ri.reportNumber === pairedReportNumbers[r.reportNumber][1])
      }
      return {
        ...r,
        text: `${r.reportName} (${r.reportNumber})${
          pair
            ? ` + ${pair.reportName} (${pair.reportNumber}) - ${
                users.length > 1 ? users.find(u => u.id === r.companyUserId).name : ''
              }`
            : ''
        }`,
      }
    })
    .filter(
      ({ reportNumber }) =>
        !Object.values(pairedReportNumbers)
          .map(r => r[1])
          .includes(reportNumber)
    )

  const pairedReportSelected = pairedReportNumbers[reports.find(r => r.id === +selectedReport)?.reportNumber]

  const errorStatus =
    uploadStatus === uploadReportStatusType.missingData ||
    uploadStatus === uploadReportStatusType.monthMismatch ||
    uploadStatus === uploadReportStatusType.wrongFormat ||
    uploadStatus === uploadReportStatusType.serverError

  const fileTypes =
    'text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  const readFiles = files => {
    setFiles(prev => [...prev, ...files])
    setUploadStatus(null)
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: fileTypes,
    maxFiles: maxFiles,
    onDropAccepted: readFiles,
    onFileDialogOpen: () => setErrors(null),
    onDrop: () => setErrors(null),
    disabled: !selectedReport || reportsValues.length === 0,
  })

  const uploadDisabled =
    loading ||
    !selectedReport ||
    files.length === 0 ||
    (pairedReportSelected && files.length < 2) ||
    files.some(f => f.size === 0)

  const uploadHandler = async () => {
    try {
      setLoading(true)
      setUploadStatus(null)
      let resp
      let reportIdsToUpdate = [+selectedReport]
      if (pairedReportSelected) {
        const { companyUserId, reportNumber } = reports.find(r => r.id === +selectedReport)
        const pairedReport = reports.find(
          r => r.reportNumber === pairedReportNumbers[reportNumber][1] && r.companyUserId === companyUserId
        )
        reportIdsToUpdate.push(pairedReport.id)
        resp = await uploadPairedReportsByIdRequest(files[0], files[1], reportIdsToUpdate)
      } else {
        resp = await uploadReportByIdRequest(selectedReport, files[0])
      }
      const {
        data: { data },
      } = resp
      if (data === reportRecordStatusType.saved) {
        setLoading(false)
        onUploadSuccess(reportIdsToUpdate)
      } else {
        setLoading(false)
        setUploadStatus(uploadReportStatusTypeMap[data] || uploadReportStatusType.serverError)
      }
    } catch (error) {
      setLoading(false)
      setUploadStatus(uploadReportStatusType.serverError)
    }
  }

  return (
    <ModalWindow renderPortal showCloseIcon={false}>
      <div className='scan-upload-report-modal'>
        <header>
          <p>{t('importModal.title')}</p>
          <button onClick={closeModal}>
            <CloseOutlined />
          </button>
        </header>
        <main>
          <div>
            <p>{t('importModal.remark')}</p>
            <InputSelect
              values={reportsValues}
              label={t(`importModal.${reportsValues.length > 0 ? 'inputPlaceholder' : 'noReportsAvailable'}`)}
              showLabel
              selectedValue={reportsValues.length > 0 ? selectedReport : null}
              onChange={setSelectedReport}
            />
          </div>
          <div className='modal-upload-report-drop-zone'>
            <div {...getRootProps({ className: cn('dropzone', { disabled: !selectedReport }) })}>
              <input {...getInputProps()} multiple={maxFiles === 1 ? false : true} />
              <UploadFile />
              <div className='drop-zone-actions'>
                <p>{t('importModal.dragFile')}</p>
                <button onClick={open} disabled={!selectedReport}>
                  {t('importModal.clickToUpload')}
                </button>
              </div>
              <p className='drop-zone-remark'>Excel or CSV (max. 3MB)</p>
            </div>
            {errors && (
              <div className='errors' style={{ color: 'red' }}>
                {errors}
              </div>
            )}
          </div>
          <div className='modal-upload-report-files'>
            {files.map(f => (
              <div className='modal-upload-report-file' key={f.name + f.size}>
                <div className={cn('file-ico', { error: errorStatus || f.size === 0 })}>
                  <MicrosoftExcelIconSvg fill={`var(--trend-${errorStatus || f.size === 0 ? 'down' : 'up'}-color)`} />
                </div>
                <div className={cn('file-info', { error: errorStatus || f.size === 0 })}>
                  <p>{f.name}</p>
                  <div
                    className={cn({
                      error: errorStatus || f.size === 0,
                      success: uploadStatus === uploadReportStatusType.Ok,
                    })}
                  >
                    <p>
                      {errorStatus || f.size === 0
                        ? t('importModal.failed') + (f.size === 0 ? ' - ' + t('importModal.uploadStatus4') : '')
                        : bytesPrettyPrint(f.size)}
                    </p>
                    {(loading || uploadStatus) && (
                      <p>{loading ? t('importModal.uploading') : t(`importModal.uploadStatus${uploadStatus}`)}</p>
                    )}
                  </div>
                </div>
                <button onClick={() => setFiles([])}>
                  <CloseOutlined />
                </button>
              </div>
            ))}
          </div>
        </main>
        <div>
          <ButtonRounded onClick={uploadHandler} disabled={uploadDisabled}>
            {t('importModal.uploadBtn')}
          </ButtonRounded>
        </div>
      </div>
    </ModalWindow>
  )
}

export default ScanUploadReportModal
