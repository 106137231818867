import { useState } from 'react'
import Loader from '../../../../components/Common/Loader/Loader'
import CustomerTablesNavbar from '../../../customer/components/CustomerTablesNavbar/CustomerTablesNavbar'
import { useTranslation } from 'react-i18next'
import AgencyHouseLobbySalesField from './AgencyHouseLobbySalesField'
import './AgencyHouseLobbySales.scss'
import { InfoTwoTone } from '@mui/icons-material'
import Tooltip from '../../../../components/Common/Tooltip/Tooltip'

const salesType = {
  existing: 1,
  potential: 2,
}

const AgencyHouseLobbySales = ({ sales, loading }) => {
  const [currentSalesType, setCurrentSalesType] = useState(salesType.existing)
  const { t } = useTranslation('agencyHouseLobby')
  const tSales = (key, params) => `${t('sales.' + key, { ...params })}`
  if (loading) {
    return (
      <div className='agency-house-lobby-sales'>
        <Loader />
      </div>
    )
  }

  const currentSales = Object.values(
    currentSalesType === salesType.existing ? sales.existingCategories : sales.nonExistingCategories
  )

  return (
    <div className='agency-house-lobby-sales'>
      <CustomerTablesNavbar
        navbarItems={[
          { text: tSales('existingNavHeader'), type: salesType.existing },
          { text: tSales('potentialNavHeader'), type: salesType.potential },
        ]}
        currentTableType={currentSalesType}
        setCurrentTable={({ type }) => setCurrentSalesType(type)}
      />
      <header>
        <div>
          <h4>
            {tSales('totalCustomers', {
              customers: sales.customersCount,
            })}
          </h4>
          <div>
            <InfoTwoTone />
            <Tooltip
              title={tSales('totalCustomersTooltip', {
                customers: sales.customersCount,
              })}
              textWidth={'192px'}
            />
          </div>
        </div>
        <p>{tSales('remark')}</p>
      </header>
      <main>
        {currentSales.map(f => (
          <AgencyHouseLobbySalesField
            key={f.fieldId}
            field={f}
            potentialField={currentSalesType === salesType.potential}
          />
        ))}
      </main>
    </div>
  )
}

export default AgencyHouseLobbySales
