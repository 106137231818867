import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ModalWithSelection from '../../../../components/Common/ModalWithSelection/ModalWithSelection'
import CategoriesConfirmModal from '../CategoriesConfirmModal/CategoriesConfirmModal'

const CategoriesSelector = ({ policies, closeSelector, setPoliciesCategory }) => {
  const { i18n, t } = useTranslation('customer')
  const [showCategoriesSelector, setShowCategoriesSelector] = useState(true)
  const [showCategoriesConfirmModal, setShowCategoriesConfirmModal] = useState(false)
  const [newCategory, setNewCategory] = useState(null)

  const categories = useSelector(state => state.shared).categories
  const categoriesLoading = useSelector(state => state.shared).categoriesLoading
  const setCategorySuccess = useSelector(state => state.policy).setCategorySuccess
  const setCategoryLoading = useSelector(state => state.policy).setCategoryLoading
  const paymentsAgg = useSelector(state => state.policy).paymentsAgg

  const haveSameCategory = policies.map(p => p?.categoryId).every((pc, _, pcArr) => pc === pcArr[0])
  const hasPolicyTypes = paymentsAgg.some(agg => agg.payments.some(pmnt => pmnt.policyTypeId))
  const hasRealtedPolices = hasPolicyTypes || policies.length > 1 || true
  const currCategory = haveSameCategory && categories.find(c => c.id === policies[0].categoryId)
  const currField = policies.map(p => p?.fieldId).every((pf, _, pfArr) => pf === pfArr[0]) && policies[0].fieldId
  const availableCategories = categories.filter(c => c.fieldId === currField)
  const policiesIds = policies.map(p => p.id)

  const setPoliciesCategoryHandler = (policiesIds, categoryId, updateAllRelatedPolicies) => {
    setPoliciesCategory(policiesIds, categoryId, updateAllRelatedPolicies)
  }
  const setCategoryToUpdate = categoryId => {
    setNewCategory(categoryId)
    if (hasRealtedPolices) {
      setShowCategoriesSelector(false)
      setShowCategoriesConfirmModal(true)
    } else {
      setPoliciesCategory(policiesIds, categoryId, hasRealtedPolices)
    }
  }

  return (
    <>
      {showCategoriesSelector && (
        <div className='modal-selection-wrapper' onClick={e => e.stopPropagation()}>
          <ModalWithSelection
            items={availableCategories.map(c => {
              return { ...c, value: i18n.language === 'he' ? c.name : c.englishName }
            })}
            currentItem={currCategory}
            closeModal={closeSelector}
            onClickHandler={setCategoryToUpdate}
            loading={categoriesLoading || setCategoryLoading}
            selectSuccess={setCategorySuccess}
            showSearch={true}
            searchPlaceholder={t('searchCategory')}
          />
        </div>
      )}
      {showCategoriesConfirmModal && (
        <CategoriesConfirmModal
          categories={availableCategories}
          closeModal={closeSelector}
          newCategoryId={newCategory}
          policies={policies}
          updatePoliciesCategory={setPoliciesCategoryHandler}
          loading={setCategoryLoading}
          closeOnOutsideClick={false}
        />
      )}
    </>
  )
}

export default CategoriesSelector
